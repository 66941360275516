import { useTranslation } from 'react-i18next';

export const useValidation = () => {
  const { t } = useTranslation('common');

  const validation = {
    required: {
      required: t('validation.required'),
    },
    url: {
      pattern: {
        value:
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
        message: t('validation.url'),
      },
      required: t('validation.required'),
    },
  };

  return validation;
};
