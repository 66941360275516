import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { RadioGroup } from 'components';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

const Dsfa_Step6 = () => {
  const { getValues, formState, control, unregister } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const options = t('riskOptions', { returnObjects: true });
  const step3 = getValues()?.step3;

  const userRisksResult = Object.entries(step3.risktypes).flatMap(
    ([type, riskGroup]) =>
      riskGroup.map(({ label }) => ({
        label,
        name: label.replace(/[,\/]/g, ''),
        type,
      }))
  );

  React.useEffect(() => {
    unregister('step6');
  }, []);

  return (
    <div className="card-body">
      <h4 style={{ marginBottom: '25px' }}>{t('dsfa.steps.6.title')}</h4>
      <div className={styles.steptext}>{t('dsfa.steps.6.description')}</div>
      <div className={styles.dsfaInputsWrapper}>
        {userRisksResult.map((riskFactor, idx) => (
          <Controller
            key={idx}
            name={`step6.${riskFactor.type}.${riskFactor.name}`}
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <RadioGroup
                label={riskFactor.label}
                name={riskFactor.name}
                options={options}
                customColor="#4b7bec"
                error={
                  formState.errors?.step6?.[riskFactor.type]?.[riskFactor.name]
                }
                {...field}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export { Dsfa_Step6 };
