import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Loader } from 'components/Loader';

import styles from './styles.module.css';

const Toms_Step1 = () => {
  const [entryControl, setEntryControl] = React.useState(null);
  const [hasFetched, setHasFetched] = React.useState(false);
  const { control, formState } = useFormContext();
  const { api } = useApi();
  const validation = useValidation();

  const fetchData = async () => {
    const entryControlResponse = await api.get('/toms_entrycontrol');

    setEntryControl(entryControlResponse.data);
  };

  React.useEffect(() => {
    if (api && !hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [api]);

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        Massnahmen, die geeignet sind, Unbefugten den Zutritt zu
        Datenverarbeitungsanlagen, mit denen Personendaten bearbeitet oder
        genutzt werden, zu verwehren.
      </div>
      <div className={styles.tomsInputsWrapper}>
        {!entryControl && <Loader />}
        {entryControl && (
          <Controller
            name="step1.entryControl"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label="Zutrittskontrollen"
                options={entryControl.map((entry) => {
                  return {
                    label: entry.EntrycontrolName,
                    value: entry.EntrycontrolId,
                  };
                })}
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step1?.entryControl}
                name={field.name}
                isMulti
                customColor="#f7b731"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Toms_Step1 };
