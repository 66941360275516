import React from 'react';
import Slider from 'rc-slider';

import styles from './styles.module.css';
import './slider.css';

const StepsNavigation = ({
  step,
  setStep,
  errors,
  marks,
  min,
  max,
  customColor,
}) => {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  const handleChange = (newValue) => {
    if (Object.keys(errors)?.length > 0) {
      return;
    }

    setStep(newValue);
  };

  React.useEffect(() => {
    const handleWindowResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className={`${styles.stepsNavigation} stepsNavigation`}>
      <Slider
        vertical={innerWidth > 1100}
        marks={marks}
        min={min}
        max={max}
        onChange={handleChange}
        value={step}
        reverse={innerWidth > 1100}
        railStyle={{ backgroundColor: customColor ?? '#d33c2e' }}
        trackStyle={{ backgroundColor: customColor ?? '#d33c2e' }}
        dotStyle={{
          backgroundColor: customColor ?? '#d33c2e',
          borderColor: customColor ?? '#d33c2e',
        }}
        handleStyle={{
          backgroundColor: customColor ?? '#d33c2e',
          borderColor: customColor ?? '#d33c2e',
        }}
      />
    </div>
  );
};

export { StepsNavigation };
