import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AARequest = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  // Funktion zum Herunterladen der Datei
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/Antwort_Auskunftsanfrage.docx`;
    link.download = 'Antwort_Auskunftsanfrage.docx';
    link.click();
  };

  return (
    <div className="card-body morph_variation1 padder">
      <img src="/icon-aa.svg" style={{ width: '80px' }} />
      <br />

      <h4>{t('pendingModule.aa')}</h4>

      <p className="text-center" style={{ margin: '10px 0px 30px 0px' }}>
        {t('programmeData.aa.moduledescription')}
        <br />
        {t('programmeData.aa.moduledescription2')}
      </p>

      <div
        style={{
          width: '60%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '5px',
        }}
      >
        <button
          type="button"
          className="btn btn-secondary"
          style={{
            marginBottom: '10px',
            marginTop: '32px',
            width: 'calc(50% - 2.5px)',
          }}
          onClick={() => navigate('/Programme')}
        >
          {t('backToMenu')}
        </button>
        <button
          type="button"
          className="btn btn-primary accent-grey-bg"
          style={{
            marginBottom: '10px',
            marginTop: '32px',
            width: 'calc(50% - 2.5px)',
          }}
          onClick={handleDownload}
        >
          {t('programmeData.aa.auskunftsanfrage')}
        </button>
      </div>
    </div>
  );
};

export { AARequest };
