import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';
import DeleteIcon from 'assets/images/delete-icon.svg';
import DownloadIcon from 'assets/images/download-icon.svg';

import styles from '../styles.module.css';

export const UploadsTable = ({ data, setShowModal, handleDownload }) => {
  const { t, i18n } = useTranslation('common');

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'Id',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Comment',
        accessor: 'Comment',
      },
      {
        Header: 'File',
        accessor: 'File',
      },
      {
        Header: t('overview.action'),
        id: 'aktionen',
        Cell: ({ row }) => (
          <span className={styles.actionsWrapper}>
            <img
              src={DeleteIcon}
              className={styles.tableIcon}
              onClick={() => {
                setShowModal({
                  id: row.values.Id,
                  name: row.values.File,
                  type: row.values.type,
                });
              }}
            />
            <img
              className={styles.tableIcon}
              src={DownloadIcon}
              onClick={() => {
                handleDownload(row.values.File, row.values.type);
              }}
            />
          </span>
        ),
      },
    ],
    [i18n.language]
  );

  const initialState = { hiddenColumns: ['Id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  return (
    <table
      {...getTableProps()}
      className={`${styles.uploadsTable} table-hover`}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={styles.tableHeading}
                onClick={() => {
                  if (column.id !== 'aktionen') {
                    column.toggleSortBy(!column.isSortedDesc);
                  }
                }}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' \u2193'
                      : ' \u2191'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={styles.tableData}
                    style={
                      cell.getCellProps().key.includes('aktionen')
                        ? { width: '40px' }
                        : undefined
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
