import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown, Loader } from 'components';
import { useFormContext, Controller } from 'react-hook-form';
import styles from './styles.module.css';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';


const Dsa_Step3 = () => {
  const [purposeData, setPurposeData] = React.useState(null);
  const [individualDecisionData, setIndividualDecisionData] = React.useState(null);
  const { control, formState } = useFormContext();
  const [error, setError] = React.useState(null);
  const { t } = useTranslation('common');

  const { api } = useApi();
  const validation = useValidation();

  const fetchData = async () => {
    try {
      const purposeResponse = await api.get(`/dsa_purpose`);
      setPurposeData(purposeResponse.data);

      const individualDecisionResponse = await api.get(`/verzeichnis_individualdecision`);
      setIndividualDecisionData(individualDecisionResponse.data);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api && !purposeData) {
      fetchData();
    }
  }, [api]);

  const renderEntries = () => {
    if (!individualDecisionData) return null;

    return (
      <div className={styles.entriesContainer}>
        {individualDecisionData.individualDecision.map((entry, index) => {
          const correspondingComment = individualDecisionData.comment[index];
          const hasValidComment = correspondingComment && correspondingComment.CommentName !== "none";

          return (
            <div key={index} className={styles.entrySection}>
              <div className={styles.entryContent}>
                {hasValidComment && correspondingComment.CommentName !== "" ? (
                  <div className={styles.commentSection}>
                    <li className={styles.valueText}>{correspondingComment.CommentName}</li>
                  </div>
                ) : (
                  <div className={styles.purposeSection}>
                    <li className={styles.valueText}>{entry.PurposeName}</li>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.cardBody}>
      <div className={styles.dsaInputsWrapper}>
        {!purposeData && <Loader />}
        {purposeData && (
          <Controller
            control={control}
            name="step3.purpose"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                options={purposeData.map((option) => ({
                  value: option.PurposeId,
                  label: option.PurposeName,
                }))}
                label="Zweck der Datenbearbeitungen"
                isCreatable
                isMulti
                onChange={field.onChange}
                value={field.value}
                error={formState.errors?.step3?.purpose}
                name={field.name}
                customColor="#fa8231"
              />
            )}
          />
        )}
      </div>
      <h5 style={{ fontWeight: 'bold', marginBottom: '15px', marginTop: '30px' }}>{t('dsa.steps.3.automated')}</h5>

      <div className={styles.entriesWrapper}>
        {renderEntries()}
      </div>
    </div>
  );
};

export { Dsa_Step3 };