import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Textarea } from 'components/Textarea';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/Loader';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';


import styles from './styles.module.css';

const Verzeichnis_Step4 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const { api, isApiReady } = useApi();
  const validation = useValidation();
  const [retention, setretention] = React.useState(null);

  const fetchData = async () => {
    try {
      const retentionResponse = await api.get('/verzeichnis_retention');

      setretention(retentionResponse.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  React.useEffect(() => {
    if (isApiReady && !retention) {
      fetchData();
    }
  }, [isApiReady]);

  return (
    <div className="card-body">
      <h4> {t('verzeichnis.steps.4.title')}</h4>
      <div className={styles.steptext}>
        {t('verzeichnis.steps.4.description')}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>


      {!retention && <Loader />}
        {retention && (
          <Controller
            name="step4.retention"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label={t('verzeichnis.steps.4.inputs.storage')}
                options={retention.map((ret) => {
                  return {
                    label: ret.RetentionName,
                    value: ret.RetentionId,
                  };
                })}
                name={field.name}
                isMulti
                isCreatable
                value={field.value}
                onChange={field.onChange}
                error={formState.errors.step4?.aufbewahrung}
              />
            )}
          />
        )}

{/* 
        <Controller
          control={control}
          name="step4.aufbewahrung"
          rules={{ ...validation.required }}
          render={({ field }) => {
            return (
              <Textarea
                label={t('verzeichnis.steps.4.inputs.storage')}
                id="verzeichnis-aufbewahrung"
                error={formState.errors?.step4?.aufbewahrung}
                tooltipText="Beschreiben Sie hier, wie lange die Daten aufbewahrt werden, zusätzlich oder anstelle davon können Sie auch Kriterien zur Aufbewahrungsdauer beschreiben, bspw. «Gesetzliche Aufbewahrungsfrist von 10 Jahren»."
                {...field}
              />
            );
          }}
        /> */}
      <Controller
        control={control}
        name="step4.massnahmenDatensicherheit"
        rules={{ ...validation.required }}
        defaultValue="Gemäss Liste der technischen und organisatorischen Massnahmen (TOMs)"
        render={({ field }) => (
          <Textarea
            label={t('verzeichnis.steps.4.inputs.securityMeasures')}
            id="verzeichnis-massnahmen"
            error={formState.errors?.step4?.massnahmenDatensicherheit}
            tooltipText="Bitte beschreiben Sie hier, was unternommen wird, um den Schutz der Daten sicherzustellen."
            placeholder="Individueller Text hier eingeben..."
            {...field}
          />
        )}
      />
      </div>
    </div>
  );
};

export { Verzeichnis_Step4 };
