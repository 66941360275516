import { Modal } from '../Modal';

import styles from './styles.module.css';

const DeleteModal = ({ onCancel, onConfirm }) => {
  return (
    <Modal>
      <h4>Diese Aktion kann nicht rückgängig gemacht werden.</h4>
      <p>Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?</p>
      <div className={styles.btnWrapper}>
        <button type="button" className="btn btn-primary" onClick={onCancel}>
          Abbrechen
        </button>
        <button type="button" className="btn btn-default" onClick={onConfirm}>
          Eintrag löschen
        </button>
      </div>
    </Modal>
  );
};

export { DeleteModal };
