import React from 'react';
import { ServiceCard, Navbar } from 'components';
import { useTranslation } from 'react-i18next';
import { MobileNavbar } from 'components/MobileNavbar';
import mobiliar_logo from '../../src/assets/images/Logo_Mobiliar_Alt.png';
import TipBar from 'components/TipBar'; // Import TipBar component

function Programme() {
  const { t } = useTranslation('common');

  return (
    <div className="wrapper" style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100vh'
    }}>
      <TipBar /> {/* TipBar at the top */}
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar /> {/* Navbar on the left */}
        <MobileNavbar />
        <div id="content" style={{ flexGrow: 1, padding: '50px' }}>
        <div
            className="container"
            style={{
              display: 'flex',
              padding: '0px 0px',
              width: '100%',
              maxWidth: '1450px',
              padding: '0 0',
            }}
          > 
            <div style={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', // Adjusted minimum width
              gap: '20px', // Adjusted gap between items
            }}>
              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.sb.title')}
                  icon="/icon-sb.svg"
                  cardcolor="#34495e"
                  description={t('programmeData.sb.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Standortbestimmung"
                  overviewLink="/StandortbestimmungOverview"
                  active={true}
                  soon={false}
                  //ribbon="Preview"
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.verzeichnis.title')}
                  icon="/icon-verzeichnis.svg"
                  cardcolor="#eb3b5a"
                  description={t('programmeData.verzeichnis.description')}
                  progressPercentage={25}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Verzeichnis"
                  overviewLink="/VerzeichnisOverview"
                  active
                />
              </div>
              
              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.dse.title')}
                  icon="/icon-dse.svg"
                  cardcolor="#fa8231"
                  description={t('programmeData.dse.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Dsa"
                  overviewLink="/DsaOverview"
                  active
                  ribbon=""
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.tom.title')}
                  icon="/icon-tom.svg"
                  cardcolor="#f7b731"
                  description={t('programmeData.tom.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Toms"
                  overviewLink="/TomsOverview"
                  ribbon=""
                  active
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.advv.title')}
                  icon="/icon-advv.svg"
                  cardcolor="#20bf6b"
                  description={t('programmeData.advv.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Advv"
                  overviewLink="/Advv"
                  active
                  ribbon=""
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.ghv.title')}
                  icon="/icon-ghv.svg"
                  cardcolor="#0fb9b1"
                  description={t('programmeData.ghv.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Ghv"
                  overviewLink="/Ghv"
                  active
                  ribbon=""
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.br.title')}
                  icon="/icon-br.svg"
                  cardcolor="#45aaf2"
                  description={t('programmeData.br.description')}
                  progressPercentage={87}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/BR"
                  overviewLink="/BROverview"
                  active
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.dsfa.title')}
                  icon="/icon-dsfa.svg"
                  cardcolor="#4b7bec"
                  description={t('programmeData.dsfa.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Datenschutzfolgeabschaetzung"
                  overviewLink="/DatenschutzfolgeabschaetzungOverview"
                  active
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.dsv.title')}
                  icon="/icon-dsv.svg"
                  cardcolor="#a55eea"
                  description={t('programmeData.dsv.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Datenschutzverletzung"
                  overviewLink="/DatenschutzverletzungOverview"
                  active
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.aa.title')}
                  icon="/icon-aa.svg"
                  cardcolor="#778ca3"
                  description={t('programmeData.aa.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/AA"
                  overviewLink="/AA"
                  active
                  ribbon=""
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.cyberver.title')}
                  icon="fa-solid fa-face-meh-blank"
                  description={t('programmeData.cyberver.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Cyberver"
                  overviewLink="/CyberverOverview"
                  ribbon="Tipp"
                  imageSrc={mobiliar_logo}
                  alternative={1}
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.proton.title')}
                  cardcolor="#8a90c7"
                  icon="fa-solid fa-face-meh-blank"
                  description={t('programmeData.proton.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="https://go.getproton.me/aff_c?offer_id=7&aff_id=9042" 
                  overviewLink="https://go.getproton.me/aff_c?offer_id=7&aff_id=9042"
                  ribbon="Tipp"
                  imageSrc={"proton_logo.png"}
                  alternative={2}
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Programme };