import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const lngs = [
  {
    value: 'en',
    label: '🇬🇧 English',
  },
  {
    value: 'de',
    label: '🇩🇪 Deutsch',
  },
];

const dropdownStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    border: "solid #D4D4D4 1px",
    backgroundColor: '#ffffff',
    fontSize: 14,
    // boxShadow:
    //   'inset -3px -3px 6px rgba(255, 255, 255, 0.5), inset 3px 3px 6px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    height: 20,
    width: 20,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#ffffff',
    zIndex: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#D33C2E' : '#000',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#D33C2E',
      color: '#fff',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '10px 20px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 10px',
  }),
};

export const LanguageSelect = () => {
  const { i18n } = useTranslation();

  return (
    <Select
      options={lngs}
      onChange={(option) => i18n.changeLanguage(option.value)}
      defaultValue={lngs.find((lng) => lng.value === i18n.language)}
      styles={dropdownStyles}
    />
  );
};
