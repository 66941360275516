import React from 'react';
import Logo_Red_New from '../../../../src/assets/images/Logo_Red_New.png';
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import { formatRiskFactor } from '.';
import { useTranslation } from 'react-i18next';

const DetailedViewPdf = ({ data }) => {
  const { companyInfo } = data;
  const { t } = useTranslation('common');

  const checkboxes = t('dsfa.steps.4.inputs', { returnObjects: true });
  const pointDisplayMapping = t('detailedView.dsfa.pointDisplayMapping', {
    returnObjects: true,
  });
  const LABELS = t('detailedView.dsfa.labels', { returnObjects: true });

  const pdfStyles = StyleSheet.create({
    title: {
      fontSize: 16,
      textAlign: 'center',
      padding: 10,
      marginBottom: '30px',
    },
    documenttitle: {
      fontSize: 16,
      textAlign: 'center',
      padding: 4,
      marginBottom: '4px',
    },
    documentsubtitle: {
      fontSize: 10,
      textAlign: 'center',
      padding: 2,
      marginBottom: '6px',
    },

    documentcompanydata: {
      fontSize: 12,
      textAlign: 'center',
      padding: 2,
      marginBottom: '6px',
      marginTop: '10px',
    },

    section: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10,
    },
    outerPadding: {
      padding: 40, // Replace '10' with the amount of padding you want
    },
    sectionTitle: {
      fontSize: 12,
      marginLeft: '10px',
      marginBottom: '10px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      fontSize: '10px',
      alignItems: 'left',
    },

    rowOdd: {
      backgroundColor: 'rgb(232, 232, 232)',
    },
    tableHeaders: {
      backgroundColor: '#d33c2e',
      color: '#fff',
      marginTop: -1,
      fontWeight: '700',
    },
    mainPointTitle: {
      fontSize: 12,
      marginLeft: '10px',
      marginBottom: '10px',
    },

    mainFieldTitle: {
      fontSize: 12,
      marginLeft: '20px',
    },

    mainPointValue: {
      width: '70%',
    },
    categoryPointList: {
      display: 'flex',
      flexDirection: 'column',
      listStyle: 'square',
    },

    points: {
      marginLeft: '30px',
    },
    logo: {
      textAlign: 'right',
      width: '30%',
      marginTop: '-20px',
    },
    logoContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: 20,
    },
    pageContainer: {
      paddingVertical: '40px',
      paddingHorizontal: '20px',
    },
  });

  return (
    <Document>
      <Page size="A4" style={pdfStyles.pageContainer}>
        <View style={pdfStyles.logoContainer}>
          <Image style={pdfStyles.logo} source={Logo_Red_New} />
        </View>

        {data && (
          <View style={pdfStyles.outerPadding}>
            <View style={pdfStyles.documenttitle}>
              <Text>
                {t('detailedView.dsfa.title')} - {t('detailedView.entry')}{' '}
                {data.id}
              </Text>
            </View>

            <View style={pdfStyles.documentsubtitle}>
              <Text>
                {t('detailedView.dateCreated')}: {data.dateCreated}
              </Text>
              <Text>
                {t('detailedView.lastEdited')}: {data.lastEdited}
              </Text>
            </View>

            <View style={pdfStyles.documentcompanydata}>
              <Text>{companyInfo?.CompanyName}</Text>
              <Text>
                {companyInfo?.CompanyStreet} {companyInfo?.CompanyStreetNr}
              </Text>
              <Text>
                {companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}
              </Text>
            </View>

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
                marginTop: 20,
              }}
            />

            {['step1'].map((step) => (
              <View style={pdfStyles.section} key={step}>
                <View>
                  {Object.keys(data[step].categories).map((point, idx) => (
                    <View
                      style={{ ...pdfStyles.rowView, flexDirection: 'column' }}
                      key={idx}
                    >
                      <Text style={pdfStyles.mainPointTitle}>
                        {LABELS.step1[point]}
                      </Text>
                      <View className={pdfStyles.categoryPointList}>
                        {Array.isArray(data[step].categories[point]?.preset)
                          ? data[step].categories[point]?.preset.map(
                              (obj, i) => (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    marginBottom: 1,
                                  }}
                                  key={i}
                                >
                                  <Text
                                    style={{ marginLeft: 20, marginRight: 8 }}
                                  >
                                    •
                                  </Text>
                                  <Text>{obj.label}</Text>
                                </View>
                              )
                            )
                          : null}
                        {Array.isArray(data[step].categories[point]?.custom)
                          ? data[step].categories[point]?.custom.map(
                              (obj, i) => (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    marginBottom: 1,
                                  }}
                                  key={i}
                                >
                                  <Text
                                    style={{ marginLeft: 20, marginRight: 8 }}
                                  >
                                    •
                                  </Text>
                                  <Text>{obj.label}</Text>
                                </View>
                              )
                            )
                          : null}
                      </View>
                    </View>
                  ))}

                  <View>
                    <Text style={pdfStyles.mainFieldTitle}>Beschreibung</Text>
                    <View style={pdfStyles.rowView}>
                      <Text style={pdfStyles.points}>
                        {data[step].description}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            ))}

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
              }}
            />

            {['step2'].map((step) => (
              <View style={pdfStyles.section} key={step}>
                <View>
                  {Object.keys(data[step]).map((point) => (
                    <View key={point}>
                      <Text style={pdfStyles.mainFieldTitle}>
                        {pointDisplayMapping[point] || ''}
                      </Text>
                      <View style={pdfStyles.rowView}>
                        <Text style={pdfStyles.points}>
                          {data[step][point]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            ))}

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
              }}
            />

            {['step3'].map((step) => (
              <View style={pdfStyles.section} key={step}>
                <View>
                  {Object.keys(data[step]).map((point, idx) => (
                    <View
                      style={{ ...pdfStyles.rowView, flexDirection: 'column' }}
                      key={idx}
                    >
                      <Text style={pdfStyles.mainPointTitle}>
                        {pointDisplayMapping[point]}
                      </Text>
                      <View className={pdfStyles.categoryPointList}>
                        {Array.isArray(data[step][point]?.preset)
                          ? data[step][point]?.preset.map((obj, i) => (
                              <View
                                style={{
                                  flexDirection: 'row',
                                  marginBottom: 1,
                                }}
                                key={i}
                              >
                                <Text
                                  style={{ marginLeft: 20, marginRight: 8 }}
                                >
                                  •
                                </Text>
                                <Text>{obj.label}</Text>
                              </View>
                            ))
                          : null}
                        {Array.isArray(data[step][point]?.custom)
                          ? data[step][point]?.custom.map((obj, i) => (
                              <View
                                style={{
                                  flexDirection: 'row',
                                  marginBottom: 1,
                                }}
                                key={i}
                              >
                                <Text
                                  style={{ marginLeft: 20, marginRight: 8 }}
                                >
                                  •
                                </Text>
                                <Text>{obj.label}</Text>
                              </View>
                            ))
                          : null}
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            ))}

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
              }}
            />

            <View style={pdfStyles.section}>
              <View>
                <View>
                  <Text style={pdfStyles.mainPointTitle}>Risk factors</Text>
                  <View className={pdfStyles.categoryPointList}>
                    {Object.keys(data.step4).map((point) => {
                      return data.step4[point] ? (
                        <View
                          style={{
                            flexDirection: 'row',
                            marginBottom: 4,
                            fontSize: 10,
                            marginLeft: 30,
                          }}
                          key={point}
                        >
                          <Text style={{ marginHorizontal: 8 }}>•</Text>
                          <Text
                            style={{
                              width: '65%',
                            }}
                          >
                            {point === 'other'
                              ? data.step4[point]?.input ?? data.step4[point]
                              : formatRiskFactor(checkboxes, point)}
                          </Text>
                        </View>
                      ) : null;
                    })}
                  </View>
                </View>
              </View>
            </View>

            <View style={pdfStyles.section}>
              <View>
                {Object.keys(data.step5).map((type) =>
                  Object.keys(data.step5[type]).map((point) => (
                    <View style={{ marginBottom: 10 }} key={point}>
                      <Text style={pdfStyles.mainPointTitle}>{point}</Text>
                      <Text style={{ fontSize: 10, marginLeft: 30 }}>
                        {data.step5[type][point]}
                      </Text>
                    </View>
                  ))
                )}
              </View>
            </View>

            <View style={pdfStyles.section}>
              <Text style={pdfStyles.sectionTitle}>Step 6</Text>
              <View>
                {Object.keys(data.step6).map((type) =>
                  Object.keys(data.step6[type]).map((point) => (
                    <View key={point} style={{ marginBottom: 10 }}>
                      <Text style={pdfStyles.mainPointTitle}>{point}</Text>
                      <Text style={{ fontSize: 10, marginLeft: 30 }}>
                        {data.step6[type][point]}
                      </Text>
                    </View>
                  ))
                )}
              </View>
            </View>

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
              }}
            />

            {['step7'].map((step) => (
              <View style={pdfStyles.section} key={step}>
                <View>
                  {Object.keys(data[step]).map((point) => (
                    <View key={point}>
                      <Text style={pdfStyles.mainFieldTitle}>
                        {pointDisplayMapping[point] || ''}
                      </Text>
                      <View style={pdfStyles.rowView}>
                        <Text style={pdfStyles.points}>
                          {data[step][point]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            ))}

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
              }}
            />

            {['step8'].map((step) => (
              <View style={pdfStyles.section} key={step}>
                <View>
                  {Object.keys(data[step]).map((point) => (
                    <View key={point}>
                      <Text style={pdfStyles.mainFieldTitle}>
                        {pointDisplayMapping[point] || ''}
                      </Text>
                      <View style={pdfStyles.rowView}>
                        <Text style={pdfStyles.points}>
                          {data[step][point]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </View>
        )}
      </Page>
    </Document>
  );
};

export { DetailedViewPdf };
