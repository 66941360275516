import React from 'react';
import { Checkbox } from 'components/Checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

export const getDocsLabel = (checkboxes, name) => {
  const point = checkboxes
    .map((category) => category.points.find((point) => point.name === name))
    .filter(Boolean)[0];

  return point ? point.label : null;
};

const BR_Step6 = () => {
  const { control } = useFormContext();
  const { t } = useTranslation('common');

  const CHECKBOXES = t('br.steps.6.inputs', { returnObjects: true });

  return (
    <div className="card-body" style={{ alignItems: 'flex-start' }}>
      <div className={styles.steptextlast}>{t('br.steps.6.description')}</div>

      {CHECKBOXES.map((section) => {
        return (
          <React.Fragment key={section.heading}>
            <h4 style={{ marginTop: '40px' }}>{section.heading}</h4>
            <div className={styles.checkboxesWrapper}>
              {section.points.map((checkbox) => (
                <Controller
                  key={checkbox.name}
                  control={control}
                  name={`step6.${checkbox.name}`}
                  defaultValue={0}
                  render={({ field }) => (
                    <Checkbox
                      {...checkbox}
                      {...field}
                      step="step6"
                      name={checkbox.name}
                      checkboxTargetClassName="accent-blue"
                    />
                  )}
                />
              ))}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export { BR_Step6 };
