import { useNavigate } from 'react-router-dom';

export const useDokumenteActions = () => {
  const navigate = useNavigate();

  const handleView = (type, id) => {
    switch (type) {
      case 'Verzeichnis Bearbeitungstätigkeiten':
        return navigate(`/VerzeichnisOverview/${id}`);
      case 'Bearbeitungsreglement':
        return navigate(`/BROverview/${id}`);
      case 'Datenschutzverletzung':
        return navigate(`/DatenschutzverletzungOverview/${id}`);
      case 'Datenschutz-Folgenabschätzung':
        return navigate(`/DatenschutzfolgeabschaetzungOverview/${id}`);
      case 'Datenschutzerklarung':
        return navigate(`/DsaOverview/${id}`);
    }
  };

  const handleEdit = (type, id) => {
    switch (type) {
      case 'Verzeichnis Bearbeitungstätigkeiten':
        return navigate(`/Verzeichnis/${id}`);
      case 'Bearbeitungsreglement':
        return navigate(`/BR/${id}`);
      case 'Datenschutzverletzung':
        return navigate(`/Datenschutzverletzung/${id}`);
      case 'Datenschutz-Folgenabschätzung':
        return navigate(`/Datenschutzfolgeabschaetzung/${id}`);
      case 'Datenschutzerklarung':
        return navigate(`/Dsa/${id}`);
    }
  };

  return { handleView, handleEdit };
};
