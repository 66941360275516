import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { getApiUrl } from 'services/getApiUrl';

const useApi = () => {
  const apiRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    let isMounted = true;

    if (!account?.username) return;

    const createApiInstance = async () => {
      const silentAccessTokenRequest = {
        account,
        scopes: ['50dc3e8e-4773-4c87-983e-384cd9bf4973'],
      };

      const accessTokenRequest = {
        scopes: ['50dc3e8e-4773-4c87-983e-384cd9bf4973'],
        loginHint: account.username,
      };

      const tokenResponse = await instance
        .acquireTokenSilent(silentAccessTokenRequest)
        .catch(async () => {
          return await instance.acquireTokenPopup(accessTokenRequest);
        });
      const token = `Bearer ${tokenResponse.accessToken}`;

      const authorizedApi = axios.create({
        baseURL: getApiUrl(),
        headers: {
          Authorization: token,
        },
      });

      return authorizedApi;
    };

    createApiInstance().then((instance) => {
      if (isMounted) {
        apiRef.current = instance;
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [instance, account]);

  const isApiReady = () => {
    return !loading && apiRef.current !== null;
  };

  return { api: apiRef.current, isApiReady };
};

export { useApi };
