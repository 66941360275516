import ReactDOM from 'react-dom';

import styles from './styles.module.css';

const Modal = ({ className, children }) => {
  return ReactDOM.createPortal(
    <div className={styles.modalOverlay}>
      <div className={styles.modalBackground} />
      <div className={`${styles.modal} ${className}`}>{children}</div>
    </div>,
    document.getElementById('modal')
  );
};

export { Modal };
