import React from 'react';
import { ServiceCard, Navbar } from 'components';
import { useTranslation } from 'react-i18next';
import { MobileNavbar } from 'components/MobileNavbar';
import mobiliar_logo from '../../src/assets/images/Logo_Mobiliar_Alt.png';
import TipBar from 'components/TipBar'; // Import TipBar component
import { Programme } from './Programme';

function Home() {return (Programme());}

export { Home };
