export const getMsalRedirectUri = () => {
  const environment = process.env.NODE_ENV;
  const domain = window.location.hostname.split('.')[0];

  switch (environment) {
    case 'development':
      return 'http://localhost:3000/Home';
    case 'production':
      if (domain === 'maintain') {
        return 'https://maintain.datenschutzkonform.ch/Home';
      }

      if (domain === 'tool') {
        return 'https://tool.datenschutzkonform.ch/Home';
      }
    default:
      return '';
  }
};
