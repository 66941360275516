import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Loader } from 'components/Loader';

import styles from './styles.module.css';

const Toms_Step5 = () => {
  const [inputControl, setInputControl] = React.useState(null);
  const [hasFetched, setHasFetched] = React.useState(false);
  const { control, formState } = useFormContext();
  const { api } = useApi();
  const validation = useValidation();

  const fetchData = async () => {
    const inputControlResponse = await api.get('/toms_inputcontrol');

    setInputControl(inputControlResponse.data);
  };

  React.useEffect(() => {
    if (api && !hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [api]);

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        Massnahmen, die geeignet sind zu verhindern, dass
        Datenverarbeitungssysteme von Unbefugten genutzt werden können.
      </div>
      <div className={styles.tomsInputsWrapper}>
        {!inputControl && <Loader />}
        {inputControl && (
          <Controller
            name="step5.inputControl"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label="Eingabekontrolle"
                options={inputControl.map((entry) => {
                  return {
                    label: entry.InputcontrolName,
                    value: entry.InputcontrolId,
                  };
                })}
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step5?.inputControl}
                name={field.name}
                isMulti
                customColor="#f7b731"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Toms_Step5 };
