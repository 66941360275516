import React from 'react';

import { useValidation } from 'helpers/validation';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup } from 'components';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const DSV_Step6 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const options = t('radioOptions', { returnObjects: true });

  return (
    <div className="card-body">
      <h3>Allgemeine Einschätzung</h3>
      <div className={styles.dsvInputsWrapper}>
        <Controller
          name="step6.highrisk"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('dsv.steps.6.inputs.highrisk')}
              name="highrisk"
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step6?.highrisk}
              customColor="#a55eea"
            />
          )}
        />
        <Controller
          name="step6.reportabledata"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('dsv.steps.6.inputs.reportableData')}
              name="reportabledata"
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step6?.reportabledata}
              customColor="#a55eea"
            />
          )}
        />
        <Controller
          name="step6.reportableaffected"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('dsv.steps.6.inputs.reportableAffected')}
              name="reportableaffected"
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step6?.reportableaffected}
              customColor="#a55eea"
            />
          )}
        />
      </div>
    </div>
  );
};

export { DSV_Step6 };
