import React from 'react';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './styles.css';
import { FormField } from 'components/FormField';

const Slider = ({
  marks,
  min,
  max,
  error,
  onChange,
  value,
  title,
  handleStyle,
}) => {
  return (
    <FormField error={error} isSlider>
      <label className="slider-label">{title}</label>
      <RCSlider
        marks={marks}
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        handleStyle={handleStyle}
      />
    </FormField>
  );
};

export { Slider };
