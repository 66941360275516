import React from 'react';
import { useTranslation } from 'react-i18next';


const Dsa_Step7 = () => {
  const { t } = useTranslation('common');
  return (
    <div className="card-body">
      <h4>{t('dsa.steps.7.description')}</h4>
      <span>
      {t('dsa.steps.7.text')}
      </span>
    </div>
  );
};

export { Dsa_Step7 };
