import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useApi } from 'services/axios';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from 'components/DeleteModal';
import { useTranslation } from 'react-i18next';
import {
  Navbar,
  Loader,
  MobileNavbar,
  OverviewPdf,
  UploadsTable,
} from 'components';
import { BlobServiceClient } from '@azure/storage-blob';
import { azureStorageConfig } from 'msalConfig';
import { getStorageEnv } from 'services/getStorageEnv';

import EyeIcon from 'assets/images/eye-icon.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';
import TipBar from 'components/TipBar';

import styles from './styles.module.css';

const normalizeDate = (dateString) => {
  let normalizedDate = dateString;
  // Datum im Format dd-mm-yyyy
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    normalizedDate = dateString.replace(/-/g, '.');
  }
  // Datum im Format yyyy-mm-dd
  else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    normalizedDate = `${day}.${month}.${year}`;
  }
  return normalizedDate;
};

const Table = ({ data, setShowModal, companyData }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },

      {
        Header: t('overview.companyName'),
        accessor: 'companyName',
      },

      {
        Header: t('overview.lawName'),
        accessor: 'lawName',
      },

      {
        Header: t('overview.created'),
        accessor: 'dateCreated',
        Cell: ({ value }) => normalizeDate(value), // Normalisiert das Datum vor der Anzeige
        sortType: (a, b) => {
          const parseDate = (dateString) => {
            const [day, month, year] = normalizeDate(dateString).split('.');
            return `${year}-${month}-${day}`;
          };
      
          const dateA = parseDate(a.original.lastEdited);
          const dateB = parseDate(b.original.lastEdited);
      
          return dateA.localeCompare(dateB);
        }
      },
      {
        Header: t('overview.edited'),
        accessor: 'lastEdited',
        Cell: ({ value }) => normalizeDate(value), // Normalisiert das Datum vor der Anzeige
        sortType: (a, b) => {
          const parseDate = (dateString) => {
            const [day, month, year] = normalizeDate(dateString).split('.');
            return `${year}-${month}-${day}`;
          };
      
          const dateA = parseDate(a.original.lastEdited);
          const dateB = parseDate(b.original.lastEdited);
      
          return dateA.localeCompare(dateB);
        }      },

      // {
      //   Header: t('overview.companyName'),
      //   id: 'company',
      //   Cell: () => <span>{companyData?.CompanyName}</span>,
      // },
      {
        Header: t('overview.action'),
        id: 'aktionen',
        Cell: ({ row }) => (
          <span className={styles.actionsWrapper}>
            <img
              src={EyeIcon}
              className={styles.tableIcon}
              onClick={() => navigate(`/DsaOverview/${row.values.id}`)}
            />
            <img
              src={EditIcon}
              className={styles.tableIcon}
              onClick={() => navigate(`/Dsa/${row.values.id}`)}
            />
            <img
              src={DeleteIcon}
              className={styles.tableIcon}
              onClick={() => {
                setShowModal(row.values.id);
              }}
            />
          </span>
        ),
      },
    ],
    [i18n.language]
  );

  const initialState = { hiddenColumns: ['id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  return (
    <table {...getTableProps()} className={`${styles.dsaTable} table-hover`}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={styles.tableHeading}
                onClick={() => {
                  if (column.id !== 'aktionen') {
                    column.toggleSortBy(!column.isSortedDesc);
                  }
                }}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' \u2193'
                      : ' \u2191'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={styles.tableData}
                    style={
                      cell.getCellProps().key.includes('aktionen')
                        ? { width: '40px' }
                        : undefined
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const DsaOverview = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showUploadsModal, setShowUploadsModal] = React.useState(false);
  const [overviewData, setOverviewData] = React.useState(null);
  const [uploadsData, setUploadsData] = React.useState(null);
  const [meData, setMeData] = React.useState(null);
  const [pdfFormattedData, setPdfFormattedData] = React.useState(null);
  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const fetchMe = async () => {
    try {
      const response = await api.get('/me');

      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    if (api && !meData) {
      fetchMe();
    }
  }, [api]);

  const blobClient = new BlobServiceClient(
    `https://${azureStorageConfig.ACCOUNT}.blob.core.windows.net/?${azureStorageConfig.SAS_TOKEN}`
  );

  const containerClient = blobClient.getContainerClient(
    `user${meData?.id}-${getStorageEnv()}`
  );

  const fetchData = async () => {
    try {
      const overviewResponse = await api.get('/dsa_alldata');
      const uploadsResponse = await api.get('/dsa-uploads');

      setUploadsData(uploadsResponse.data);
      setOverviewData(overviewResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDelete = async () => {
    await api.delete(`/dsa_alldata/${showModal}`);

    fetchData();
    setShowModal(false);
  };

  const handleDeleteUploads = async () => {
    if (!showUploadsModal.name) return;

    const options = {
      deleteSnapshots: 'include',
    };

    const blockBlobClient = containerClient.getBlockBlobClient(
      `Dsa/${showUploadsModal.name}`
    );
    await blockBlobClient.deleteIfExists(options);
    await api.delete(`/dsa-uploads/${showUploadsModal.id}`);

    fetchData();
    setShowUploadsModal(false);
  };

  const handleDownload = async (filename) => {
    const blockBlobClient = containerClient.getBlockBlobClient(
      `Dsa/${filename}`
    );
    try {
      const blobUrl = blockBlobClient.url;

      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      document.body.appendChild(anchor);

      anchor.href = blobUrl;
      anchor.download = filename;

      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const renderTable = () => {
    if (overviewData?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!overviewData) {
      return <Loader />;
    }

    return <Table data={overviewData} setShowModal={setShowModal} />;
  };

  const renderUploadsTable = () => {
    if (uploadsData?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!uploadsData) {
      return <Loader />;
    }

    return (
      <UploadsTable
        data={uploadsData}
        setShowModal={setShowUploadsModal}
        handleDownload={handleDownload}
      />
    );
  };

  React.useEffect(() => {
    if (isApiReady && !overviewData && !uploadsData) {
      fetchData();
    }
  }, [isApiReady]);

  React.useEffect(() => {
    if (overviewData) {
      setPdfFormattedData({
        columns: ['Firma', 'Gesetzesgrundlage', 'Stand'],
        data: overviewData?.map((entry) => {
          return {
            Stand: entry.lastEdited.replace(/-/g, '.'),
            Firma: entry.companyName,
            Gesetzesgrundlage: entry.lawName
          };
        }),
      });
    }
  }, [overviewData]);

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
    <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar />
      <MobileNavbar />
      <div
        className={styles.overviewContainer}
        style={{ overflow: 'auto' }}
        id="content"
      >
        <div
          className={`card morph_variation1 padder ${styles.overviewCard}`}
          style={{ gap: '25px' }}
        >
          <div className={styles.overviewHeadingWrapper}>
            <h4 className={styles.overviewHeading}>{t('overview.dsa.title')}</h4>

            <div className={styles.headingBtnWrapper}>
              <button
                className="btn btn-primary accent-orange-bg"
                onClick={() => navigate('/Dsa')}
              >
                {t('overview.createBtn')}
              </button>
              <PDFDownloadLink
                document={
                  <OverviewPdf
                    data={pdfFormattedData}
                    title="Übersicht Datenschutzerklärungen"
                  />
                }
                fileName="Datenschutzerklärungen_Übersicht.pdf"
              >
                {({ loading }) => (
                  <button className="btn btn-default">
                    {loading
                      ? 'Loading document...'
                      : t('overview.downloadPDF')}
                  </button>
                )}
              </PDFDownloadLink>
            </div>
          </div>
          <div style={{ overflowX: 'auto' }}>{renderTable()}</div>
          {showModal && (
            <DeleteModal
              onCancel={() => setShowModal(false)}
              onConfirm={() => handleDelete()}
            />
          )}
        </div>

        <div
          className={`card morph_variation1 padder ${styles.overviewCard}`}
          style={{ gap: '25px' }}
        >
          <div className={styles.overviewHeadingWrapper}>
            <h4 className={styles.overviewHeading}>
            {t('overview.dsa.data')}
            </h4>

            <div className={styles.headingBtnWrapper}>
              <button
                className="btn btn-primary accent-orange-bg"
                onClick={() => navigate('/DsaUpload')}
              >
                {t('overview.upload')}
                </button>
            </div>
          </div>
          <div style={{ overflowX: 'auto' }}>{renderUploadsTable()}</div>
          {showModal && (
            <DeleteModal
              onCancel={() => setShowModal(false)}
              onConfirm={() => handleDelete()}
            />
          )}
          {showUploadsModal && (
            <DeleteModal
              onCancel={() => setShowUploadsModal(false)}
              onConfirm={() => handleDeleteUploads()}
            />
          )}
        </div>
      </div>
    </div>
    </div>

  );
};

export { DsaOverview };
