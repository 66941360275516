import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Textarea } from 'components';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

const Dsfa_Step7 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');

  const FIELDS = t('dsfa.steps.7.inputs', { returnObjects: true });

  return (
    <div className="card-body">
      <h4 style={{ marginBottom: '25px' }}>{t('dsfa.steps.7.title')}</h4>
      <div className={styles.steptext}>{t('dsfa.steps.7.description')}</div>
      <div className={styles.dsfaInputsWrapper}>
        {FIELDS.map((item) => (
          <Controller
            key={item.name}
            name={`step7.${item.name}`}
            control={control}
            render={({ field }) => (
              <Textarea
                id={item.name}
                error={formState.errors?.step7?.[item.name]}
                {...item}
                {...field}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export { Dsfa_Step7 };
