import React from 'react';
import { Loader, Navbar, MobileNavbar } from 'components';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';

import { Table } from './components/table';
import { Navigate } from 'react-router-dom';
import TipBar from 'components/TipBar';


// import styles from './styles.module.css';

function AdminPanel() {
  const [data, setData] = React.useState(null);
  const [meData, setMeData] = React.useState(null);
  const [triggerRefetch, setTriggerRefetch] = React.useState(false);
  const { t } = useTranslation('common');
  const { api } = useApi();

  const fetchData = async () => {
    try {
      const userResponse = await api.get('/users');

      setData(userResponse.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchMe = async () => {
    try {
      const response = await api.get('/me');

      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSelect = async (id) => {
    const response = await api.post('/user-select', { id });

    fetchMe();
    fetchData();
    setTriggerRefetch((prev) => !prev);
  };

  React.useEffect(() => {
    if (api && !meData) {
      fetchMe();
    }
  }, [api]);

  React.useEffect(() => {
    if (meData?.isAdmin && !data) {
      fetchData();
    }
  }, [meData]);

  const renderTable = () => {
    if (data?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!data) {
      return <Loader />;
    }

    return <Table data={data} meData={meData} handleSelect={handleSelect} />;
  };

  if (meData && !meData.isAdmin) return <Navigate to="/Home" />;

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
    <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar shouldRefetch={triggerRefetch} />
      <MobileNavbar />
      <div id="content" style={{ padding: '50px' }}>
        <div className="container" style={{ width: '100%' }}>
          <div
            className="card morph_variation1 padder"
            style={{ marginBottom: 50 }}
          >
            <div className="row">
              <div className="col-md-12">
                <h4 style={{ margin: '0 0 25px 0' }}>Admin panel</h4>
                <div
                  className="card-body"
                  style={{ overflowX: 'auto', display: 'block' }}
                >
                  {renderTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export { AdminPanel };
