import React from 'react';

import { useApi } from 'services/axios';
import { Navbar, Loader, MobileNavbar } from 'components';
import { Link, useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DetailedViewPdf } from './detailedViewPdf';
import { getEditingLabel } from '../steps/BR_Step1';
import { useTranslation } from 'react-i18next';
import TipBar from 'components/TipBar';
import styles from './styles.module.css';

function formatDocsAndMeasures(responseData, checkboxes) {
  const groupedData = checkboxes
    .map((section) => {
      const heading = section.heading;
      const points = section.points
        .map((point) => {
          const name = point.name;
          const value = responseData[name].hasOwnProperty('input')
            ? responseData[name].input
            : null;
          return { ...point, value };
        })
        .filter((point) => {
          return point.value !== null;
        });

      return { heading, points };
    })
    .filter((section) => section.points.length > 0);

  return groupedData;
}

const BRDetailedView = () => {
  const { id } = useParams();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);

  const checkboxes = t('br.steps.1.inputs', { returnObjects: true });
  const checkboxesStep6 = t('br.steps.6.inputs', { returnObjects: true });
  const pointDisplayMapping = t('detailedView.br.pointDisplayMapping', {
    returnObjects: true,
  });
  const labels = t('detailedView.br.labels.step3', { returnObjects: true });

  const description = data?.step1?.description;
  delete data?.step1?.description;

  const fetchEntry = async () => {
    try {
      const entryResponse = await api.get(`/br_alldata/${id}`);
      const companyResponse = await api.get(`/companydata`);

      setError(null);
      setData(entryResponse.data);
      setCompanyData(companyResponse.data[0]);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api && !data) {
      fetchEntry();
    }
  }, [api]);

  if (error) {
    return (
      <div className="wrapper">
        <div id="content">
          <Link to="/BROverview" className={styles.detailedViewLink}>
            <button className="btn btn-default">
              {t('detailedView.backToOverview')}
            </button>
          </Link>
          <h2>{t('detailedView.notFound')}</h2>
        </div>
      </div>
    );
  }

  if (!data)
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );

  const formattedDocsAndMeasures = formatDocsAndMeasures(
    data?.step6,
    checkboxesStep6
  );

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar />
      <MobileNavbar />
      <div
        className={`${styles.detailedViewWrapper} morph_variation1`}
        id="content"
      >
        <div className={styles.detailedViewHeadingWrapper}>
          <div className={styles.detailedViewTitleWrapper}>
            <Link to="/BROverview" className={styles.detailedViewLink}>
              <button className="btn btn-default">
                {t('detailedView.backToOverview')}
              </button>
            </Link>
          </div>
          <div className={styles.detailedViewLinks}>
            <Link to={`/BR/${id}`} className={styles.detailedViewLink}>
              <button className="btn btn-default">
                {t('detailedView.edit')}
              </button>
            </Link>
            <PDFDownloadLink
              document={
                <DetailedViewPdf
                  data={{ ...data, id, companyInfo: companyData, description }}
                />
              }
              fileName={`brEntry-${id}`}
            >
              {({ loading }) => (
                <button className="btn btn-primary accent-blue-bg">
                  {loading
                    ? t('detailedView.loadingDocument')
                    : t('detailedView.downloadPDF')}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>

        <hr />

        <div className={styles.gapWrapper}>
          <div className={styles.mainInfoPoints}>
            <div className={styles.mainInfoPoint}>
              <span className={styles.mainInfoPointTitle}>Bezeichnung</span>
              <span className={styles.mainInfoPointValueTitle}>{description}</span>
            </div>
          </div>
          <div className={styles.categoryPoints}>
            <div className={styles.categoryPoints}>
              <div className={styles.categoryPoint} style={{ width: '100%' }}>
                <h5 className={styles.categoryPointTitle}>
                  {t('detailedView.br.step1.title')}
                </h5>
                <ul className={styles.categoryPointList}>
                  {Object.keys(data.step1).map((point) => {
                    return data.step1[point] ? (
                      <li className={styles.categoryPointListItem} key={point}>
                        {point === 'other'
                          ? data.step1[point]?.input ?? data.step1[point]
                          : getEditingLabel(checkboxes, point)}
                      </li>
                    ) : null;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step2).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.mainInfoPointValue}>
                    {data.step2[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <hr />

        <div className={styles.gapWrapper}>
          <div className={styles.categoryPoints}>
            {Object.keys(data.step3).map((point) => {
              return data.step3[point]?.preset?.length > 0 ||
                data.step3[point]?.custom?.length > 0 ? (
                <div className={styles.categoryPoint} key={point}>
                  <h5 className={styles.categoryPointTitle}>{labels[point]}</h5>
                  <ul className={styles.categoryPointList}>
                    {data.step3[point]?.preset.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                    {data.step3[point]?.custom.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null;
            })}
          </div>
        </div>
        <hr />

        <div className={styles.gapWrapper}>
          <div className={styles.categoryPoints}>
            {Object.keys(data.step4).map((point) => {
              return data.step4[point]?.preset?.length > 0 ||
                data.step4[point]?.custom?.length > 0 ? (
                <div className={styles.categoryPoint} key={point}>
                  <h5 className={styles.categoryPointTitle}>
                    {t('detailedView.br.step4.title')}
                  </h5>

                  {/*  <h5 className={styles.categoryPointTitle}>{point}</h5>  */}
                  <ul className={styles.categoryPointList}>
                    {data.step4[point]?.preset.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                    {data.step4[point]?.custom.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null;
            })}
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step5).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.mainInfoPointValue}>
                    {data.step5[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <hr />

        <div className={styles.gapWrapper}>
          <div className={styles.categoryPoints}>
            <div className={styles.categoryPoints}>
              <div className={styles.categoryPoint} style={{ width: '100%' }}>
                <ul className={styles.categoryPointList}>
                  {Object.keys(formattedDocsAndMeasures).map((sectionIdx) => {
                    const section = formattedDocsAndMeasures[sectionIdx];
                    return (
                      <React.Fragment key={sectionIdx}>
                        <li style={{ fontWeight: 'bold' }}>
                          {section.heading}
                        </li>
                        <ul>
                          {section.points.map((point) => (
                            <li key={point.label}>{`${point.label.trim()}: ${
                              point.value
                            }`}</li>
                          ))}
                        </ul>
                      </React.Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
            <div>
              <div className={styles.gapdates}>
                <span>{t('detailedView.dateCreated')}</span><span>{data.dateCreated.replace(/-/g, '.')}</span><br>
                </br>
                <span>{t('detailedView.lastEdited')}</span><span>{data.lastEdited.replace(/-/g, '.')}</span>
              </div>          
            </div>
      </div>
    </div>
    </div>
  );
};

export { BRDetailedView };
