import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';
import { useValidation } from 'helpers/validation';
import { useFormContext, Controller } from 'react-hook-form';
import { Loader } from 'components/Loader';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

const Verzeichnis_Step3 = () => {
  const [recipients, setRecipients] = React.useState(null);
  const [affectedIndividuals, setAffectedIndividuals] = React.useState(null);
  const [hasFetched, setHasFetched] = React.useState(null);
  const { api } = useApi();
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const fetchData = async () => {
    try {
      const recipientsResponse = await api.get('/verzeichnis_recipients');
      const individualsResponse = await api.get('/verzeichnis_affected');

      setRecipients(recipientsResponse.data);
      setAffectedIndividuals(individualsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    if (api && !hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [api]);

  return (
    <div className="card-body">
      <h4>{t('verzeichnis.steps.3.title')}</h4>
      <div className={styles.steptext}>
        {t('verzeichnis.steps.3.description')}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        {(!affectedIndividuals || !recipients) && <Loader />}
        {affectedIndividuals && (
          <Controller
            control={control}
            name="step3.betroffenePersonen"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                options={affectedIndividuals.map((individual) => ({
                  label: t(`verzeichnis.steps.3.affected.${individual.AffectedId}`, individual.AffectedName),
                  value: individual.AffectedId,
                }))}
                isMulti
                label={t('verzeichnis.steps.3.inputs.affected')}
                isCreatable
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step3?.betroffenePersonen}
                name={field.name}
              />
            )}
          />
        )}
        <p></p>
        {recipients && (
          <Controller
            control={control}
            name="step3.datenempfänger"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                options={recipients.map((recipient) => ({
                  label: t(`verzeichnis.steps.3.recipients.${recipient.RecipientId}`, recipient.RecipientName),
                  value: recipient.RecipientId,
                }))}
                isMulti
                label={t('verzeichnis.steps.3.inputs.dataReceiver')}
                isCreatable
                onChange={field.onChange}
                value={field.value}
                error={formState.errors?.step3?.datenempfänger}
                name={field.name}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Verzeichnis_Step3 };