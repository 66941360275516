import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import { useDokumenteActions } from '../hooks';
import EyeIcon from 'assets/images/eye-icon.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';

import styles from '../styles.module.css';

export const Table = ({ data, setShowModal }) => {
  const { t, i18n } = useTranslation('common');
  const { handleView, handleEdit } = useDokumenteActions();

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      // {
      //   Header: t('overview.created'),
      //   accessor: 'dateCreated',
      // },
      {
        Header: t('dokumentePage.entryType'),
        accessor: 'type',
      },
      {
        Header: t('overview.edited'),
        accessor: 'lastEdited',
      },
      {
        Header: t('overview.action'),
        id: 'aktionen',
        Cell: ({ row }) => (
          <span className={styles.actionsWrapper}>
            <img
              src={EyeIcon}
              className={styles.tableIcon}
              onClick={() => handleView(row.values.type, row.values.id)}
            />
            <img
              src={EditIcon}
              className={styles.tableIcon}
              onClick={() => handleEdit(row.values.type, row.values.id)}
            />
            <img
              src={DeleteIcon}
              className={styles.tableIcon}
              onClick={() => {
                setShowModal({ id: row.values.id, type: row.values.type });
              }}
            />
          </span>
        ),
      },
    ],
    [i18n.language]
  );

  const initialState = { hiddenColumns: ['id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  return (
    <table
      {...getTableProps()}
      className={`${styles.verzeichnisTable} table-hover`}
      style={{ minWidth: '400px' }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                onClick={() => {
                  if (column.id !== 'aktionen') {
                    column.toggleSortBy(!column.isSortedDesc);
                  }
                }}
                className={styles.tableHeading}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' \u2193'
                      : ' \u2191'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={styles.tableData}
                    style={
                      cell.getCellProps().key.includes('aktionen')
                        ? { width: '40px' }
                        : undefined
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
