import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';

import styles from '../styles.module.css';

export const Table = ({ data, handleSelect, meData }) => {
  const { t, i18n } = useTranslation('common');

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Mail',
        accessor: 'mail',
      },
      {
        Header: t('overview.action'),
        id: 'aktionen',
        Cell: ({ row }) =>
          row.values.id === meData?.id && row.values.mail !== meData?.mail ? (
            <span
              style={{
                textAlign: 'center',
                display: 'inline-block',
                width: '100%',
              }}
            >
              ✅
            </span>
          ) : row.values.mail === meData?.mail ? (
            <span></span>
          ) : (
            <button
              className="btn btn-default"
              onClick={() => handleSelect(row.values.id)}
            >
              Select
            </button>
          ),
      },
    ],
    [i18n.language, data, meData]
  );

  const initialState = { hiddenColumns: ['id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  return (
    <table
      {...getTableProps()}
      className={`${styles.usersTable} table-hover`}
      style={{ minWidth: '400px' }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                onClick={() => {
                  if (column.id !== 'aktionen') {
                    column.toggleSortBy(!column.isSortedDesc);
                  }
                }}
                className={styles.tableHeading}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' \u2193'
                      : ' \u2191'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={styles.tableData}
                    style={
                      cell.getCellProps().key.includes('aktionen')
                        ? { width: '40px' }
                        : undefined
                    }
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
