import React from 'react';
import { Checkbox } from 'components/Checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Dsfa_Step4 = () => {
  const { control, formState, getValues, setValue, watch } = useFormContext();
  const { t } = useTranslation('common');

  const RISK_CHECKBOXES = t('dsfa.steps.4.inputs', { returnObjects: true });

  const handleLastCheckboxChange = (e) => {
    const lastCheckboxName = RISK_CHECKBOXES[RISK_CHECKBOXES.length - 1].name;

    if (e) {
      setValue(`step4.${lastCheckboxName}`, true);

      RISK_CHECKBOXES.slice(0, -1).forEach((checkbox) => {
        setValue(`step4.${checkbox.name}`, false);
      });
    } else {
      setValue(`step4.${lastCheckboxName}`, false);
    }
  };

  const cancelCheckboxValue = watch('step4.none');

  const validateFields = () => {
    const isAnyFieldFilled = Object.values(getValues()?.step4).some(
      (checkboxValue) => checkboxValue !== 0 && checkboxValue !== null
    );

    return isAnyFieldFilled || t('oneFieldRequired');
  };

  return (
    <div className="card-body">
      <h4>{t('dsfa.steps.4.title')}</h4>
      <div className={styles.steptext}>{t('dsfa.steps.4.description')}</div>
      <div className={styles.dsfaInputsWrapper}>
        {RISK_CHECKBOXES.map((checkbox) => {
          const disabled =
            checkbox.name === 'none' ? false : cancelCheckboxValue;

          return (
            <Controller
              key={checkbox.name}
              control={control}
              name={`step4.${checkbox.name}`}
              rules={{ validate: validateFields }}
              defaultValue={0}
              render={({ field }) => (
                <Checkbox
                  {...checkbox}
                  {...field}
                  onChange={
                    checkbox.name === 'none'
                      ? handleLastCheckboxChange
                      : field.onChange
                  }
                  disabled={disabled}
                  step="step4"
                  name={checkbox.name}
                  checkboxTargetClassName="accent-darkblue"
                />
              )}
            />
          );
        })}
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step4?.auto?.message}
      </span>
    </div>
  );
};

export { Dsfa_Step4 };
