import React from 'react';
import { useNavigate } from 'react-router-dom';

const DatenschutzverletzungStart = ({ next }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/Programme');
  };

  return (
    <div className="card-body morph_variation1 padder">
      <img src="/icon-dsv.svg" style={{ width: '80px'}} />
      <br />
      <h4>Datenschutzverletzung</h4>
      <p className="text-center" style={{ margin: '10px 0px 30px 0px' }}>
      Erfassen einer neuen Datenschutzverletzung starten: <br />
      </p>

      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px'}}>
        <button
          onClick={handleBack}
          type="submit"
          className="btn btn-secondary"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
        >
          Zurück zum Menü
        </button>
        <button
          onClick={next}
          type="submit"
          className="btn btn-primary accent-purple-bg"
          style={{
            marginBottom: '10px',
            marginTop: '16px',
            marginRight: '10px',
            width: '200px',
          }}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export { DatenschutzverletzungStart };
