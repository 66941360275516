import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectDropdown, Loader } from 'components';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const BR_Step3 = () => {
  const [categories, setCategories] = React.useState(null);
  const { t } = useTranslation('common');
  const { control, getValues, formState } = useFormContext();
  const { api } = useApi();

  const fetchData = async () => {
    try {
      const categoriesResponse = await api.get('/br_categories');
      const subcategoriesResponse = await api.get('/br_subcategories');

      const categoriesWithSubcategories = categoriesResponse.data.map(
        (category) => {
          const subcategories = subcategoriesResponse.data.filter(
            (subCategory) => subCategory.CategoryId === category.CategoryId
          );
          return { ...category, Subcategories: subcategories };
        }
      );

      setCategories(categoriesWithSubcategories);
    } catch (err) {
      console.error('Error fetching data: ', err);
    }
  };

  const validateFields = () => {
    const isAnyFieldFilled = Object.values(getValues()?.step3).some(
      (fieldValues) =>
        fieldValues !== null &&
        Object.values(fieldValues)
          .map((fieldValuesArray) => fieldValuesArray?.length > 0)
          .some((value) => value === true)
    );

    return isAnyFieldFilled || 'Mindestens ein Feld ist erforderlich';
  };

  React.useEffect(() => {
    if (api && !categories) {
      fetchData();
    }
  }, [api]);

  return (
    <div className="card-body">
      <h4>{t('br.steps.3.title')}</h4>
      <div className={styles.steptext}>{t('br.steps.3.description')}</div>
      <div className={styles.brInputsWrapper}>
        {!categories && <Loader />}
        {categories &&
          categories.map((category) => (
            <Controller
              key={category.CategoryName}
              name={`step3.${category.CategoryName.replace(/[,\s-]/g, '')}`}
              control={control}
              defaultValue={null}
              rules={{ validate: validateFields }}
              render={({ field }) => (
                <SelectDropdown
                  options={category.Subcategories.map((subcategory) => {
                    return {
                      label: subcategory.SubcategoryName,
                      value: subcategory.SubcategoryId,
                    };
                  })}
                  label={category.CategoryName}
                  isMulti
                  isCreatable
                  value={field.value}
                  onChange={field.onChange}
                  name={field.name}
                  customColor="#45aaf2"
                />
              )}
            />
          ))}
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step3?.ArbeitsundBerufsdaten?.message}
      </span>
    </div>
  );
};

export { BR_Step3 };
