import React from 'react';
import { useTranslation } from 'react-i18next';

const Dsa_Step9 = () => {
  const { t } = useTranslation('common');
  return (
    <div className="card-body">
      <h4>{t('dsa.steps.9.description')}</h4>
      <span>
      {t('dsa.steps.9.text')}
      </span>
    </div>
  );
};

export { Dsa_Step9 };
