import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { useApi } from 'services/axios';

export const predefinedData = [
  { 
    actionId: 1, 
    bereich: "sb.secondtable.verzeichnis.title", 
    description: 'sb.secondtable.verzeichnis.description', 
    completed: 'Nein', 
    overviewlink: "VerzeichnisOverview", 
    createlink: "verzeichnis", 
    uploadlink: "VerzeichnisUpload" 
  },
  { 
    actionId: 2, 
    bereich: "sb.secondtable.br.title", 
    description: 'sb.secondtable.br.description', 
    completed: 'Nein', 
    overviewlink: "BROverview", 
    createlink: "br", 
    uploadlink: "BRUpload" 
  },
  { 
    actionId: 3, 
    bereich: "sb.secondtable.dsfa.title", 
    description: 'sb.secondtable.dsfa.description', 
    completed: 'Nein', 
    overviewlink: "DsfaOverview", 
    createlink: "Datenschutzfolgeabschaetzung", 
    uploadlink: "DSFAUpload" 
  },
  { 
    actionId: 4, 
    bereich: "sb.secondtable.toms.title", 
    description: 'sb.secondtable.toms.description', 
    completed: 'Nein', 
    overviewlink: "TomsOverview", 
    createlink: "toms", 
    uploadlink: "TOMSUpload"  
  },
  { 
    actionId: 5, 
    bereich: "sb.secondtable.dsa.title", 
    description: 'sb.secondtable.dsa.description', 
    completed: 'Nein', 
    overviewlink: "DSAOverview", 
    createlink: "dsa", 
    uploadlink: "DSAUpload" 
  },
  { 
    actionId: 6, 
    bereich: "sb.secondtable.advv.title", 
    description: 'sb.secondtable.advv.description', 
    completed: 'Nein', 
    overviewlink: "advv", 
    createlink: "advv", 
    uploadlink: "advv" 
  },
];

const SecondTable = ({ data = predefinedData }) => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState(data);
  const navigate = useNavigate();
  const { api, isApiReady } = useApi();

  const columns = useMemo(
    () => [
      {
        Header: t('sb.secondtable.common.area'),
        accessor: 'bereich',
        Cell: ({ value }) => t(value),
      },
      {
        Header: t('sb.secondtable.common.description'),
        accessor: 'description',
        Cell: ({ value }) => t(value),
      },
      {
        Header: t('sb.secondtable.common.fulfilled'),
        accessor: 'completed',
        Cell: ({ value }) => (
          <span style={{ color: value === 'Ja' ? 'green' : 'red' }}>
            {value === 'Ja' ? '✓' : '✗'}
          </span>
        ),
      },
      {
        Header: t('sb.secondtable.common.actions'),
        id: 'actions',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', gap: '10px' }}>
            {row.values.completed === 'Nein' ? (
              <>
                <button 
                  className="btn btn-primary accent-dark-grey-bg"
                  onClick={() => navigate(`/${row.original.createlink}`)}
                >
                  {t('sb.secondtable.common.createNew')}
                </button>
                <button 
                  className="btn btn-secondary"
                  onClick={() => navigate(`/${row.original.uploadlink}`)}
                >
                  {t('sb.secondtable.common.upload')}
                </button>
              </>
            ) : (
              <button 
                className="btn btn-primary accent-dark-grey-bg"
                onClick={() => navigate(`/${row.original.overviewlink}`)}
              >
                {t('sb.secondtable.common.overview')}
              </button>
            )}
          </div>
        ),
      },
    ],
    [navigate, t]
  );

  const fetchData = async () => {
    if (!isApiReady) return;

    const endpoints = [
      { name: 'verzeichnis', overview: '/verzeichnis_alldata', uploads: '/verzeichnis-uploads', dataKey: 'step1' },
      { name: 'br', overview: '/br_alldata', uploads: '/br-uploads', dataKey: 'entries' },
      { name: 'dsfa', overview: '/dsfa_alldata', uploads: '/dsfa-uploads', dataKey: 'entries' },
      { name: 'toms', overview: '/toms_alldata', uploads: '/toms-uploads', dataKey: 'entries' },
      { name: 'dsa', overview: '/dsa_alldata', uploads: '/dsa-uploads', dataKey: null },
      { name: 'advv', overview: null, uploads: '/advv-uploads', dataKey: null },
    ];

    try {
      const results = await Promise.all(
        endpoints.map(async ({ name, overview, uploads, dataKey }) => {
          const [overviewResponse, uploadsResponse] = await Promise.all([
            overview ? api.get(overview) : Promise.resolve({ data: null }),
            api.get(uploads)
          ]);

          return {
            name,
            overviewData: dataKey ? overviewResponse.data[dataKey] : overviewResponse.data,
            uploadsData: uploadsResponse.data
          };
        })
      );

      const updatedTableData = tableData.map(item => {
        const result = results.find(r => r.name === item.createlink);
        if (result) {
          const hasData = (result.overviewData && result.overviewData.length > 0) || 
                          (result.uploadsData && result.uploadsData.length > 0);
          return { ...item, completed: hasData ? 'Ja' : 'Nein' };
        }
        return item;
      });

      setTableData(updatedTableData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    console.log(isApiReady)
    if (isApiReady === true) {

      fetchData();
    }
  }, [isApiReady, i18n.language]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  return (
    <table {...getTableProps()} className={`${styles.dsaTable} table-hover`}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} className={styles.tableHeading}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} className={styles.tableData}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { SecondTable };