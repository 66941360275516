import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Toms_Step1,
  Toms_Step2,
  Toms_Step3,
  Toms_Step4,
  Toms_Step5,
  Toms_Step6,
  Toms_Step7,
  Toms_Step8,
  Toms_Step9,
  Toms_Step10,
  Toms_Step11,
  Toms_Step12,
} from '../steps';

import styles from './styles.module.css';

const TomsFormular = ({ next, back, activeStep }) => {
  const { id } = useParams();
  const { formState, getValues } = useFormContext();
  const { t } = useTranslation('common');

  const handleSaveAndExit = () => {
    if (id && !Object.keys(formState.errors)?.length) {
      sessionStorage.setItem(`saveTomsEntry-${id}`, 'true');
    }
  };

  console.log(getValues());

  return (
    <div className="morph_variation1 padder" style={{ overflow: 'visible' }}>
      <div className={styles.formularHeading}>
        <h3 style={{ marginBottom: '20px' }}>TOMS</h3>
        {id && (
          <button
            className="btn btn-primary accent-yellow-bg"
            type="submit"
            onClick={handleSaveAndExit}
            form="toms-form"
          >
            {t('saveAndExit')}
          </button>
        )}
      </div>
      {activeStep < 13 && (
        <div
          className="progress"
          style={{ height: '30px', marginTop: '20px', marginBottom: '20px' }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${(activeStep / 12) * 100}%`,
              backgroundColor: '#f7b731',
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {activeStep} / 12
          </div>
        </div>
      )}
      {activeStep === 1 && <Toms_Step1 />}
      {activeStep === 2 && <Toms_Step2 />}
      {activeStep === 3 && <Toms_Step3 />}
      {activeStep === 4 && <Toms_Step4 />}
      {activeStep === 5 && <Toms_Step5 />}
      {activeStep === 6 && <Toms_Step6 />}
      {activeStep === 7 && <Toms_Step7 />}
      {activeStep === 8 && <Toms_Step8 />}
      {activeStep === 9 && <Toms_Step9 />}
      {activeStep === 10 && <Toms_Step10 />}
      {activeStep === 11 && <Toms_Step11 />}
      {activeStep === 12 && <Toms_Step12 />}
      {activeStep >= 13 && <h5>{t('verzeichnis.processing')}</h5>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '60px',
        }}
        className={styles.navBtns}
      >
        <button
          onClick={back}
          type="button"
          className="btn btn-secondary"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
        >
          {t('back')}
        </button>
        <button
          onClick={next}
          type={activeStep === 13 ? 'submit' : 'button'}
          className="btn btn-primary accent-yellow-bg"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
          form="toms-form"
        >
          {activeStep === 12 ? t('saveAndExit') : t('saveAndContinue')}
        </button>
      </div>
    </div>
  );
};

export { TomsFormular };
