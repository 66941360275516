import { getMsalRedirectUri } from 'services/getMsalRedirectUri';

export const msalConfig = {
  auth: {
    clientId: '50dc3e8e-4773-4c87-983e-384cd9bf4973',
    authority:
      'https://datenschutzkonformstg.b2clogin.com/datenschutzkonformstg.onmicrosoft.com/b2c_1_email_sign_in',
    redirectUri: getMsalRedirectUri(),
    knownAuthorities: ['datenschutzkonformstg.b2clogin.com'],
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const azureStorageConfig = {
  SAS_TOKEN:
    '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-09-27T20:11:34Z&st=2023-09-26T12:11:34Z&spr=https,http&sig=VEygdDjYsQypM3fyaXvwAPkdz1i9gXDoHjvsa00v4xE%3D',
  ACCOUNT: 'datenschutzkonform',
  CONTAINER: 'dsfileshare',
};
