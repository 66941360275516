import React from 'react';
import { Input, MobileNavbar, Navbar } from 'components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { BlobServiceClient } from '@azure/storage-blob';
import { azureStorageConfig } from 'msalConfig';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getStorageEnv } from 'services/getStorageEnv';
import { toast } from 'react-toastify';

import styles from './styles.module.css';

const VerzeichnisUpload = () => {
  const [meData, setMeData] = React.useState(null);
  const methods = useForm({ mode: 'onChange' });
  const { api } = useApi();
  const validation = useValidation();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const fetchData = async () => {
    try {
      const response = await api.get('/me');

      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    if (api && !meData) {
      fetchData();
    }
  }, [api]);

  const submitEntry = async (comment, fileName) => {
    try {
      await api.post('/verzeichnis-uploads', {
        comment,
        fileName,
      });
    } catch (err) {
      console.error('Error submitting form.');
    }
  };

  const handleUpload = async (formData) => {
    const { selectedFile, comment } = formData;

    if (selectedFile && meData) {
      const blobServiceClient = new BlobServiceClient(
        `https://${azureStorageConfig.ACCOUNT}.blob.core.windows.net/?${azureStorageConfig.SAS_TOKEN}`
      );

      const containerName = `user${meData.id}-${getStorageEnv()}`;
      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      if (!(await containerClient.exists())) {
        await containerClient.create();
      }

      const blobClient = containerClient.getBlockBlobClient(
        `Verzeichnis/${selectedFile.name}`
      );
      await blobClient.uploadData(selectedFile);

      submitEntry(comment, selectedFile.name)
        .then(() => {
          toast.success(t('entryCreated'));
          setTimeout(() => {
            navigate('/VerzeichnisOverview');
          }, 1000);
        })
        .catch(() => {
          toast.error(toast.error('smthWrong'));
        });
    }
  };

  const onSubmit = (formData) => {
    console.log('formData: ', formData);
    handleUpload({ selectedFile: formData.file[0], comment: formData.comment });
  };

  return (
    <div className="wrapper">
      <Navbar />
      <MobileNavbar />
      <div id="content" style={{ padding: '50px' }}>
        <div
          className="container"
          style={{ width: '100%', marginTop: '100px' }}
        >
          <div
            className="row"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: 0,
            }}
          >
            <div className="card">
              <FormProvider {...methods}>
                <form
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  onSubmit={methods.handleSubmit(onSubmit)}
                  id="upload-form"
                >
                  <div
                    className="card-body morph_variation1 padder"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '12px',
                    }}
                  >
                    <h3 style={{ marginBottom: '50px' }}>Dokument hochladen</h3>
                    <Controller
                      control={methods.control}
                      name="comment"
                      rules={{ ...validation.required }}
                      defaultValue=""
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            label="Bezeichnung"
                            id="comment"
                            error={methods.formState.errors?.comment}
                          />
                        );
                      }}
                    />

                    <input
                      type="file"
                      className={styles.chooseFile}
                      {...methods.register('file')}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        marginBottom: '10px',
                        marginTop: '16px',
                        width: '200px',
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { VerzeichnisUpload };
