import React from 'react';
import { Input } from 'components/Input';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';

import styles from './styles.module.css';

const DSV_Step2 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const INPUTS = t('dsv.steps.2.inputs', { returnObjects: true });

  return (
    <div className="card-body">
      <div className={styles.dsvInputsWrapper}>
        {INPUTS.map((input) => (
          <Controller
            key={input.id}
            control={control}
            name={`step2.${input.name}`}
            rules={{ ...validation.required }}
            defaultValue=""
            render={({ field }) => {
              return (
                <Input
                  {...input}
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={formState.errors?.step2?.[input.name]}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { DSV_Step2 };
