import React, { useRef, useState } from 'react';
import Logo_Red_New from '../assets/images/Logo_Red_New.png';
import { useMsal } from '@azure/msal-react';

const LoginPage = () => {
  const { instance } = useMsal();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');

  const initializeAADSignIn = () => {
    instance.loginPopup();
  };

  return (
    <div className="login-container">
      <div className="row justify-content-center h-100">
        <div className="offset col-md-3"></div>
        <div className="col-md-6">
          <div className="card morph_variation1 padder">
            <div className="card-body">
              <img className="logo_login" src={Logo_Red_New} alt="Logo" />
              <div
                style={{ color: 'red', fontWeight: 'bold' }}
                ref={errRef}
                className={errMsg ? 'errMsg' : 'offscreen'}
              >
                {errMsg}
              </div>
              <div className="button-wrapper">
                <button
                  onClick={initializeAADSignIn}
                  type="submit"
                  className="btn btn-primary btn-block"
                >
                  Login
                </button>
              </div>
              <div className="button-wrapper">
                <a
                  href="https://www.datenschutzkonform.ch"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary btn-block"
                >
                  Registrieren
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginPage };
