import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'components';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const DSV_Step4 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const INPUTS = t('dsv.steps.4.inputs', { returnObjects: true });

  return (
    <div className="card-body">
      <div className={styles.dsvInputsWrapper}>
        {INPUTS &&
          INPUTS.map((input) => (
            <Controller
              key={input.id}
              control={control}
              name={`step4.${input.name}`}
              rules={{ ...validation.required }}
              defaultValue=""
              render={({ field }) => {
                return (
                  <Input
                    {...input}
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={formState.errors?.step4?.[input.name]}
                  />
                );
              }}
            />
          ))}
      </div>
    </div>
  );
};

export { DSV_Step4 };
