import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Loader } from 'components/Loader';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Verzeichnis_Step5 = () => {
  const [legalBasis, setLegalBasis] = React.useState(null);
  const { control, formState } = useFormContext();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const fetchData = async () => {
    const legalbasisResponse = await api.get('/verzeichnis_legalbasis');

    setLegalBasis(legalbasisResponse.data);
  };

  React.useEffect(() => {
    if (api && !legalBasis) {
      fetchData();
    }
  }, [api]);

  return (
    <div className="card-body">
      <h4>Rechtsgrundlage</h4>
      <div className={styles.steptext}>
        {t('verzeichnis.steps.5.description')}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        {!legalBasis && <Loader />}
        {legalBasis && (
          <Controller
            name="step5.rechtsgrundlage"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label={t('verzeichnis.steps.5.inputs.legalBasis')}
                options={legalBasis.map((basis) => {
                  return {
                    label: basis.LegalbasisName,
                    value: basis.LegalbasisId,
                  };
                })}
                name={field.name}
                isMulti
                isCreatable
                value={field.value}
                onChange={field.onChange}
                error={formState.errors.step5?.rechtsgrundlage}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Verzeichnis_Step5 };
