import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Dsfa_Step1,
  Dsfa_Step2,
  Dsfa_Step3,
  Dsfa_Step4,
  Dsfa_Step5,
  Dsfa_Step6,
  Dsfa_Step7,
  Dsfa_Step8,
} from '../steps';

import styles from './styles.module.css';

const DsfaFormular = ({ next, back, activeStep }) => {
  const { id } = useParams();
  const { formState } = useFormContext();
  const { t } = useTranslation('common');

  const handleSaveAndExit = () => {
    if (id && !Object.keys(formState.errors)?.length) {
      sessionStorage.setItem(`saveDSFAEntry-${id}`, 'true');
    }
  };

  return (
    <div className="morph_variation1 padder" style={{ overflow: 'visible' }}>
      <div className={styles.formularHeading}>
        <h3 style={{ marginBottom: '20px' }}>{t('dsfa.title')}</h3>
        {id && (
          <button
            className="btn btn-primary accent-darkblue-bg"
            type="submit"
            onClick={handleSaveAndExit}
            form="dsfa-form"
          >
            {t('saveAndExit')}
          </button>
        )}
      </div>
      {activeStep < 9 && (
        <div
          className="progress"
          style={{ height: '30px', marginTop: '20px', marginBottom: '20px' }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${(activeStep / 8) * 100}%`,
              backgroundColor: '#4b7bec',
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {activeStep} / 8
          </div>
        </div>
      )}
      {activeStep === 1 && <Dsfa_Step1 />}
      {activeStep === 2 && <Dsfa_Step2 />}
      {activeStep === 3 && <Dsfa_Step3 />}
      {activeStep === 4 && <Dsfa_Step4 />}
      {activeStep === 5 && <Dsfa_Step5 />}
      {activeStep === 6 && <Dsfa_Step6 />}
      {activeStep === 7 && <Dsfa_Step7 />}
      {activeStep === 8 && <Dsfa_Step8 />}
      {activeStep >= 9 && <h5>Processing...</h5>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '60px',
        }}
        className={styles.navBtns}
      >
        <button
          onClick={back}
          type="button"
          className="btn btn-secondary"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
        >
          {t('back')}
        </button>
        <button
          onClick={next}
          type={activeStep === 9 ? 'submit' : 'button'}
          className="btn btn-primary accent-darkblue-bg"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
          form="dsfa-form"
        >
          {activeStep === 8 ? t('saveAndExit') : t('saveAndContinue')}
        </button>
      </div>
    </div>
  );
};

export { DsfaFormular };
