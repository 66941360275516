import React from 'react';
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import Logo_Red_New from '../../assets/images/Logo_Red_New.png';

const OverviewPdf = ({ data, title }) => {
  //const { companyInfo } = data;

  const pdfStyles = StyleSheet.create({
    title: { fontSize: 16, textAlign: 'center', padding: 20 },
    documenttitle: { fontSize: 16, textAlign: 'center', padding: 10 },
    documentsubtitle: { fontSize: 10, textAlign: 'center', padding: 2 },
    documentcompanydata: { fontSize: 12, textAlign: 'center', padding: 2 },
    section: { display: 'flex', flexDirection: 'column' },
    outerPadding: { padding: 20 },
    sectionTitle: { fontSize: 12 },
    rowView: { display: 'flex', flexDirection: 'row', padding: 4, fontSize: 10, alignItems: 'left' },
    rowOdd: { backgroundColor: 'rgb(232, 232, 232)' },
    tableHeaders: { backgroundColor: '#d33c2e', color: '#fff', fontWeight: '700', padding: 4 },
    tableRow: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 4 },
    tableCell: { flexGrow: 1, flexBasis: 0, padding: 4 },
    mainPointTitle: { fontSize: 12 },
    mainFieldTitle: { fontSize: 12 },
    mainPointValue: { width: '70%' },
    categoryPointList: { display: 'flex', flexDirection: 'column', listStyle: 'square' },
    points: { paddingLeft: 30 },
    logo: { textAlign: 'right', width: '20%', paddingTop: 0 },
    logoContainer: {
      display: 'flex',
      flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-start'
    },
    pageContainer: { size: 'A4 landscape', padding: '20mm' },
  });
  
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={pdfStyles.pageContainer}>
        <View style={pdfStyles.logoContainer}>
          <Image style={pdfStyles.logo} src={Logo_Red_New} />
        </View>
        {data && (
          <>
            <View style={pdfStyles.outerPadding}>
              <View style={pdfStyles.documenttitle}>
                <Text>{title}</Text>
              </View>

              {/* <View style={pdfStyles.documentcompanydata}>
              <Text>{companyInfo.CompanyName}</Text>
              <Text>
                {companyInfo.CompanyStreet} {companyInfo.CompanyStreetNr}
              </Text>
              <Text>
                {companyInfo.CompanyZIP} {companyInfo.CompanyCity}
              </Text>
            </View> */}
            </View>
            <View style={{ ...pdfStyles.rowView, ...pdfStyles.tableHeaders }}>
              {data['columns']?.map((column, idx) => (
                <Text
                  key={idx}
                  style={{
                    width: `${100 / data['columns'].length}%`,
                  }}
                >
                  {column}
                </Text>
              ))}
            </View>
            {data['data']?.map((rowData, idx) => {
              const isOdd = idx % 2 !== 0;
              const styles = isOdd
                ? { ...pdfStyles.rowView, ...pdfStyles.rowOdd }
                : pdfStyles.rowView;

              return (
                <React.Fragment key={idx}>
                  <View style={styles}>
                    {data['columns']?.map((column, index) => (
                      <Text
                        style={{
                          width: `${100 / data['columns']?.length}%`,
                        }}
                        key={index}
                      >
                        {rowData[column]}
                      </Text>
                    ))}
                  </View>
                </React.Fragment>
              );
            })}
          </>
        )}
      </Page>
    </Document>
  );
};

export { OverviewPdf };
