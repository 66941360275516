import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'helpers/validation';

import styles from './styles.module.css';

const DSV_Step7 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const INPUTS = t('dsv.steps.7.inputs', { returnObjects: true });

  return (
    <div className="card-body">
      <div className={styles.dsvInputsWrapper}>
        {INPUTS.map((input) => (
          <Controller
            key={input.id}
            control={control}
            name={`step7.${input.name}`}
            rules={{ ...validation.required }}
            defaultValue=""
            render={({ field }) => {
              return (
                <Input
                  {...input}
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={formState.errors?.step7?.[input.name]}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { DSV_Step7 };
