import styles from './styles.module.css';

const FormField = ({ error, children, isSlider }) => {
  return (
    <div className={styles.formFieldWrapper}>
      {children}
      {error && (
        <span
          isError={!!error}
          className={`${styles.errorMsg} ${isSlider ? styles.sliderError : ''}`}
        >
          {error?.message || 'Dieses Feld muss ausgefüllt werden'}
        </span>
      )}
    </div>
  );
};

export { FormField };
