import React from 'react';
import { Input, Loader } from 'components';
import { useValidation } from 'helpers/validation';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApi } from 'services/axios';
import mobiliar_logo from "../../assets/images/Logo_Mobiliar_Alt.png" ;


const CyberverRequest = () => {
  const navigate = useNavigate();
  const [isFetched, setIsFetched] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(null);
  const [isFulfilled, setIsFulfilled] = React.useState(null);
  const { t } = useTranslation('common');
  const { api } = useApi();
  const validation = useValidation();
  const methods = useForm({ mode: 'onChange' });

  const sendRequest = async (formData) => {
    try {
      await api.post('/user-request', {
        subtype: 'cyberver',
        comment: formData.comment,
      });
      toast.success(t('alt1Module.receivedToast'));

      fetch();
    } catch (err) {
      console.error('Error submitting request: ', err);
    }
  };

  const onSubmit = (data) => {
    sendRequest(data);
  };

  const fetch = async () => {
    const response = await api.get('/user-request/cyberver');

    if (response.data[0]?.Status === 'Active') {
      setIsSubmitted(true);
      return;
    }

    if (response.data[0]?.Status === 'Closed') {
      setIsFulfilled(true);
      return;
    }

    setIsSubmitted(false);
    setIsFulfilled(false);
  };

  React.useEffect(() => {
    if (isFetched || !api) return;

    fetch();
    setIsFetched(true);
  }, [api, isFetched]);

  if (isSubmitted === null && isFulfilled === null) {
    return <Loader />;
  }

  return (
    <div className="card-body morph_variation1 padder">
       <img
        src={mobiliar_logo}
        alt="Mobiliar Logo"
        style={{ margin: '20px 0px 40px 0px', height: '80px'}}
      />

      <h4>{t('alt1Module.cyberver')}</h4>

      <p className="text-center" style={{ margin: '20px 0px 40px 0px' }}>
        {isSubmitted || isFulfilled ? (
          <>
            {isSubmitted && t('alt1Module.receivedMsg')}
            {isFulfilled && t('alt1Module.fulfilledMsg')}
          </>
        ) : (
          <>
            {t('alt1Module.title')}
            <br />
            <br />
            {t('alt1Module.subtitle')}
          </>
        )}
      </p>

      {!isSubmitted && !isFulfilled && (
        <FormProvider {...methods}>
          <form
            id="cyberver-request"
            onSubmit={methods.handleSubmit(onSubmit)}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Controller
              control={methods.control}
              name="comment"
              rules={{ ...validation.required }}
              defaultValue=""
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    label="Kommentar"
                    id="support-purpose"
                    error={methods.formState.errors?.comment}
                  />
                );
              }}
            />
          </form>
        </FormProvider>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          justifyContent:
            !isSubmitted && !isFulfilled ? 'space-between' : 'center',
        }}
      >
        <button
          type="button"
          className="btn btn-secondary"
          style={{
            marginBottom: '10px',
            marginTop: '16px',
            width: !isSubmitted && !isFulfilled ? 'calc(50% - 2.5px)' : '200px',
          }}
          onClick={() => {
            navigate('/Programme');
          }}
        >
          {t('backToMenu')}
        </button>
        {!isSubmitted && !isFulfilled && (
          <button
            form="cyberver-request"
            type="submit"
            className="btn btn-primary"
            style={{
              marginBottom: '10px',
              marginTop: '16px',
              width: 'calc(50% - 2.5px)',
            }}
          >
            {t('alt1Module.requestBtn')}
          </button>
        )}
      </div>
    </div>
  );
};

export { CyberverRequest };
