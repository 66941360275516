export const PURPOSES = [
  'Angebot und Weiterentwicklung unserer Angebote, Dienstleistungen und Websites, Apps und weiteren Plattformen, auf welchen wir präsent sind',
  'Kommunikation mit Dritten und Bearbeitung derer Anfragen (z.B. Bewerbungen, Medienanfragen)',
  'Prüfung und Optimierung von Verfahren zur Bedarfsanalyse zwecks direkter Kundenansprache sowie Erhebung von Personendaten aus öffentlich zugänglichen Quellen zwecks Kundenakquisition',
  `Werbung und Marketing (einschliesslich Durchführung von Anlässen), soweit Sie der Nutzung
Ihrer Daten nicht widersprochen haben (wenn wir Ihnen als bestehender Kunde von uns Werbung
zukommen lassen, können Sie dem jederzeit widersprechen, wir setzen Sie dann auf eine
Sperrliste gegen weitere Werbesendungen)`,
  'Markt- und Meinungsforschung, Medienbeobachtung',
  `Geltendmachung rechtlicher Ansprüche und Verteidigung in Zusammenhang mit rechtlichen
Streitigkeiten und behördlichen Verfahren`,
  `Verhinderung und Aufklärung von Straftaten und sonstigem Fehlverhalten (z.B. Durchführung
interner Untersuchungen, Datenanalysen zur Betrugsbekämpfung)`,
  `Gewährleistungen unseres Betriebs, insbesondere der IT, unserer Websites, Apps und weiteren
Plattformen`,
  `Durchführung der Arbeitsverhältnisse mit allen Rechten und Pflichten, die aus Arbeitsverträgen
entstehen`,
  `Videoüberwachungen zur Wahrung des Hausrechts und sonstige Massnahmen zur IT-, Gebäude-
und Anlagesicherheit und Schutz unserer Mitarbeiter und weiteren Personen und uns gehörenden
oder anvertrauten Werte (wie z.B. Zutrittskontrollen, Besucherlisten, Netzwerk- und Mailscanner,
Telefonaufzeichnungen)`,
];

export const DATA_ABROAD = [
  'Dienstleister von uns (wie z.B. Banken, Versicherungen), einschliesslich Auftragsbearbeitern (wie z.B. IT-Provider)',
  'Händler, Lieferanten, Subunternehmer und sonstige Geschäftspartner',
  'Kunden',
  'in- und ausländische Behörden, Amtsstellen oder Gerichten',
  'Medien',
  'Öffentlichkeit, einschliesslich Besuchern von Websites und sozialer Medien',
  'Mitbewerber, Branchenorganisationen, Verbände, Organisationen und weitere Gremien',
  'anderen Parteien in möglichen oder tatsächlichen Rechtsverfahren',
];
