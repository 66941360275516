import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox } from 'components/Checkbox';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'helpers/validation';

import styles from './styles.module.css';
import { Input } from 'components';

export const getEditingLabel = (checkboxes, name) => {
  const res = checkboxes.find((checkbox) => checkbox.name === name);

  return res.label;
};

const BR_Step1 = () => {
  const { control, formState, getValues } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const EDITING_CHECKBOXES = t('br.steps.1.inputs', { returnObjects: true });

  const validateFields = () => {
    const isAnyFieldFilled = Object.values(getValues()?.step1).some(
      (checkboxValue) => checkboxValue !== 0 && checkboxValue !== null
    );

    return isAnyFieldFilled || 'Mindestens ein Feld ist erforderlich';
  };

  return (
    <div className="card-body">
      <h4>{t('br.steps.1.title')}</h4>
      {/* <div className={styles.steptext}>{t('br.steps.1.description1')}</div> */}
      <div className={styles.brInputsWrapper} style={{ marginTop: '40px' }}>
        <Controller
          control={control}
          name="step1.description"
          rules={{ ...validation.required }}
          defaultValue=""
          render={({ field }) => {
            return (
              <Input
                {...field}
                label={t('br.steps.1.label')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={formState.errors?.step1?.description}
              />
            );
          }}
        />
        <div style={{ marginTop: '40px', marginBottom: '20px' }}>
          {t('br.steps.1.description2')}
        </div>

        {EDITING_CHECKBOXES.map((checkbox) => {
          return (
            <Controller
              key={checkbox.name}
              control={control}
              name={`step1.${checkbox.name}`}
              rules={{ validate: validateFields }}
              defaultValue={0}
              render={({ field }) => (
                <Checkbox
                  {...checkbox}
                  {...field}
                  checkboxTargetClassName="accent-blue"
                  onChange={field.onChange}
                />
              )}
            />
          );
        })}
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step1?.besschuetz?.message}
      </span>
    </div>
  );
};

export { BR_Step1 };
