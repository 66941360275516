import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BearbeitungsreglementStart = ({ next }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  return (
    <div className="card-body morph_variation1 padder">
      <img src="/icon-br.svg" style={{ width: '80px'}} />
      <br />
      <h4>{t('br.start.title')}</h4>
      <p className="text-center" style={{ margin: '10px 0px 30px 0px' }}>
        {t('br.start.description')}
        <br />
        {/* {t('br.start.subtitle')} */}
      </p>

      <div
        style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}
      >
        <button
          type="button"
          className="btn btn-secondary"
          style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
          onClick={() => {
            navigate('/Programme');
          }}
        >
          {t('backToMenu')}
        </button>
        <button
          onClick={next}
          type="submit"
          className="btn btn-primary accent-blue-bg"
          style={{
            marginBottom: '10px',
            marginTop: '16px',
            marginRight: '10px',
            width: '200px',
          }}
        >
          {t('startBtn')}
        </button>
      </div>
    </div>
  );
};

export { BearbeitungsreglementStart };
