import React from 'react';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';
import { SelectDropdown, Loader } from 'components';
import { useValidation } from 'helpers/validation';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './styles.module.css';

const Dsfa_Step3 = () => {
  const [riskTypes, setRiskTypes] = React.useState(null);
  const { control, formState } = useFormContext();
  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const fetchData = async () => {
    try {
      const riskTypesResponse = await api.get('/dsfa_risktypes');

      setRiskTypes(riskTypesResponse.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  React.useEffect(() => {
    if (isApiReady && !riskTypes) {
      fetchData();
    }
  }, [isApiReady]);

  return (
    <div className="card-body">
      <div className={styles.steptext}>{t('dsfa.steps.3.description')}</div>
      <div className={styles.dsfaInputsWrapper}>
        {!riskTypes && <Loader />}
        {riskTypes && (
          <Controller
            control={control}
            name="step3.risktypes"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                options={riskTypes.map((type) => ({
                  label: type.RisktypeName,
                  value: type.RisktypeId,
                }))}
                isMulti
                label={t('dsfa.steps.3.inputs.riskAnalysis')}
                isCreatable
                onChange={field.onChange}
                value={field.value}
                error={formState.errors?.step3?.risktypes}
                name={field.name}
                customColor="#4b7bec"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Dsfa_Step3 };
