import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const RadioGroup = ({ label, description, options, field, error }) => (
  <div className={styles.radioGroup}>
    <label>{label}</label>
    {description}
    <p></p>
    <div>
      {options.map((option) => (
        <div key={option.value} className={styles.radioOption}>
          <label>
            <input
              type="radio"
              value={option.value}
              checked={field.value === option.value}
              onChange={() => field.onChange(option.value)}
              onBlur={field.onBlur}
            />
            {option.label}
          </label>
        </div>
      ))}
    </div>
    {error && <span className={styles.error}>{error.message}</span>}
  </div>
);

const Standortbestimmung_Step3 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const INPUTS = [
    {
      label: t('sb.steps.3.inputs.personendaten'),
      description: t('sb.steps.3.inputs.personendaten_description'),
      name: 'personaldata',
      options: [
        { value: 1, label: t('sb.steps.3.inputs.personendaten1') },
        { value: 2, label: t('sb.steps.3.inputs.personendaten2') },
        //Text für value 1 = "Es werden keine Personendaten bearbeitet"
        //Text für value 2 = "Es werden Personendaten bearbeitet wodurch gesetzliche Pflichten bezüglich Datenschutz wahrgenommen werden müssen"
        // value 1 = keine Dokumente erforderlich
        // value 2 = keine Folgen
      ],
    },
    {
      label: t('sb.steps.3.inputs.besonderepersonendaten'),
      description: t('sb.steps.3.inputs.besonderepersonendaten_description'),
      name: 'protectivepersonaldata',
      options: [
        { value: 1, label: t('sb.steps.3.inputs.besonderepersonendaten1') },
        { value: 2, label: t('sb.steps.3.inputs.besonderepersonendaten2') },
        // Text für Value 1 = "Das Unternehmen bearbeitet besonders schützenswerte Personendaten, wodurch zusätzliche Anforderungen an den Datenschutz entstehen."
        // Text für Value 2 = "Das Unternehmen bearbeit keine besonders schützenswerten Personendaten."
        // value 1 = keine Folgen
        // value 2 = keine Folgen
      ],
    },
    {
      label: t('sb.steps.3.inputs.profiling'),
      description: t('sb.steps.3.inputs.profiling_description'),
      name: 'profiling',
      options: [
        { value: 1, label: t('sb.steps.3.inputs.profiling1') },
        { value: 2, label: t('sb.steps.3.inputs.profiling2') },
        // Text für Value 1 = "Das Unternehmen führt Profilings durch, wodurch zusätzliche Anforderungen an den Datenschutz entstehen."
        // Text für Value 2 = "Das Unternehmen führt kein Profiling durch."

        // value 1 = keine Folgen
        // value 2 = keine Folgen
      ],
    },
  ];

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        <h4>{t('sb.steps.3.title')}</h4>
      </div>
      <div className={styles.sbInputsWrapper}>
        {INPUTS.map((input) => (
          <Controller
            key={input.name}
            control={control}
            name={`step3.${input.name.toLowerCase()}`}
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <RadioGroup
                label={input.label}
                description={input.description}
                options={input.options}
                field={field}
                error={formState.errors?.step3?.[input.name.toLowerCase()]}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export { Standortbestimmung_Step3 };
