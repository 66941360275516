import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { VerzeichnisStart } from './VerzeichnisStart';
import { VerzeichnisFormular } from './VerzeichnisFormular';
import { toast } from 'react-toastify';
import { StepsNavigation } from 'components/StepsNavigation';
import { MobileNavbar, Navbar } from 'components';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';
import TipBar from 'components/TipBar';
import styles from './styles.module.css';

const Verzeichnis = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = React.useState(id ? 1 : 0);
  const [entry, setEntry] = React.useState(null);
  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const marks = {
    1: `${t('step')} 1`,
    2: `${t('step')} 2`,
    3: `${t('step')} 3`,
    4: `${t('step')} 4`,
    5: `${t('step')} 5`,
    6: `${t('step')} 6`,
    7: `${t('step')} 7`,
  };

  const handleNext = async () => {
    await methods.trigger();

    if (Object.keys(methods.formState.errors)?.length > 0) {
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
    methods.clearErrors();
  };

  const handleBack = async () => {
    if (activeStep === 1) {
      navigate('/Programme');
    }

    setActiveStep((prevStep) => prevStep - 1);
  };

  const methods = useForm({ mode: 'onChange' });

  const submitForm = async (formData) => {
    try {
      const postResponse = await api.post('/verzeichnis_alldata', formData);

      console.log('post response: ', postResponse);
    } catch (err) {
      console.error('Error submitting form: ', err);
    }
  };

  const submitEditForm = async (formData) => {
    try {
      const postResponse = await api.post(`/verzeichnis_edit/${id}`, formData);

      console.log('post response: ', postResponse);
    } catch (err) {
      console.error('Error submitting form: ', err);
    }
  };

  const fetchEntry = async () => {
    try {
      const entryResponse = await api.get(`/verzeichnis_alldata/${id}`);
      console.log('Abgerufene Eintragsdaten:', entryResponse.data); // Log für abgerufene Daten
      setEntry(entryResponse.data);
    } catch (err) {
      console.error('Error fetching entry: ', err);
    }
  };

  const onSubmit = (data) => {
    if (
      id &&
      (activeStep === 8 || sessionStorage.getItem(`saveVerzeichnisEntry-${id}`))
    ) {
      submitEditForm(data);

      sessionStorage.removeItem(`saveVerzeichnisEntry-${id}`);
      return;
    }

    submitForm(data);
  };

  React.useEffect(() => {
    if (id && !entry && isApiReady) {
      fetchEntry();
    }
  }, [id, isApiReady]);

  React.useEffect(() => {
    if (entry) {
      methods.reset(entry);
    }
  }, [entry]);

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      if (id) {
        toast.success('Eintrag erfolgreich bearbeitet');
      } else {
        toast.success('Eintrag erfolgreich erstellt');
      }

      setTimeout(() => {
        navigate('/VerzeichnisOverview');
      }, 500);
    }
  }, [methods.formState]);

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar />
        <MobileNavbar />
        <div id="content" style={{ flexGrow: 1, padding: '20px' }}>
          <div className="container" style={{ width: '100%', padding: '0' }}>
            <div className="row topmargin100" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div className="card col-md-8">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form} id="verzeichnis-form">
                    {activeStep === 0 && <VerzeichnisStart next={handleNext} />}
                    {activeStep > 0 && (
                      <VerzeichnisFormular
                        activeStep={activeStep}
                        next={handleNext}
                        back={handleBack}
                      />
                    )}
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
          {id && (
            <StepsNavigation
              step={activeStep}
              setStep={setActiveStep}
              errors={methods.formState.errors}
              marks={marks}
              min={1}
              max={7}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { Verzeichnis };
