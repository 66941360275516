export const getApiUrl = () => {
  const environment = process.env.NODE_ENV;
  const domain = window.location.hostname.split('.')[0];

  switch (environment) {
    case 'development':
      return 'http://localhost:5001/api';
    case 'production':
      if (domain === 'maintain') {
        return 'https://datenschutzkonform-stg-backend.happycliff-0c136115.switzerlandnorth.azurecontainerapps.io/api';
      }

      if (domain === 'tool') {
        return 'https://datenschutzkonform-prd-backend.blackground-1e429a6b.switzerlandnorth.azurecontainerapps.io/api';
      }
    default:
      return '';
  }
};
