import React from 'react';
import { Input, Navbar, Textarea } from 'components';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { toast } from 'react-toastify';
import { useApi } from 'services/axios';
import { MobileNavbar } from 'components/MobileNavbar';
import TipBar from 'components/TipBar';


const Support = () => {
  const { t } = useTranslation('common');
  const validation = useValidation();
  const methods = useForm({ mode: 'onChange' });
  const { api } = useApi();

  const submitForm = async (formData) => {
    try {
      await api.post('/support-email', formData);
    } catch (err) {
      console.error('Error submitting form: ', err);
    }
  };

  const onSubmit = (data) => {
    submitForm(data);
  };

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      toast.success('Eintrag erfolgreich erstellt');
      methods.reset({ purpose: '', message: '' });
    }
  }, [methods.formState]);

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar />
        <MobileNavbar />
        <div id="content" style={{ flexGrow: 1, padding: '20px' }}>
          <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="card">
              <FormProvider {...methods}>
                <form
                  style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  onSubmit={methods.handleSubmit(onSubmit)}
                  id="profile-form"
                >
                  <div className="card-body morph_variation1 padder">
                    <i className="fa-solid fa-phone fa-5x" style={{ color: '#c0392b' }}></i>
                    <h3>{t('supportPage.title')}</h3>
                    <p className="text-center" style={{ margin: '20px 0px 40px 0px', maxWidth: '540px' }}>
                      {t('supportPage.desc')}
                    </p>
                    <p className="text-center" style={{ margin: '0px 0px 40px 0px', fontWeight: 'bold' }}>
                      binary shift GmbH <br />
                      datenschutzkonform.ch <br />
                      Buchserstrasse 15 <br />
                      5000 Aarau <br />
                      support@datenschutzkonform.ch
                      <br />
                      Tel: 062 559 90 18
                      <br />
                    </p>
                    <Controller
                      control={methods.control}
                      name="purpose"
                      rules={{ ...validation.required }}
                      defaultValue=""
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('supportPage.purpose')}
                          id="support-purpose"
                          error={methods.formState.errors?.purpose}
                        />
                      )}
                    />
                    <Controller
                      control={methods.control}
                      name="message"
                      rules={{ ...validation.required }}
                      render={({ field }) => (
                        <Textarea
                          label={t('supportPage.message')}
                          id="support-message"
                          error={methods.formState.errors?.message}
                          {...field}
                        />
                      )}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}
                      >
                        {t('supportPage.send')}
                      </button>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Support };