import React, { useCallback, useEffect, useState } from 'react';
import logo from '../assets/images/Logo_Red_New.png';
import { NavLink, Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useApi } from 'services/axios';
import { LanguageSelect } from './LanguangeSelect';

function TipBar({ shouldRefetch }) {
  const [viewingAs, setViewingAs] = useState(null);
  const [meData, setMeData] = useState(null);
  const { api } = useApi();
  const { instance } = useMsal();

  const fetchMe = useCallback(async () => {
    if (!api) {
      console.error('API is not initialized in TipBar');
      return;
    }
    try {
      const response = await api.get('/me');

      if (response.data.isAdmin) {
        const viewingAsResponse = await api.get('/selected-user');
        setViewingAs(viewingAsResponse.data);
      }

      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [api]);

  useEffect(() => {
    if (api) {
      fetchMe();
    }
  }, [api, fetchMe]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchMe();
    }
  }, [shouldRefetch, fetchMe]);

  const handleLogout = () => {
    instance.logoutPopup();
  };

  if (!api) {
    return null; // or a loading indicator
  }

  return (
    <div className="neumorphic-topbar" style={{
      backgroundColor: 'white',
      padding: '46px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      minHeight: '80px',
    }}>

      {viewingAs && viewingAs.UserName && (
        <span style={{fontWeight: "bold", color: '#c0392b', position: 'absolute', left: '5%'}}>{viewingAs.UserName}</span>
      )}
      <Link to="/Programme" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
        <img src={logo} width="240px" style={{ marginTop: '6px', marginBottom: '10px' }} alt="Logo" />
      </Link>

      <ul className="list-unstyled components" style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        listStyleType: 'none',
        padding: '0',
        position: 'absolute',
        right: '10px',
        margin: '0',
      }}>


          <li style={{ margin: '0 0px' }}>
          <LanguageSelect /> 
        </li>


        <li style={{ margin: '0 0px' }}>
          <NavLink to="/Dokumente" className="neumorphic-link-iconbuttons">
            <i className="fa-solid fa-folder-open" style={{ color: '#c0392b', fontSize: '20px' }}></i>
          </NavLink>
        </li>
        <li style={{ margin: '0 0px' }}>
          <NavLink to="/Profil" className="neumorphic-link-iconbuttons">
            <i className="fa-solid fa-user" style={{ color: '#c0392b', fontSize: '20px' }}></i>
          </NavLink>
        </li>
        <li style={{ margin: '0 0px' }}>
          <NavLink to="/Support" className="neumorphic-link-iconbuttons">
            <i className="fa-solid fa-phone" style={{ color: '#c0392b', fontSize: '20px' }}></i>
          </NavLink>
        </li>
        <li style={{ margin: '0 0px' }}>
          <NavLink to="/" className="neumorphic-link-iconbuttons" onClick={handleLogout}>
            <i className="fa-solid fa-sign-out" style={{ color: '#c0392b', fontSize: '20px' }}></i>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default TipBar;