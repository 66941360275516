import React from 'react';
import { Input } from 'components/Input';
import { useForm, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const ContractForm = () => {
  const { control, formState, handleSubmit } = useForm();
  const validation = useValidation();
  const { t } = useTranslation('common');

  const INPUTS = [
    {
      label: t('verzeichnis.steps.1.inputs.designation'),
      name: 'bezeichnung',
      id: 'verzeichnis-bezeichnung',
      tooltipText: t('verzeichnis.steps.1.inputs.designationTooltip'),
    },
    {
      label: t('verzeichnis.steps.1.inputs.purpose'),
      name: 'zweck',
      id: 'verzeichnis-zweck',
      tooltipText: t('verzeichnis.steps.1.inputs.purposeTooltip'),
    },
  ];

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission here
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="card-body morph_variation1 padder">
        <div className={styles.steptext}>
          <h4>Bezeichnung und Zweck</h4>
        </div>
        <div className={`${styles.verzeichnisInputsWrapper} input-group`}>
          {INPUTS.map((input) => (
            <Controller
              key={input.id}
              control={control}
              name={`step1.${input.name.toLowerCase()}`}
              rules={{ ...validation.required }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...input}
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={formState.errors?.step1?.[input.name.toLowerCase()]}
                  className="form-control"
                />
              )}
            />
          ))}
        </div>
      </div>
    </form>
  );
};

export { ContractForm };