import React from 'react';
import { Input, Textarea } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

import styles from './styles.module.css';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

const Dsa_Step5 = () => {
  const { t } = useTranslation('common');
  const { control, formState } = useFormContext();

  const validation = useValidation();

  return (
    <div className="card-body">
      <h4>{t('dsa.steps.5.duration')}</h4>
      <div className={styles.dsaInputsWrapper} style={{ marginTop: '20px' }}>
        <Controller
          control={control}
          name="step5.duration"
          rules={{ ...validation.required }}
          defaultValue={t('dsa.steps.5.text')}
          render={({ field }) => {
            return (
              <Textarea
                {...field}
                label={t('dsa.steps.5.duration')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={formState.errors?.step5?.text}
                rows={15}
              />
            );
          }}
        />
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step5?.text?.message}
      </span>
    </div>
  );
};

export { Dsa_Step5 };
