import React from 'react';
import { FormField } from '../FormField';
import { Tooltip } from 'react-tooltip';
import styles from './styles.module.css';

const RadioGroup = ({
  onChange,
  name,
  options,
  label,
  value,
  error,
  customColor,
  tooltipText,
}) => {
  const tooltipAnchor = `tooltipAnchor${name.split('.').pop()}`;

  // Function to convert tooltipText with <br /> to JSX for line breaking with long tooltips
  const renderTooltipContent = (text) => {
    return text.split('<br />').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <FormField error={error}>
      <div className={styles.radioGroupWrapper}>
        <div className={styles.labelWrapper}>
          {label && (
            <label
              className={styles.radioGroupLabel}
              htmlFor={name}
              style={{ marginBottom: 0 }}>
              {label}
            </label>
          )}

          {tooltipText && (
            <>
              <span className={`${styles.tooltipWrapper} ${tooltipAnchor}`}>
                ?
              </span>
              <Tooltip anchorSelect={`.${tooltipAnchor}`} place="top">
                {renderTooltipContent(tooltipText)}
              </Tooltip>
            </>
          )}
        </div>
        <div className={styles.optionsWrapper}>
          {options.map((option) => (
            <label
              className={styles.radioOption}
              key={option.value}
              style={{ marginBottom: 0 }}
            >
              <input
                type="radio"
                value={option.value}
                checked={value === option.value}
                className={styles.radioInput}
                name={name}
                style={{ margin: 0, accentColor: customColor }}
                onChange={(e) => onChange(e.target.value)}
              />
              {option.label}
            </label>
          ))}
        </div>
      </div>
    </FormField>
  );
};

export { RadioGroup };
