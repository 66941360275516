import React from 'react';
import { ServiceCard, Navbar } from 'components';
import { useTranslation } from 'react-i18next';
import { MobileNavbar } from 'components/MobileNavbar';
import TipBar from 'components/TipBar'; // Import TipBar component

function VMOverview() {
  const { t } = useTranslation('common');

  return (
    <div className="wrapper" style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100vh'
    }}>
      <TipBar /> {/* TipBar at the top */}
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar /> {/* Navbar on the left */}
        <MobileNavbar />
        <div id="content" style={{ flexGrow: 1, padding: '50px' }}>
        <div
            className="container"
            style={{
              display: 'flex',
              padding: '0px 0px',
              width: '100%',
              maxWidth: '1450px',
              padding: '0 0',
            }}
          > 
            <div style={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', // Adjusted minimum width
              gap: '20px', // Adjusted gap between items
            }}>
              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('VMOverview.contract.title')}
                  icon="fa-solid fa-file-signature"
                  cardcolor="#34495e"
                  description={t('VMOverview.contract.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Standortbestimmung"
                  overviewLink="/ContractOverview"
                  active={true}
                  soon={false}
                  //ribbon="Preview"
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('VMOverview.partner.title')}
                  icon="fa-solid fa-people-group"
                  cardcolor="#eb3b5a"
                  description={t('VMOverview.partner.description')}
                  progressPercentage={25}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Verzeichnis"
                  overviewLink="/PartnerOverview"
                  active
                />
              </div>
              
              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('VMOverview.budget.title')}
                  icon="fa-solid fa-coins"
                  cardcolor="#fa8231"
                  description={t('VMOverview.budget.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Dsa"
                  overviewLink="/DsaOverview"
                  active
                  ribbon=""
                />
              </div>

              {/* <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.tom.title')}
                  icon="/icon-tom.svg"
                  cardcolor="#f7b731"
                  description={t('programmeData.tom.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Toms"
                  overviewLink="/TomsOverview"
                  ribbon=""
                  active
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.advv.title')}
                  icon="/icon-advv.svg"
                  cardcolor="#20bf6b"
                  description={t('programmeData.advv.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Advv"
                  overviewLink="/Advv"
                  active
                  ribbon=""
                />
              </div>

              <div className="text-center equal-height-col">
                <ServiceCard
                  title={t('programmeData.ghv.title')}
                  icon="/icon-ghv.svg"
                  cardcolor="#0fb9b1"
                  description={t('programmeData.ghv.description')}
                  progressPercentage={12}
                  lastEdited={t('programmeData.lastEdited', {
                    amount: '2 months',
                  })}
                  link="/Ghv"
                  overviewLink="/Ghv"
                  active
                  ribbon=""
                />
              </div> */}

     
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { VMOverview };