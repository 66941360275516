import React from "react";

import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";

import { useApi } from "services/axios";
import { Loader } from "components/Loader";
import { useValidation } from "helpers/validation";
import { RadioGroup } from "components/RadioGroup";
import { SelectDropdown } from "components/SelectDropdown";

import styles from "./styles.module.css";

const Verzeichnis_Step6 = () => {
  const [message, setMessage] = React.useState("");
  const [countries, setCountries] = React.useState([]);
  const [guarantees, setGuarantees] = React.useState([]);
  const [hasFetched, setHasFetched] = React.useState(false);
  const [protectionOneExists, setProtectionOneExists] = React.useState(false);
  const [protectionZeroExists, setProtectionZeroExists] = React.useState(false);

  const { control, watch, unregister, formState, setValue } = useFormContext();
  const { api } = useApi();
  const { t } = useTranslation("common");
  const validation = useValidation();
  const bekanntgabeAnswer = watch("step6.bekanntgabe");

  const selectedCountries = watch("step6.land");
  const selectedGurantees = watch("step6.garantien") || { preset: [] };

  const options = t("radioOptions", { returnObjects: true });

  const fetchData = async () => {
    const countriesResponse = await api.get("/verzeichnis_countries");
    const guaranteesResponse = await api.get("/verzeichnis_guarantees");

    const contriesData =
      countriesResponse.data &&
      countriesResponse.data.length > 0 &&
      countriesResponse.data.map((country) => {
        return {
          label: country.CountryName,
          value: country.CountryId,
          protection: country.AdequateEdoebProtection,
        };
      });

    const guaranteeData =
      guaranteesResponse.data &&
      guaranteesResponse.data.length > 0 &&
      guaranteesResponse.data.map((guarantee) => {
        return {
          label: guarantee.GuaranteeName,
          value: guarantee.GuaranteeId,
        };
      });

    setCountries(contriesData);
    setGuarantees(guaranteeData);
  };

  React.useEffect(() => {
    if (api && !hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [api]);

  React.useEffect(() => {
    if (bekanntgabeAnswer === "no") {
      unregister("step6.land");
      unregister("step6.angemessener");
      unregister("step6.garantien");
    }
  }, [bekanntgabeAnswer]);

  React.useEffect(() => {
    if (selectedCountries && selectedCountries.length > 0) {
      const anyCountryWithProtection1 = selectedCountries.some(
        (country) => country.protection === 1
      );

      const anyCountryWithProtection0 = selectedCountries.some(
        (country) => country.protection === 0
      );

      console.log({ anyCountryWithProtection1 });

      if (anyCountryWithProtection1) {
        setMessage(""); // Clear the message
        setProtectionOneExists(true);
        const newGurantee = guarantees?.find(
          (guarantee) => guarantee.value === 2
        );
        // Check if selectedGurantees is iterable and if newGurantee already exists
        const guarantee2Exists = selectedGurantees?.preset?.some(
          (guarantee) => guarantee.value === 2
        );
        const allCountriesWithProtection1 = selectedCountries.every(
          (country) => country.protection === 1
        );

        if (allCountriesWithProtection1) {
          const updatedGuarantee = [
            newGurantee, // Add the new guarantee with value 2
          ];

          setValue("step6.garantien", { preset: updatedGuarantee });
        }

        if (!guarantee2Exists) {
          console.log({ selectedGurantees });
          // Check if selectedGurantees is iterable
          const updatedGuarantees = [
            ...selectedGurantees?.preset, // Ensure selectedGurantees is an array
            newGurantee, // Add the new guarantee with value 2
          ];
          setValue("step6.garantien", { preset: updatedGuarantees });
        }
      } else {
        setProtectionOneExists(false);
      }

      if (anyCountryWithProtection0) {
        setMessage(t("verzeichnis.steps.6.messages.noAdequateProtection"));
        setProtectionZeroExists(true);
      } else {
        setMessage(t("verzeichnis.steps.6.messages.AdequateProtection"));
        setProtectionZeroExists(false);
      }
    } else {
      setValue("step6.garantien", { preset: [] });
    }
  }, [selectedCountries, selectedGurantees, t]);

  return (
    <div className="card-body">
      <h4>Ausland</h4>
      <div className={styles.steptext}>
        {t("verzeichnis.steps.6.description")}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        <Controller
          name="step6.bekanntgabe"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t("verzeichnis.steps.6.inputs.disclosureAbroad")}
              name="bekanntgabe"
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step6?.bekanntgabe}
            />
          )}
        />

        {(!countries || !guarantees) && <Loader />}

        {countries && bekanntgabeAnswer === "yes" && (
          <Controller
            name="step6.land"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label={t("verzeichnis.steps.6.inputs.country")}
                
                options={countries}
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step6?.land}
                name={field.name}
                isMulti
              />
            )}
          />
        )}

        {/* Display message for no adequate protection */}
        {message &&
          bekanntgabeAnswer &&
          selectedCountries &&
          selectedCountries.length > 0 && <div>{message}</div>}

        {guarantees && bekanntgabeAnswer === "yes" && selectedCountries && (
          <Controller
            control={control}
            name="step6.garantien"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label={t("verzeichnis.steps.6.inputs.guarantees")}
                options={
                  protectionOneExists && !protectionZeroExists
                    ? guarantees.filter((guarantee) => guarantee.value === 2)
                    : guarantees
                }
                isMulti
                isCreatable
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step6?.garantien}
                name={field.name}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Verzeichnis_Step6 };
