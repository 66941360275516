import React from 'react';

import logo from 'assets/images/Logo_Red_New.png';
import closeIcon from 'assets/images/close-icon.svg';
import menuIcon from 'assets/images/burger-menu-icon.svg';

import { Modal } from 'components/Modal';
import { NavLink, Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { LanguageSelect } from 'components/LanguangeSelect';

const MobileNavbar = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const { t } = useTranslation('common');
  const { instance } = useMsal();

  const handleOpenMenu = () => {
    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const handleLogout = React.useCallback(() => {
    instance.logoutPopup();
  }, []);

  return (
    <div className={styles.navbar}>
      <Link to="/Home">
        <span>
          <img src={logo} width="240px" />
        </span>
      </Link>
      <span onClick={handleOpenMenu}>
        <img src={menuIcon} className={styles.menu} />
      </span>

      {showMenu && (
        <Modal className={styles.modal}>
          <div className={styles.closeWrapper}>
            <span onClick={handleCloseMenu}>
              <img src={closeIcon} className={styles.closeIcon} />
            </span>
          </div>

          <nav className={styles.navPoints}>
            {/* <div
              style={{
                margin: '20px 16px 20px 8px',
              }}
            >
              <LanguageSelect />
            </div> */}
            <ul className="list-unstyled components">
              <li className="active">
                <NavLink to="/Home" className="neumorphic-link">
                  <h5>
                    <i
                      className="fa-solid fa-house"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    {t('navbar.home')}
                  </h5>
                </NavLink>
              </li>
              <li className="active">
                <NavLink to="/Programme" className="neumorphic-link">
                  <h5>
                    <i
                      className="fa-solid fa-computer"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    {t('navbar.dataProtection')}
                  </h5>
                </NavLink>
              </li>
              <li>
                <NavLink to="/Dokumente" className="neumorphic-link">
                  <h5>
                    <i
                      className="fa-solid fa-folder-open"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    {t('navbar.docs')}
                  </h5>
                </NavLink>
              </li>
              <li>
                <NavLink to="/Profil" className="neumorphic-link">
                  <h5>
                    <i
                      className="fa-solid fa-user"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    {t('navbar.profile')}
                  </h5>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/Nachrichten" className="neumorphic-link">
                  <h5>
                    <i
                      className="fa-solid fa-envelope"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    {t('navbar.news')}
                  </h5>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/Einstellungen" className="neumorphic-link">
                  <h5>
                    <i
                      className="fa-solid fa-gear"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    EINSTELLUNGEN
                  </h5>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/Support" className="neumorphic-link">
                  <h5>
                    <i
                      className="fa-solid fa-phone"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    {t('navbar.support')}
                  </h5>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/"
                  className="neumorphic-link"
                  onClick={handleLogout}
                >
                  <h5>
                    <i
                      className="fa-solid fa-sign-out"
                      style={{ color: '#c0392b', marginRight: '10px' }}
                    ></i>
                    {t('navbar.logout')}
                  </h5>
                </NavLink>
              </li>
            </ul>
          </nav>
        </Modal>
      )}
    </div>
  );
};

export { MobileNavbar };
