import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ServiceCard({
  title,
  description,
  lastEdited,
  icon,
  link,
  overviewLink,
  ribbon,
  active,
  imageSrc,
  cardcolor,
  alternative,
  soon = false
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const handleButtonClick = () => {
    // Unterscheidung zwischen internen und externen Links
    if (link.startsWith('http')) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      navigate(link);
    }
  };

  const handleOverviewBtn = () => {
    // Unterscheidung zwischen internen und externen Links
    if (overviewLink.startsWith('http')) {
      window.open(overviewLink, '_blank', 'noopener,noreferrer');
    } else {
      navigate(overviewLink);
    }
  };

  const buttonText = alternative === 1 
    ? 'alt1Module.seeDetails' 
    : alternative === 2
        ? 'programmeData.proton.button' 
        : 'pendingModule.seeDetails';

  const iconStyles = {
    // width: '60px',
    height: '60px',
    marginTop: '10px',
    marginBottom: '10px',
    color: cardcolor,
  };

  return (
    <div
      className="card morph_variation1 padder"
      style={{ height: '100%', position: 'relative' }}
    >
      <div className="ribbon">{ribbon}</div>
      <div className="card-body service-card">
        {imageSrc ? (
          <img
            src={imageSrc}
            alt={title}
            style={{
              width: '160px',
              height: '100px',
              paddingTop: '10px',
              paddingBottom: '26px',
            }}
          />
        ) : icon.startsWith('/') ? (
          <img
            src={icon}
            alt={`${title} icon`}
            style={iconStyles}
          />
        ) : (
          <i
            className={`fa-solid ${icon} fa-4x`}
            style={iconStyles}
          />
        )}
        <h4 className="card-title">{title}</h4>
        <p className="card-text">{description}</p>

        {active ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '5px',
            }}
          >
            <button
              className="btn btn-secondary"
              style={{ marginBottom: '10px', marginTop: '10px' }}
              onClick={handleOverviewBtn}
            >
              {t('serviceCard.overview')}
            </button>
            <button
              onClick={handleButtonClick}
              className="btn btn-primary"
              style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: cardcolor }}
            >
              {t('serviceCard.create')}
            </button>
          </div>
        ) : (
          soon ? (
            <div style={{ fontWeight: 'bold' }}>in Entwicklung</div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <button
                className="btn btn-primary"
                onClick={handleButtonClick}
                style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: cardcolor }}
              >
                {t(buttonText)}
              </button>
            </div>
          )
        )}

        {/* <p className="card-text">
          <small className="text-muted">{lastEdited}</small>
        </p> */}
      </div>
    </div>
  );
}

export { ServiceCard };
