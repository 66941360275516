import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useApi } from 'services/axios';
import { Navbar, Loader, MobileNavbar } from 'components';
import { useTranslation } from 'react-i18next';
import { DetailedViewPdf } from './detailedViewPdf';
import TipBar from 'components/TipBar';

import styles from './styles.module.css';

export const TITLES = [
  'Zutrittskontrollen',
  'Zugangskontrollen',
  'Zugriffskontrollen',
  'Trennungskontrolle',
  'Eingabekontrolle',
  'Weitergabekontrolle',
  'Verfügbarkeit und Belastbarkeit',
  'Datenschutz und -sicherheitsereignisse',
  'Pseudonymisierung',
  'Verschlüsselung',
  'Wiederherstellungsmanagement bzw. Fehlerbeseitigung',
];

const StandortbestimmungDetailedView = () => {
  const { id } = useParams();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);
  const { api } = useApi();
  const { t } = useTranslation('common');

  // const LABELS = t('detailedView.verzeichnis.labels', { returnObjects: true });

  const fetchEntry = async () => {
    try {
      const entryResponse = await api.get(`/sb_alldata/${id}`);
      const companyResponse = await api.get(`/companydata`);

      setError(null);
      setData(entryResponse.data);
      setCompanyData(companyResponse.data[0]);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api && !data && !companyData && !error) {
      fetchEntry();
    }
  }, [api]);

  if (error)
    return (
      <div className="wrapper">
        <div id="content">
          <Link to="/StandortbestimmungOverview" className={styles.detailedViewLink}>
            <button className="btn btn-default">
              {t('detailedView.backToOverview')}
            </button>
          </Link>
          <h2>{t('detailedView.notFound')}</h2>
        </div>
      </div>
    );

  if (!data)
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar />
      <MobileNavbar />

      <div
        className={`${styles.detailedViewWrapper} morph_variation1`}
        id="content"
      >
        <div className={styles.detailedViewHeadingWrapper}>
          <div className={styles.detailedViewTitleWrapper}>
            <Link to="/StandortbestimmungOverview" className={styles.detailedViewLink}>
              <button className="btn btn-default">
                {t('detailedView.backToOverview')}
              </button>
            </Link>
          </div>

          <div className={styles.detailedViewLinks}>
            <Link to={`/Standortbestimmung/${id}`} className={styles.detailedViewLink}>
              <button className="btn btn-primary accent-dark-grey-bg">
                {t('detailedView.edit')}
              </button>
            </Link>
            <PDFDownloadLink
              document={
                <DetailedViewPdf
                  data={{ ...data, id, companyInfo: companyData }}
                />
              }
              fileName={`StandortbestimmungEntry-${id}`}
            >
              {({ loading }) => (
                <button className="btn btn-default">
                  {loading
                    ? 'Loading document...'
                    : t('detailedView.downloadPDF')}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>

        <hr />

        <div className={styles.detailedViewContent}>
          <div className={styles.mainInfoPoints}>
            <div className={styles.mainInfoPoint}>
              <span className={styles.mainInfoPointTitle}>ID</span>
              <span className={styles.mainInfoPointValue}>{id}</span>
            </div>
            <div className={styles.mainInfoPoint}>
              <span className={styles.mainInfoPointTitle}>
                {t('detailedView.dateCreated')}
              </span>
              <span className={styles.mainInfoPointValue}>
                {data.dateCreated}
              </span>
            </div>
            <div className={styles.mainInfoPoint}>
              <span className={styles.mainInfoPointTitle}>
                {t('detailedView.lastEdited')}
              </span>
              <span className={styles.mainInfoPointValue}>
                {data.lastEdited}
              </span>
            </div>
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.categoryPoints}>
            {Object.keys(data).map((step, idx) => (
              <div className={styles.categoryPoint} key={idx}>
                <h5 className={styles.categoryPointTitle}>{TITLES[idx]}</h5>
                <ul className={styles.categoryPointList}>
                  {Object.keys(data[step]).map((key) => {
                    return Array.isArray(data[step][key])
                      ? data[step][key].map((obj, i) => (
                          <li className={styles.categoryPointListItem} key={i}>
                            {obj.label}
                          </li>
                        ))
                      : null;
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <hr />
{/* 
        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>
              Weitere Massnahmen
            </span>
            <span className={styles.mainInfoPointValue}>
              {data.step12.furtherMeasures}
            </span>
          </div>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Zertifizierungen</span>
            <span className={styles.mainInfoPointValue}>
              {data.step12.certificates}
            </span>
          </div>
        </div> */}
      </div>
    </div>
    </div>
  );
};

export { StandortbestimmungDetailedView };
