import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { DATA_ABROAD, PURPOSES } from './constants';
import { TITLES } from 'Programme/Toms/TomsDetailedView';

const DetailedViewPdf = ({ data, selectedTomsData, categoryData }) => {
  console.log("toms data : " +selectedTomsData)
  const { companyInfo } = data;
  const { t } = useTranslation('common');

  const pdfStyles = StyleSheet.create({
    pageContainer: {
      paddingVertical: 35,
      paddingHorizontal: 25,
      fontFamily: 'Helvetica',
    },
    outerPadding: { padding: 35 },
    documentCompanyData: {
      fontSize: 10,
      textAlign: 'left',
      marginBottom: 30,
      padding: 8,
      border: '1px solid #e0e0e0',
      backgroundColor: '#f8f8f8',
      borderRadius: 5,
    },
    documentTitle: {
      fontSize: 14,
      textAlign: 'left',
      fontWeight: 'bold',
      marginBottom: 15,
      borderBottom: '1px solid #000',
      paddingBottom: 5,
    },
    section: { display: 'flex', flexDirection: 'column', marginBottom: 10 },
    sectionTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      marginTop: 10,
      marginBottom: 5,
      color: '#333',
    },
    sectionText: {
      fontSize: 10,
      lineHeight: 1.3,
      color: '#555',
      marginBottom: 8,
    },
    categoryTitle: {
      fontSize: 11,
      fontWeight: 'bold',
      marginBottom: 5,
      color: '#000',
    },
    categoryListItem: { fontSize: 9, marginBottom: 2, paddingLeft: 10 },
    fourColumnGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    column: { width: '48%', marginBottom: 10 },
    listItem: {
      fontSize: 9,
      marginBottom: 2,
      paddingLeft: 12,
      color: '#555',
    },
    horizontalRule: {
      borderBottom: '1px solid #333',
      marginVertical: 10,
      opacity: 0.6,
    },
    stylishLine: {
      width: '100%',
      height: 1,
      backgroundColor: '#333',
      marginVertical: 12,
      opacity: 0.7,
    },
    tomsSection: {
      marginTop: 10,
      marginBottom: 10,
    },
    tomsTitle: {
      fontSize: 11,
      fontWeight: 'bold',
      marginBottom: 5,
    },
    tomsItem: {
      fontSize: 9,
      marginBottom: 2,
      paddingLeft: 10,
    },
  });

  return (
    <Document>
      <Page size="A4" style={pdfStyles.pageContainer}>
        <View style={pdfStyles.outerPadding}>
          {/* Company Data */}
          <View style={pdfStyles.documentCompanyData}>
            <Text>{companyInfo?.CompanyName}</Text>
            <Text>{companyInfo?.CompanyStreet}</Text>
            <Text>{companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}</Text>
            <Text>Telefon: {companyInfo?.CompanyPhone}</Text>
          </View>

          {/* Document Title */}
          <View style={pdfStyles.documentTitle}>
            <Text>Datenschutzerklärung {companyInfo?.CompanyName}</Text>
          </View>

          {/* Introduction */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.sectionTitle}>Einleitung</Text>
            <Text style={pdfStyles.sectionText}>
              In dieser Datenschutzerklärung erläutern wir, die {companyInfo?.CompanyName} (nachfolgend wir oder uns), wie wir
              Personendaten erheben und sonst bearbeiten. Dies ist keine abschließende Beschreibung; allenfalls regeln andere
              Datenschutzerklärungen spezifische Sachverhalte...
            </Text>
          </View>

          <View style={pdfStyles.stylishLine} />

          {/* Rechtsgrundlage */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.sectionTitle}>Rechtsgrundlage</Text>
            <Text style={pdfStyles.sectionText}>
              {data.step1.law.value === 1
                ? 'Diese Datenschutzerklärung ist auf die Anforderungen des Schweizer Datenschutzgesetzes (DSG) ausgelegt.'
                : data.step1.law.value === 2
                ? 'Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) ausgelegt.'
                : 'Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) und des Schweizer Datenschutzgesetzes (DSG) ausgelegt.'}
            </Text>
          </View>

          <View style={pdfStyles.stylishLine} />

          {/* Verantwortlicher */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.sectionTitle}>Verantwortlicher</Text>
            <Text style={pdfStyles.sectionText}>
              Verantwortlich für die Datenbearbeitungen, die wir hier beschreiben, ist die {companyInfo?.CompanyName}. 
              Wenn Sie datenschutzrechtliche Anliegen haben, können Sie uns diese an folgende Kontaktadresse mitteilen:
              {'\n'}{companyInfo?.CompanyName}, {companyInfo?.CompanyStreet}, {companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}. 
              Telefon: {companyInfo?.CompanyPhone}.
            </Text>
          </View>

          <View style={pdfStyles.stylishLine} />

          {/* Erhebung */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.sectionTitle}>Erhebung und Bearbeitung von Personendaten</Text>
            <Text style={pdfStyles.sectionText}>
              Wir bearbeiten in erster Linie die Personendaten, die wir im Rahmen unserer Geschäftsbeziehung mit unseren Kunden und anderen Geschäftspartnern von diesen und weiteren daran beteiligten Personen erhalten...
            </Text>
          </View>

          <View style={pdfStyles.stylishLine} />

          {/* Category Data Section */}
          {categoryData && (
            <>
              <View style={pdfStyles.section}>
                <Text style={pdfStyles.sectionTitle}>Kategorien von Personendaten</Text>
              </View>
              <View style={pdfStyles.fourColumnGrid}>
                {Object.entries(categoryData.categories).map(([category, data], index) => (
                  <View key={category} style={pdfStyles.column}>
                    <Text style={pdfStyles.categoryTitle}>{category}</Text>
                    <View>
                      {data.preset.map((item, index) => (
                        <Text key={`preset-${index}`} style={pdfStyles.categoryListItem}>• {item.label}</Text>
                      ))}
                      {data.custom && data.custom.map((item, index) => (
                        <Text key={`custom-${index}`} style={pdfStyles.categoryListItem}>• {item.label}</Text>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            </>
          )}

          <View style={pdfStyles.stylishLine} />

          {/* Zweck */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.sectionTitle}>Zweck der Datenbearbeitungen</Text>
            <Text style={pdfStyles.sectionText}>
              Wir verwenden die von uns erhobenen Personendaten in erster Linie, um unsere Verträge mit unseren Kunden und Geschäftspartnern abzuschließen und abzuwickeln...
            </Text>
            {data.step3.purpose?.preset?.map((point, i) => (
              <View key={i} style={{ flexDirection: 'row', marginBottom: 2 }}>
                <Text style={{ marginLeft: 10 }}>•</Text>
                <Text style={pdfStyles.listItem}>{PURPOSES[point.value - 1]}</Text>
              </View>
            ))}
          </View>

          <View style={pdfStyles.stylishLine} />

          {/* Dauer der Aufbewahrung */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.sectionTitle}>Dauer der Aufbewahrung</Text>
            <Text style={pdfStyles.sectionText}>
              Wir verarbeiten und speichern Ihre Personendaten, solange es für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder sonst die mit der Bearbeitung verfolgten Zwecke erforderlich ist...
            </Text>
          </View>

          <View style={pdfStyles.stylishLine} />


          {/* Tom Detail */}
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.sectionTitle}>Datensicherheit</Text>
            <Text style={pdfStyles.sectionText}>
            Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und
              haben technische und organisatorische Massnahmen getroffen, um die
              Sicherheit Ihrer Daten zu gewährleisten. Insbesondere speichern
              und verarbeiten wir unsere Daten unter hohen Sicherheitsstandards.
            </Text>
            {selectedTomsData && (
              <View style={pdfStyles.tomsSection}>
                <Text style={pdfStyles.tomsTitle}>Unter anderem haben wir folgende Massnahmen umgesetzt:</Text>
                {Object.entries(selectedTomsData)
                  .filter(([key]) => key.includes('step'))
                  .map(([step, stepData], idx) => (
                    <View key={step} style={pdfStyles.tomsSection}>
                      <Text style={pdfStyles.tomsTitle}>{TITLES[idx]}</Text>
                      {Object.values(stepData).flatMap((points) =>
                        Array.isArray(points)
                          ? points.map((obj, i) => (
                              <Text key={i} style={pdfStyles.tomsItem}>• {obj.label}</Text>
                            ))
                          : null
                      )}
                    </View>
                  ))}
                  <Text style={pdfStyles.sectionTitle}>Weitere Massnahmen :</Text>
                  <Text style={pdfStyles.sectionText}>{selectedTomsData.step12.furtherMeasures}</Text>
                  <Text style={pdfStyles.sectionTitle}>Zertifizierungen :</Text>
                  <Text style={pdfStyles.sectionText}>{selectedTomsData.step12.certificates}</Text>
              </View>
            )}
            
          </View>
          

          <View style={pdfStyles.stylishLine} />

          

          {/* Letzte Bearbeitung */}
          <View style={pdfStyles.sectionText}>
            <Text>Stand: {data.lastEdited.replace(/-/g, '.')}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export { DetailedViewPdf };
