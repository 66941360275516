import React from 'react';
import { FormField } from 'components/FormField';

import './styles.css';
import { Input } from 'components/Input';
import { Controller, useFormContext } from 'react-hook-form';
import { useValidation } from 'helpers/validation';

const Checkbox = ({
  label,
  value,
  onChange,
  name,
  error,
  withInput,
  inputRequired,
  step,
  disabled,
  checkboxTargetClassName,
}) => {
  const { control, formState } = useFormContext();
  const validation = useValidation();

  return (
    <div className="checkbox-wrapper">
      <FormField error={error}>
        <span className="checkbox" style={{ opacity: disabled ? '0.6' : '1' }}>
          <label className="checkbox" htmlFor={name} style={{ padding: 0 }}>
            <input
              type="checkbox"
              className="checkbox"
              name={name}
              checked={value}
              onChange={(e) => onChange(+e.target.checked)}
              disabled={disabled}
            />
            <span
              className={`checkboxTarget ${checkboxTargetClassName}`}
            ></span>
            <span className="white-bg"></span>
            {label}
          </label>
        </span>
      </FormField>
      {withInput && Boolean(value) && (
        <Controller
          control={control}
          name={`${step}.${name}.input`}
          defaultValue=""
          rules={inputRequired ? { ...validation.required } : undefined}
          render={({ field }) => (
            <Input
              {...field}
              error={formState?.errors?.[step]?.[name]?.input?.message}
            />
          )}
        />
      )}
    </div>
  );
};

export { Checkbox };
