import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Loader } from 'components/Loader';

import styles from './styles.module.css';

const Toms_Step6 = () => {
  const [transferControl, setTransferControl] = React.useState(null);
  const [hasFetched, setHasFetched] = React.useState(false);
  const { control, formState } = useFormContext();
  const { api } = useApi();
  const validation = useValidation();

  const fetchData = async () => {
    const transferControlResponse = await api.get('/toms_transfercontrol');

    setTransferControl(transferControlResponse.data);
  };

  React.useEffect(() => {
    if (api && !hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [api]);

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        Massnahmen, welche ein unbefugtes Lesen, Kopieren, Verändern oder
        Entfernen bei elektronischer Übertragung oder Transport verhindern.
      </div>
      <div className={styles.tomsInputsWrapper}>
        {!transferControl && <Loader />}
        {transferControl && (
          <Controller
            name="step6.transferControl"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label="Weitergabekontrolle"
                options={transferControl.map((entry) => {
                  return {
                    label: entry.TransfercontrolName,
                    value: entry.TransfercontrolId,
                  };
                })}
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step6?.transferControl}
                name={field.name}
                isMulti
                customColor="#f7b731"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Toms_Step6 };
