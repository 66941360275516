import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { SelectDropdown, Loader, Input } from 'components';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const DSV_Step1 = () => {
  const [affected, setAffected] = React.useState(null);
  const { control, formState } = useFormContext();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const fetchData = async () => {
    try {
      const affectedResponse = await api.get('/dsv_affected');
      setAffected(affectedResponse.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  React.useEffect(() => {
    if (api && !affected) {
      fetchData();
    }
  }, [api]);

  return (
    <div className="card-body">
      <div className={styles.dsvInputsWrapper}>
        <Controller
          control={control}
          name={`step1.description`}
          rules={{ ...validation.required }}
          defaultValue=""
          render={({ field }) => {
            return (
              <Input
                {...field}
                label={t('dsv.steps.1.inputs.description')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={formState.errors?.step1?.description}
              />
            );
          }}
        />

        {!affected && <Loader />}
        {affected && (
          <Controller
            control={control}
            name="step1.affected"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                options={affected.map((item) => {
                  return {
                    label: item.AffectedName,
                    value: item.AffectedId,
                  };
                })}
                isMulti
                label={t('dsv.steps.1.inputs.affected')}
                isCreatable
                onChange={field.onChange}
                value={field.value}
                error={formState.errors?.step1?.affected}
                name={field.name}
                customColor="#a55eea"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { DSV_Step1 };
