import React, { useEffect, useState } from 'react';
import { Input, Navbar } from 'components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useApi } from 'services/axios';
import { useValidation } from 'helpers/validation';
import { toast } from 'react-toastify';
import { Loader } from 'components/Loader';
import { MobileNavbar } from 'components/MobileNavbar';
import { useTranslation } from 'react-i18next';
import TipBar from 'components/TipBar';


const Profil = () => {
  const methods = useForm({ mode: 'onChange' });
  const { api } = useApi();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const INPUTS = t('profilePage.inputs', { returnObjects: true });

  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await api.get('/companydata');
      setData(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const submitForm = async (formData) => {
    try {
      const postResponse = await api.post('/companydata', formData);
      console.log('post response: ', postResponse);
    } catch (err) {
      console.error('Error submitting form: ', err);
    }
  };

  const onSubmit = (data) => {
    submitForm(data);
  };

  useEffect(() => {
    if (api && !data) {
      fetchData();
    }
  }, [api]);

  useEffect(() => {
    if (data) {
      methods.reset(data);
    }
  }, [data]);

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      toast.success(t('profilePage.successMsg'));
    }
  }, [methods.formState]);

  if (!data) {
    return (
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar /> {/* Navbar on the left */}
        <MobileNavbar />
        <div id="content" style={{ flexGrow: 1, padding: '20px' }}>
          <div className="container" style={{ display: 'flex' }}>
            <div style={{ display: 'grid', width: '100%', gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))', gap: '20px' }}>
              <div className="row" style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 0 }}>
                <div className="card">
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} id="profile-form">
                      <div className="card-body morph_variation1 padder">
                        <i className="fa-solid fa-user fa-5x" style={{ color: '#c0392b' }}></i>
                        <h3>{t('profilePage.title')}</h3>
                        <p className="text-center" style={{ margin: '20px 0px 40px 0px' }}>
                          {t('profilePage.subtitle1')} <br />
                          {t('profilePage.subtitle2')}
                        </p>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                          {INPUTS.map((input) => (
                            <Controller
                              key={input.id}
                              control={methods.control}
                              name={input.id}
                              rules={{ ...validation.required }}
                              defaultValue=""
                              render={({ field }) => (
                                <Input {...input} {...field} error={methods.formState.errors?.[input.id]} />
                              )}
                            />
                          ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <button type="submit" className="btn btn-primary" style={{ marginBottom: '10px', marginTop: '16px', width: '200px' }}>
                            {t('profilePage.submit')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Profil };