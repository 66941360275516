import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useApi } from 'services/axios';
import { RadioGroup, SelectDropdown, Loader } from 'components';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const DSV_Step3 = () => {
  const [disclosedTypeCategories, setDisclosedTypeCategories] =
    React.useState(null);
  const { control, formState, getValues } = useFormContext();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const options = t('radioOptions', { returnObjects: true });

  const fetchData = async () => {
    try {
      const disclosedTypeCategories = await api.get('/dsfa_categories');
      const disclosedTypeSubcategories = await api.get('/dsfa_subcategories');

      const categoriesWithSubcategories = disclosedTypeCategories.data.map(
        (category) => {
          const subcategories = disclosedTypeSubcategories.data.filter(
            (subCategory) => subCategory.CategoryId === category.CategoryId
          );
          return { ...category, Subcategories: subcategories };
        }
      );

      setDisclosedTypeCategories(categoriesWithSubcategories);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const validateFields = () => {
    const isAnyFieldFilled = Object.values(getValues()?.step3).some(
      (fieldValues) =>
        fieldValues !== null &&
        typeof fieldValues !== 'string' &&
        Object.values(fieldValues)
          .map((fieldValuesArray) => fieldValuesArray?.length > 0)
          .some((value) => value === true)
    );

    return isAnyFieldFilled || 'Es ist mindestens ein Auswahlfeld erforderlich';
  };

  React.useEffect(() => {
    if (api && !disclosedTypeCategories) {
      fetchData();
    }
  }, [api]);

  return (
    <div className="card-body">
      <h3>{t('dsv.steps.3.title')}</h3>
      <div className={styles.dsvInputsWrapper}>
        {!disclosedTypeCategories && <Loader />}
        {disclosedTypeCategories &&
          disclosedTypeCategories.map((category) => (
            <Controller
              key={category.CategoryName}
              name={`step3.${category.CategoryName.replace(/[,\s-]/g, '')}`}
              control={control}
              defaultValue={null}
              rules={{ validate: validateFields }}
              render={({ field }) => (
                <SelectDropdown
                  options={category.Subcategories.map((subcategory) => {
                    return {
                      label: subcategory.SubcategoryName,
                      value: subcategory.SubcategoryId,
                    };
                  })}
                  label={category.CategoryName}
                  isMulti
                  isCreatable
                  value={field.value}
                  onChange={field.onChange}
                  name={field.name}
                  customColor="#a55eea"
                />
              )}
            />
          ))}
        <Controller
          name="step3.sensitivedata"
          control={control}
          rules={{ ...validation.required }}
          defaultValue="no"
          render={({ field }) => (
            <RadioGroup
              label={t('dsv.steps.3.inputs.sensitiveData')}
              name="sensitivedata"
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step3?.sensitivedata}
              customColor="#a55eea"
            />
          )}
        />
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step3?.ArbeitsundBerufsdaten?.message}
      </span>
    </div>
  );
};

export { DSV_Step3 };
