import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { Tooltip } from 'react-tooltip';

const RadioGroup = ({
  label,
  description,
  options,
  field,
  error,
  tooltipText,
}) => {
  const tooltipAnchor = `tooltipAnchor${field.name.split('.').pop()}`;

  return (
    <div className={styles.radioGroup}>
      <div className={styles.labelWrapper}>
        {label && (
          <label
            className={styles.radioLabel}
            htmlFor={field.name}
            style={{ marginBottom: 0 }}
          >
            {label}
          </label>
        )}
        {tooltipText && (
          <div className={`${styles.tooltipWrapper} ${tooltipAnchor}`}>
            ?
            <Tooltip anchorSelect={`.${tooltipAnchor}`} place="top">
              {tooltipText}
            </Tooltip>
          </div>
        )}
      </div>
      <div>
        {description}

        <p></p>
        {options.map((option) => (
          <div key={option.value} className={styles.radioOption}>
            <label>
              <input
                type="radio"
                value={option.value}
                checked={field.value === option.value}
                onChange={() => field.onChange(option.value)}
                onBlur={field.onBlur}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {error && <span className={styles.error}>{error.message}</span>}
    </div>
  );
};

const Standortbestimmung_Step7 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const INPUTS = [
    {
      label: t('sb.steps.7.inputs.verzeichnis'),
      //description: t('sb.steps.7.inputs.verzeichnis'),
      name: 'existingbr',
      //tooltipText: t('sb.steps.7.inputs.verzeichnisTooltip'),
      options: [
        { value: 1, label: t('sb.steps.7.inputs.yes') },
        { value: 2, label: t('sb.steps.7.inputs.no') },
        //value 1 = FOLGE BEI ALLEN: DOKUMENT ABLEGEN
        //value 2 = FOLGE BEI ALLEN: DOKUMENT NOCH ZU ERSTELLEN
      ],
    },
    {
      label: t('sb.steps.7.inputs.datenschutzerklaerung'),
      //description: t('sb.steps.7.inputs.datenschutzerklaerung'),
      name: 'existingdsa',
      //tooltipText: t('sb.steps.7.inputs.datenschutzerklaerungTooltip'),
      options: [
        { value: 1, label: t('sb.steps.7.inputs.yes') },
        { value: 2, label: t('sb.steps.7.inputs.no') },
      ],
    },
    {
      label: t('sb.steps.7.inputs.toms'),
      //description: t('sb.steps.7.inputs.toms'),
      name: 'existingtoms',
      //tooltipText: t('sb.steps.7.inputs.tomsTooltip'),
      options: [
        { value: 1, label: t('sb.steps.7.inputs.yes') },
        { value: 2, label: t('sb.steps.7.inputs.no') },
      ],
    },
    {
      label: t('sb.steps.7.inputs.adv'),
      //description: t('sb.steps.7.inputs.adv'),
      name: 'existingadvv',
      //tooltipText: t('sb.steps.7.inputs.advTooltip'),
      options: [
        { value: 1, label: t('sb.steps.7.inputs.yes') },
        { value: 2, label: t('sb.steps.7.inputs.no') },
      ],
    },
    {
      label: t('sb.steps.7.inputs.geheim'),
      //description: t('sb.steps.7.inputs.geheim'),
      name: 'existingghv',
      //tooltipText: t('sb.steps.7.inputs.geheimTooltip'),
      options: [
        { value: 1, label: t('sb.steps.7.inputs.yes') },
        { value: 2, label: t('sb.steps.7.inputs.no') },
      ],
    },
  ];

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        <h4>{t('sb.steps.7.title')}</h4>
      </div>
      <div className={styles.sbInputsWrapper}>
        {t('sb.steps.7.inputs.document_description')}

        {INPUTS.map((input) => (
          <Controller
            key={input.name}
            control={control}
            name={`step7.${input.name.toLowerCase()}`}
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <RadioGroup
                label={input.label}
                description={input.description}
                tooltipText={input.tooltipText}
                options={input.options}
                field={field}
                error={formState.errors?.step7?.[input.name.toLowerCase()]}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export { Standortbestimmung_Step7 };
