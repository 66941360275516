import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Loader } from 'components/Loader';

import styles from './styles.module.css';

const Toms_Step9 = () => {
  const [pseudoNymisation, setPseudoNymisation] = React.useState(null);
  const [hasFetched, setHasFetched] = React.useState(false);
  const { control, formState } = useFormContext();
  const { api } = useApi();
  const validation = useValidation();

  const fetchData = async () => {
    const pseudoNymisationResponse = await api.get('/toms_pseudonymisation');

    setPseudoNymisation(pseudoNymisationResponse.data);
  };

  React.useEffect(() => {
    if (api && !hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [api]);

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        Hierbei wird der Name oder anderes Identifikationsmerkmal durch ein
        Alternativmerkmal (z.B. als Code in Zahlen- Buchstabenkombination)
        ersetzt, die Identität des Betroffenen soll hierdurch verborgen bleiben
        bzw. wesentlich erschwert feststellbar werden.
      </div>
      <div className={styles.tomsInputsWrapper}>
        {!pseudoNymisation && <Loader />}
        {pseudoNymisation && (
          <Controller
            name="step9.pseudoNymisation"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label="Pseudonymisierung"
                options={pseudoNymisation.map((entry) => {
                  return {
                    label: entry.PseudonymisationName,
                    value: entry.PseudonymisationId,
                  };
                })}
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step9?.pseudoNymisation}
                name={field.name}
                isMulti
                customColor="#f7b731"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Toms_Step9 };
