import React from 'react';
import { DeleteModal, Loader, Navbar, MobileNavbar } from 'components';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';
import { BlobServiceClient } from '@azure/storage-blob';
import { azureStorageConfig } from 'msalConfig';
import { getStorageEnv } from 'services/getStorageEnv';
import { UploadsTable } from './components/uploadsTable';
import { Table } from './components/table';
import TipBar from 'components/TipBar';


import styles from './styles.module.css';

const sortEntries = (entries) =>
  entries.sort((a, b) => {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  });

function Dokumente() {
  const [data, setData] = React.useState(null);
  const [uploadsData, setUploadsData] = React.useState(null);
  const [meData, setMeData] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [showUploadsModal, setShowUploadsModal] = React.useState(false);

  const { t } = useTranslation('common');
  const { api } = useApi();

  const blobClient = new BlobServiceClient(
    `https://${azureStorageConfig.ACCOUNT}.blob.core.windows.net/?${azureStorageConfig.SAS_TOKEN}`
  );

  const containerClient = blobClient.getContainerClient(
    `user${meData?.id}-${getStorageEnv()}`
  );

  const fetchData = async () => {
    try {
      const verzeichnisResponse = await api.get('/verzeichnis_alldata');
      const brResponse = await api.get('/br_alldata');
      const dsvResponse = await api.get('/dsv_alldata');
      const dsfaResponse = await api.get('/dsfa_alldata');
      const tomsResponse = await api.get('/toms_alldata');
      const dsaResponse = await api.get('/dsa_alldata');

      const verzeichnisFormatted = verzeichnisResponse.data.step1.map(
        (entry) => ({ ...entry, type: 'Eintrag ins Bearbeitungsverzeichnis' })
      );
      const brFormatted = brResponse.data.entries.map((entry) => ({
        ...entry,
        type: 'Bearbeitungsreglement',
      }));

      const dsvFormatted = dsvResponse.data.entries.map((entry) => ({
        ...entry,
        type: 'Datenschutzverletzung',
      }));

      const dsfaFormatted = dsfaResponse.data.entries.map((entry) => ({
        ...entry,
        type: 'Datenschutz-Folgenabschätzung',
      }));

      const tomsFormatted = tomsResponse.data.entries.map((entry) => ({
        ...entry,
        type: 'TOMS',
      }));

      const dsaFormatted = dsaResponse.data.map((entry) => ({
        ...entry,
        type: 'Datenschutzerklärung',
      }));

      setData(
        sortEntries([
          ...verzeichnisFormatted,
          ...brFormatted,
          ...dsvFormatted,
          ...dsfaFormatted,
          ...tomsFormatted,
          ...dsaFormatted,
        ])
      );
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchMe = async () => {
    try {
      const response = await api.get('/me');

      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchUploads = async () => {
    const verzeichnisResponse = await api.get('/verzeichnis-uploads');
    const dsvResponse = await api.get('/dsv-uploads');
    const dsfaResponse = await api.get('/dsfa-uploads');
    const brResponse = await api.get('/br-uploads');
    const advvResponse = await api.get('/advv-uploads');
    const dsaResponse = await api.get('/dsa-uploads');
    const tomsResponse = await api.get('/toms-uploads');
    const aaResponse = await api.get('/aa-uploads');
    const ghvResponse = await api.get('/ghv-uploads');

    const formattedVerzeichnis = verzeichnisResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'Verzeichnis',
      };
    });

    const formattedDsv = dsvResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'DSV',
      };
    });

    const formattedDsfa = dsfaResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'DSFA',
      };
    });

    const formattedBR = brResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'BR',
      };
    });

    const formattedAdvv = advvResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'ADVV',
      };
    });

    const formattedDsa = dsaResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'DSA',
      };
    });

    const formattedToms = tomsResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'TOMS',
      };
    });

    const formattedAa = aaResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'AA',
      };
    });

    const formattedGhv = ghvResponse.data.map((entry) => {
      return {
        ...entry,
        type: 'GHV',
      };
    });

    const result = [
      ...formattedBR,
      ...formattedDsfa,
      ...formattedDsv,
      ...formattedVerzeichnis,
      ...formattedAdvv,
      ...formattedDsa,
      ...formattedToms,
      ...formattedAa,
      ...formattedGhv,
    ];

    setUploadsData(result);
  };

  React.useEffect(() => {
    if (api && !data && !meData && !uploadsData) {
      fetchData();
      fetchMe();
      fetchUploads();
    }
  }, [api]);

  const handleDeleteUploads = async () => {
    if (!showUploadsModal.name) return;

    const options = {
      deleteSnapshots: 'include',
    };

    const blockBlobClient = containerClient.getBlockBlobClient(
      `${showUploadsModal.type}/${showUploadsModal.name}`
    );
    await blockBlobClient.deleteIfExists(options);
    await api.delete(
      `/${showUploadsModal.type.toLowerCase()}-uploads/${showUploadsModal.id}`
    );

    fetchData();
    fetchUploads();
    setShowUploadsModal(false);
  };

  const handleDownload = async (filename, type) => {
    const blockBlobClient = containerClient.getBlockBlobClient(
      `${type}/${filename}`
    );

    console.log('TYPE: ', type);
    try {
      const blobUrl = blockBlobClient.url;

      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      document.body.appendChild(anchor);

      anchor.href = blobUrl;
      anchor.download = filename;

      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleDelete = async () => {
    switch (showModal.type) {
      case 'Verzeichnis Bearbeitungstätigkeiten':
        await api.delete(`/verzeichnis_alldata/${showModal.id}`);
        break;
      case 'Bearbeitungsreglement':
        await api.delete(`/br_alldata/${showModal.id}`);
        break;
      case 'Datenschutzverletzung':
        await api.delete(`/dsv_alldata/${showModal.id}`);
        break;
      case 'Datenschutzerklarung':
        await api.delete(`/dsa_alldata/${showModal.id}`);
        break;
      case 'Datenschutz-Folgenabschätzung':
        await api.delete(`/dsfa_alldata/${showModal.id}`);
        break;
      default:
        break;
    }

    fetchData();

    setShowModal(false);
  };

  const renderTable = () => {
    if (data?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!data) {
      return <Loader />;
    }

    return <Table data={data} setShowModal={setShowModal} />;
  };

  const renderUploadsTable = () => {
    if (uploadsData?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!uploadsData) {
      return <Loader />;
    }

    return (
      <UploadsTable
        data={uploadsData}
        setShowModal={setShowUploadsModal}
        handleDownload={handleDownload}
      />
    );
  };

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar />
        <MobileNavbar />
        <div id="content" style={{ flexGrow: 1, padding: '20px' }}>
          <div className="container" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div className="card morph_variation1 padder" style={{ marginBottom: 50 }}>
              <div className="row">
                <div className="col-md-12">
                  <h4 style={{ margin: '0 0 25px 0' }}>
                    {t('dokumentePage.title')}
                  </h4>
                  <div className="card-body" style={{ overflowX: 'auto', display: 'block' }}>
                    {renderTable()}
                  </div>
                  {showModal && (
                    <DeleteModal onCancel={() => setShowModal(false)} onConfirm={() => handleDelete()} />
                  )}
                </div>
              </div>
            </div>

            <div className={`card morph_variation1 padder ${styles.overviewCard}`} style={{ gap: '25px' }}>
              <div className={styles.overviewHeadingWrapper}>
                <h4 className={styles.overviewHeading}>Dateien</h4>
              </div>
              <div style={{ overflowX: 'auto' }}>{renderUploadsTable()}</div>
              {showModal && (
                <DeleteModal onCancel={() => setShowModal(false)} onConfirm={() => handleDelete()} />
              )}
              {showUploadsModal && (
                <DeleteModal onCancel={() => setShowUploadsModal(false)} onConfirm={() => handleDeleteUploads()} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Dokumente };