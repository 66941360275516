import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { SelectDropdown, Loader } from 'components';
import { useApi } from 'services/axios';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

const BR_Step4 = () => {
  const [affected, setAffected] = React.useState(null);
  const { control, formState } = useFormContext();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const fetchData = async () => {
    try {
      const affectedResponse = await api.get('/dsv_affected');
      setAffected(affectedResponse.data);
    } catch (err) {
      console.error('Error fetching data: ', err);
    }
  };

  React.useEffect(() => {
    if (api && !affected) {
      fetchData();
    }
  }, [api]);

  return (
    <div className="card-body">
      <div className={styles.steptext}>{t('br.steps.4.description')}</div>
      <div className={styles.brInputsWrapper}>
        {!affected && <Loader />}
        {affected && (
          <Controller
            control={control}
            name="step4.affected"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                options={affected.map((individual) => {
                  return {
                    label: individual.AffectedName,
                    value: individual.AffectedId,
                  };
                })}
                label={t('br.steps.4.inputs.affected')}
                isCreatable
                isMulti
                error={formState.errors?.step4?.affected}
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                customColor="#45aaf2"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { BR_Step4 };
