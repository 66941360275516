export const getStorageEnv = () => {
  const environment = process.env.NODE_ENV;
  const domain = window.location.hostname.split('.')[0];

  switch (environment) {
    case 'development':
      return 'dev';
    case 'production':
      if (domain === 'maintain') {
        return 'test';
      }

      if (domain === 'tool') {
        return 'prod';
      }
    default:
      return 'dev';
  }
};
