import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useApi } from 'services/axios';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from 'components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { Navbar, Loader, MobileNavbar, OverviewPdf, UploadsTable } from 'components';

import EyeIcon from 'assets/images/eye-icon.svg';
import EditIcon from 'assets/images/edit-icon.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';

import styles from './styles.module.css';
import TipBar from 'components/TipBar';

const normalizeDate = (dateString) => {
  let normalizedDate = dateString;
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    normalizedDate = dateString.replace(/-/g, '.');
  }
  else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    normalizedDate = `${day}.${month}.${year}`;
  }
  return normalizedDate;
};

const Table = ({ data, setShowModal }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('VMTables.partner.partnerName'),
        accessor: 'partnerName',
      },
      {
        Header: t('VMTables.partner.partnerType'),
        accessor: 'partnerType',
      },
      {
        Header: t('overview.created'),
        accessor: 'dateCreated',
        Cell: ({ value }) => normalizeDate(value),
        sortType: (a, b) => {
          const parseDate = (dateString) => {
            const [day, month, year] = normalizeDate(dateString).split('.');
            return `${year}-${month}-${day}`;
          };
          const dateA = parseDate(a.original.dateCreated);
          const dateB = parseDate(b.original.dateCreated);
          return dateA.localeCompare(dateB);
        }
      },
      {
        Header: t('overview.edited'),
        accessor: 'lastEdited',
        Cell: ({ value }) => normalizeDate(value),
        sortType: (a, b) => {
          const parseDate = (dateString) => {
            const [day, month, year] = normalizeDate(dateString).split('.');
            return `${year}-${month}-${day}`;
          };
          const dateA = parseDate(a.original.lastEdited);
          const dateB = parseDate(b.original.lastEdited);
          return dateA.localeCompare(dateB);
        }
      },
      {
        Header: t('overview.action'),
        id: 'actions',
        Cell: ({ row }) => (
          <span className={styles.actionsWrapper}>
            <img
              src={EyeIcon}
              className={styles.tableIcon}
              onClick={() => navigate(`/PartnerOverview/${row.values.id}`)}
            />
            <img
              src={EditIcon}
              className={styles.tableIcon}
              onClick={() => navigate(`/VMEdit/${row.values.id}`)}
            />
            <img
              src={DeleteIcon}
              className={styles.tableIcon}
              onClick={() => {
                setShowModal(row.values.id);
              }}
            />
          </span>
        ),
      },
    ],
    [i18n.language]
  );

  const initialState = { hiddenColumns: ['id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState }, useSortBy);

  return (
   <table {...getTableProps()} className={`${styles.verzeichnisTable} table-hover`}>
    <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.tableHeading}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' \u2193' : ' \u2191') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tableRow}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} className={styles.tableData}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const PartnersOverview = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showUploadsModal, setShowUploadsModal] = React.useState(false);
  const [overviewData, setOverviewData] = React.useState(null);
  const [uploadsData, setUploadsData] = React.useState(null);
  const [pdfFormattedData, setPdfFormattedData] = React.useState(null);
  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      // Simulating API calls with dummy data
      const overviewResponse = {
        data: {
          partners: [
            { id: 1, partnerName: 'Partner A', partnerType: 'Client', dateCreated: '2023-05-15', lastEdited: '2023-05-20' },
            { id: 2, partnerName: 'Partner B', partnerType: 'Supplier', dateCreated: '2023-05-10', lastEdited: '2023-05-18' },
          ]
        }
      };
      const uploadsResponse = {
        data: [
          { id: 1, name: 'Partner_A.pdf', uploadDate: '2023-05-15' },
          { id: 2, name: 'Partner_B.pdf', uploadDate: '2023-05-10' },
        ]
      };

      setOverviewData(overviewResponse.data.partners);
      setUploadsData(uploadsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDelete = async () => {
    // Simulating delete API call
    console.log(`Deleting partner with ID: ${showModal}`);
    fetchData();
    setShowModal(false);
  };

  const handleDeleteUploads = async () => {
    // Simulating delete upload API call
    console.log(`Deleting upload with ID: ${showUploadsModal.id}`);
    fetchData();
    setShowUploadsModal(false);
  };

  const handleDownload = async (filename) => {
    console.log(`Downloading file: ${filename}`);
    // Implement actual download logic here
  };

  const renderTable = () => {
    if (overviewData?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!overviewData) {
      return <Loader />;
    }

    return (
      <Table
        data={overviewData}
        setShowModal={setShowModal}
      />
    );
  };

  const renderUploadsTable = () => {
    if (uploadsData?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!uploadsData) {
      return <Loader />;
    }

    return (
      <UploadsTable
        data={uploadsData}
        setShowModal={setShowUploadsModal}
        handleDownload={handleDownload}
      />
    );
  };

  React.useEffect(() => {
    if (isApiReady && !overviewData && !uploadsData) {
      fetchData();
    }
  }, [isApiReady]);

  React.useEffect(() => {
    if (overviewData) {
      setPdfFormattedData({
        columns: ['Partner Name', 'Partner Type', 'Created'],
        data: overviewData?.map((entry) => ({
          'Partner Name': entry.partnerName,
          'Partner Type': entry.partnerType,
          Created: normalizeDate(entry.dateCreated),
        })),
      });
    }
  }, [overviewData]);

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar />
        <MobileNavbar />
        <div className={styles.overviewContainer} style={{ overflow: 'auto' }} id="content">
          <div className={`card morph_variation1 padder ${styles.overviewCard}`} style={{ gap: '25px' }}>
            <div className={styles.overviewHeadingWrapper}>
              <h4 className={styles.overviewHeading}>
              {t('VMTables.partner.title')}
              </h4>
              
              <div className={styles.headingBtnWrapper}>
                <button className="btn btn-primary" onClick={() => navigate('/PartnerCreate')}>
                  {t('overview.createBtn')}
                </button>
                <PDFDownloadLink
                  document={<OverviewPdf data={pdfFormattedData} title="Partner Management Overview" />}
                  fileName="PartnerManagementOverview.pdf"
                >
                  {({ loading }) => (
                    <button className="btn btn-default">
                      {loading ? 'Loading document...' : t('overview.downloadPDF')}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            </div>
            <div style={{ overflowX: 'auto' }}>{renderTable()}</div>
            {showModal && <DeleteModal onCancel={() => setShowModal(false)} onConfirm={handleDelete} />}
          </div>
  

        </div>
      </div>
    </div>
  );
};

export { PartnersOverview };