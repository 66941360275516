import React from 'react';

import { useApi } from 'services/axios';
import { Navbar, Loader, MobileNavbar } from 'components';
import { Link, useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DetailedViewPdf } from './detailedPdf';
import { useTranslation } from 'react-i18next';
import TipBar from 'components/TipBar';


import styles from './styles.module.css';

export const formatRiskFactor = (checkboxes, name) => {
  const factor = checkboxes.find((risk) => risk.name === name);

  return factor.label;
};

const DsfaDetailedView = () => {
  const { id } = useParams();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);

  const checkboxes = t('dsfa.steps.4.inputs', { returnObjects: true });
  const LABELS = t('detailedView.dsfa.labels', { returnObjects: true });

  const fetchEntry = async () => {
    try {
      const entryResponse = await api.get(`/dsfa_alldata/${id}`);
      const companyResponse = await api.get(`/companydata`);

      setError(null);
      setData(entryResponse.data);
      setCompanyData(companyResponse.data[0]);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  const pointDisplayMapping = t('detailedView.dsfa.pointDisplayMapping', {
    returnObjects: true,
  });

  React.useEffect(() => {
    if (api && !data) {
      fetchEntry();
    }
  }, [api]);

  if (error) {
    return (
      <div className="wrapper">
        <div id="content">
          <Link
            to="/DatenschutzfolgeabschaetzungOverview"
            className={styles.detailedViewLink}
          >
            <button className="btn btn-default">
              {t('detailedView.backToOverview')}
            </button>
          </Link>
          <h2>{t('detailedView.notFound')}</h2>
        </div>
      </div>
    );
  }

  if (!data)
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar />
      <MobileNavbar />
      <div
        className={`${styles.detailedViewWrapper} morph_variation1`}
        id="content"
      >
        <div className={styles.detailedViewHeadingWrapper}>
          <div className={styles.detailedViewTitleWrapper}>
            <Link
              to="/DatenschutzfolgeabschaetzungOverview"
              className={styles.detailedViewLink}
            >
              <button className="btn btn-default">
                {t('detailedView.backToOverview')}
              </button>
            </Link>
          </div>
          <div className={styles.detailedViewLinks}>
            <Link
              to={`/Datenschutzfolgeabschaetzung/${id}`}
              className={styles.detailedViewLink}
            >
              <button className="btn btn-default">
                {t('detailedView.edit')}
              </button>
            </Link>
            <PDFDownloadLink
              document={
                <DetailedViewPdf
                  data={{ ...data, id, companyInfo: companyData }}
                />
              }
              fileName={`DSFA-${data.step1.description}`}
            >
              {({ loading }) => (
                <button className="btn btn-primary accent-darkblue-bg">
                  {loading
                    ? 'Loading document...'
                    : t('detailedView.downloadPDF')}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>

        <hr />

        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Bezeichnung</span>
            <span className={styles.mainInfoPointValue}>
              {data.step1.description}
            </span>
          </div>
        </div>

        <hr />

        <div>
        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Betroffene Daten</span>
          </div>
        </div>        
        <div className={styles.categoryPoints}>
            {Object.keys(data.step1.categories).map((point) => {
              return data.step1.categories[point]?.preset?.length > 0 ||
                data.step1.categories[point]?.custom?.length > 0 ? (
                <div className={styles.categoryPoint} key={point}>
                  <h5 className={styles.categoryPointTitle}>
                    {LABELS.step1[point]}
                  </h5>
                  <ul className={styles.categoryPointList}>
                    {data.step1.categories[point]?.preset.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                    {data.step1.categories[point]?.custom.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null;
            })}
          </div>
          <hr />

          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step2).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step2[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <hr />

        <div className={styles.gapWrapper}>
          <div className={styles.categoryPoints}>
            {Object.keys(data.step3).map((point) => {
              return data.step3[point]?.preset?.length > 0 ||
                data.step3[point]?.custom?.length > 0 ? (
                <div className={styles.categoryPoint} key={point}>
                  <h5 className={styles.categoryPointTitle}>
                    {t('detailedView.dsfa.step3.title')}
                  </h5>
                  <ul className={styles.categoryPointList}>
                    {data.step3[point]?.preset.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                    {data.step3[point]?.custom.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null;
            })}
          </div>
        </div>

        <hr />
            <div className={styles.categoryPoints}>
              <div className={styles.categoryPoint} style={{ width: '100%' }}>
                <h5 className={styles.categoryPointTitle}>
                  {t('detailedView.dsfa.step4.title')}
                </h5>
                <ul className={styles.categoryPointList}>
                  {Object.keys(data.step4).map((point) => {
                    return data.step4[point] ? (
                      <li className={styles.categoryPointListItem} key={point}>
                        {point === 'other'
                          ? data.step4[point]?.input ?? data.step4[point]
                          : formatRiskFactor(checkboxes, point)}
                      </li>
                    ) : null;
                  })}
                </ul>
            </div>
          </div>

        <hr />
        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Schadensausmass</span>
          </div>
        </div> 
        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step5).map((type) =>
              Object.keys(data.step5[type]).map((point) => (
                <div className={styles.mainInfoPoint} key={point}>
                  <span className={styles.InfoPointTitle}>{point}</span>
                  <span className={styles.InfoPointValue}>
                    {data.step5[type][point]}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>

        <hr />
        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Eintrittswahrscheinlichkeiten</span>
          </div>
        </div> 
        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step6).map((type) =>
              Object.keys(data.step6[type]).map((point) => (
                <div className={styles.mainInfoPoint} key={point}>
                  <span className={styles.InfoPointTitle}>{point}</span>
                  <span className={styles.InfoPointValue}>
                    {data.step6[type][point]}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>

        <hr />
        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Bestehende Massnahmen</span>
          </div>
        </div> 
        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step7).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.InfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step7[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <hr />
        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Geplante Massnahmen</span>
          </div>
        </div> 
        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step8).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.InfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step8[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <hr />
            <div>
              <div className={styles.gapdates}>
                <span>{t('detailedView.dateCreated')}</span><span>{data.dateCreated.replace(/-/g, '.')}</span><br>
                </br>
                <span>{t('detailedView.lastEdited')}</span><span>{data.lastEdited.replace(/-/g, '.')}</span>
              </div>          
            </div>
      </div>
    </div>
    </div>
  );
};

export { DsfaDetailedView };
