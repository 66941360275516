import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown, Loader, Input } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'helpers/validation';

const Dsfa_Step1 = () => {
  const [categories, setCategories] = React.useState(null);
  const [duplicateError, setDuplicateError] = React.useState(''); // State to hold error message
  const { api } = useApi();
  const { control, formState, getValues, setError, clearErrors } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const fetchData = async () => {
    try {
      const responseCategories = await api.get('/dsfa_categories');
      const responseSubcategories = await api.get('/dsfa_subcategories');

      const categoriesWithSubcategories = responseCategories.data.map(
        (category) => {
          const subcategories = responseSubcategories.data.filter(
            (subCategory) => subCategory.CategoryId === category.CategoryId
          );
          return { ...category, Subcategories: subcategories };
        }
      );

      setCategories(categoriesWithSubcategories);
    } catch (err) {
      console.error('Error fetching data: ', err);
    }
  };

  React.useEffect(() => {
    if (api && !categories) {
      fetchData();
    }
  }, [api]);

  const validateUniqueSelection = (currentValue, currentName) => {
    const allValues = getValues()?.step1?.categories || {};

    // Collect all selected subcategory values from all categories except the current one
    const allSelectedValues = Object.entries(allValues)
      .filter(([name]) => name !== currentName) // exclude the current category being validated
      .flatMap(([_, categoryValue]) => {
        // Extract values from the 'custom' key, if they exist
        if (categoryValue && Array.isArray(categoryValue.custom)) {
          return categoryValue.custom.map((item) => item.label); // Use the label for comparison
        }
        return [];
      });

    // Ensure currentValue is an array before checking for duplicates
    const currentSelectionArray = Array.isArray(currentValue)
      ? currentValue.map((item) => item.label) // Extract labels for comparison
      : [];

    // Combine all values (current selection + other selected values)
    const combinedValues = [...allSelectedValues, ...currentSelectionArray];

    // Create a Set to check for duplicates (since Sets only store unique values)
    const uniqueValuesSet = new Set(combinedValues);

    const hasDuplicates = combinedValues.length !== uniqueValuesSet.size;

    if (hasDuplicates) {
      setDuplicateError(t('duplicateCategoryError')); // Set error message in state
      setError('step1.categories', {
        type: 'manual',
        message: t('duplicateCategoryError'),
      }); // Trigger a form error
      return false;
    } else {
      setDuplicateError(''); // Clear error message if no duplicates
      clearErrors('step1.categories');
      return true;
    }
  };

  return (
    <div className="card-body">
      <h4>{t('dsfa.steps.1.title')}</h4>

      <div className={styles.dsfaInputsWrapper} style={{ marginTop: '40px' }}>
        <Controller
          control={control}
          name={`step1.description`}
          rules={{ ...validation.required }}
          defaultValue=""
          render={({ field }) => {
            return (
              <Input
                {...field}
                label="Bezeichnung des Eintrages"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={formState.errors?.step1?.description}
              />
            );
          }}
        />
        <div style={{ marginTop: '40px', marginBottom: '20px' }}>
          {t('dsfa.steps.1.description')}
        </div>
        {!categories && <Loader />}
        {categories &&
          categories.map((category, idx) => (
            <Controller
              key={idx}
              name={`step1.categories.${category.CategoryName.replace(
                /[,\s-]/g,
                ''
              )}`}
              control={control}
              rules={{
                validate: (value) =>
                  validateUniqueSelection(
                    value,
                    `step1.categories.${category.CategoryName.replace(
                      /[,\s-]/g,
                      ''
                    )}`
                  ),
              }}
              defaultValue={null}
              render={({ field }) => (
                <SelectDropdown
                  options={category.Subcategories.map((subcategory) => {
                    return {
                      label: subcategory.SubcategoryName,
                      value: subcategory.SubcategoryId,
                    };
                  })}
                  label={category.CategoryName}
                  isMulti
                  isCreatable
                  value={field.value}
                  onChange={field.onChange}
                  name={field.name}
                  customColor="#4b7bec"
                />
              )}
            />
          ))}
      </div>

      {/* Display error message at the bottom if duplicates are detected */}
      {duplicateError && (
        <div style={{ color: 'red', marginTop: '20px' }}>
          {duplicateError}
        </div>
      )}

      <span className={styles.errorMsg}>
        {formState.errors?.step1?.ArbeitsundBerufsdaten?.message}
      </span>
    </div>
  );
};

export { Dsfa_Step1 };