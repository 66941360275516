import React from 'react';
import { Textarea } from 'components';
import { useValidation } from 'helpers/validation';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Dsfa_Step2 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const INPUTS = t('dsfa.steps.2.inputs', { returnObjects: true });

  return (
    <div className="card-body">
      <div className={styles.steptext}>{t('dsfa.steps.2.description')}</div>
      <div className={styles.dsfaInputsWrapper}>
        {INPUTS.map((input) => (
          <Controller
            key={input.id}
            control={control}
            name={`step2.${input.name}`}
            rules={{ ...validation.required }}
            render={({ field }) => {
              return (
                <Textarea
                  {...input}
                  {...field}
                  error={formState.errors?.step2?.[input.name]}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { Dsfa_Step2 };
