import React from 'react';
import logo from '../assets/images/Logo_Red_New.png';
import { NavLink, Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LanguageSelect } from './LanguangeSelect';
import { useTranslation } from 'react-i18next';
import { useApi } from 'services/axios';
import smslogo from '../assets/images/SMS-Logo.png';

function Navbar({ shouldRefetch = false }) { // Standardwert für shouldRefetch
  const [meData, setMeData] = React.useState(null);
  const [viewingAs, setViewingAs] = React.useState(null);
  const { instance } = useMsal();
  const { t } = useTranslation('common');
  const { api } = useApi();

  // if (shouldRefetch !== undefined) { // Bedingung vor der Konsolenausgabe
  //   console.log('Should refetch: ', shouldRefetch);
  // }

  const fetchMe = React.useCallback(async () => {
    try {
      if (!api) return;
      const response = await api.get('/me');
      if (response.data.isAdmin) {
        const viewingAs = await api.get('/selected-user');
        setViewingAs(viewingAs.data);
      }
      setMeData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [api]);

  const handleLogout = () => {
    instance.logoutPopup();
  };

  React.useEffect(() => {
    if (api) {
      fetchMe();
    }
  }, [api, fetchMe]);

  React.useEffect(() => {
    if (shouldRefetch) {
      fetchMe();
    }
  }, [shouldRefetch, fetchMe]);

  if (!api) {
    return null; // oder eine Ladeanzeige
  }

  return (
    <nav id="sidebar" className="neumorphic-sidebar">
      <div>
        <div className="sidebar-header">
          {/* <Link to="/Programme">
              <img
                src={logo}
                width="240px"
                style={{ marginTop: '24px', marginBottom: '24px' }}
              />
            </Link> */}
        </div>
        {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center align horizontally
              marginBottom: '0px',
            }}
          >
            <a
              href="https://www.swissmadesoftware.org/en/home.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={smslogo} width="140px" />
            </a>
          </div> */}
       
        {/* <br></br><br></br>
         <hr style={{ marginBottom: '24px' }}></hr> */}

        <ul className="list-unstyled components">


          <li className="active nav-item">
            <NavLink to="/Programme" className="neumorphic-link nav-link">
                <img
                  src="/OverviewIcon.svg"
                  style={{ width: '22px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.home')}</span>
            </NavLink>
          </li>






          <li className="active nav-item">
            <NavLink to="/StandortbestimmungOverview" className="neumorphic-link nav-link">
                <img
                  src="/icon-sb.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.sb')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink
              to="/VerzeichnisOverview"
              className="neumorphic-link nav-link"
            >
                <img
                  src="/icon-verzeichnis.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
              <span className="nav-text">{t('navbar.verzeichnis')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink to="/DsaOverview" className="neumorphic-link nav-link">
                <img
                  src="/icon-dse.svg"
                  style={{ width: '22px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.dse')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink to="/TomsOverview" className="neumorphic-link nav-link">
                <img
                  src="/icon-tom.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.toms')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink to="/advv" className="neumorphic-link nav-link">
                <img
                  src="/icon-advv.svg"
                  style={{ width: '22px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.advv')}</span>
            </NavLink>
          </li>
          
          <li className="active nav-item">
            <NavLink to="/ghv" className="neumorphic-link nav-link">
                <img
                  src="/icon-ghv.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.ghv')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink to="/BROverview" className="neumorphic-link nav-link">
                <img
                  src="/icon-br.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.br')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink
              to="/DatenschutzfolgeabschaetzungOverview"
              className="neumorphic-link nav-link"
            >
                <img
                  src="/icon-dsfa.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.dsfa')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink
              to="/DatenschutzverletzungOverview"
              className="neumorphic-link nav-link"
            >
                <img
                  src="/icon-dsv.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.dsv')}</span>
            </NavLink>
          </li>

          <li className="active nav-item">
            <NavLink to="/aa" className="neumorphic-link nav-link">
                <img
                  src="/icon-aa.svg"
                  style={{ width: '18px', marginBottom: '0px' }}
                />
                <span className="nav-text">{t('navbar.aa')}</span>
            </NavLink>
          </li>

        </ul>
        <hr style={{ marginTop: '24px', marginBottom: '0px' }}></hr>

        <hr style={{ marginBottom: '24px' }}></hr>
        {meData?.isAdmin && (
          <>
            <ul className="list-unstyled components">
              <li className="active nav-item">
                <NavLink to="/AdminPanel" className="neumorphic-link nav-link">
                    <i
                      className="fa-solid fa-users"
                      style={{
                        color: '#bdc3c7',
                        fontSize: '20px',
                        marginBottom: '0px',
                      }}
                    ></i>
                    <span className="nav-text">{'Account wechseln'}</span>
                </NavLink>
              </li>

              <li className="active nav-item">
                <NavLink to="/VMOverview" className="neumorphic-link nav-link">
                    <i
                      className="fa-solid fa-address-card"
                      style={{
                        color: '#bdc3c7',
                        fontSize: '20px',
                        marginBottom: '0px',
                      }}
                    ></i>
                    <span className="nav-text">{'Vertragsmanagement'}</span>
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </div>
    </nav>
  );
}

export { Navbar };
