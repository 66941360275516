import React from 'react';
import { Input } from 'components';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const BR_Step2 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const INPUTS = t('br.steps.2.inputs', { returnObjects: true });

  return (
    <div className="card-body">
      <div className={styles.steptext}>{t('br.steps.2.description')}</div>
      <div className={styles.brInputsWrapper}>
        {INPUTS.map((input) => (
          <Controller
            key={input.id}
            control={control}
            name={`step2.${input.name}`}
            rules={{ ...validation.required }}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...input}
                {...field}
                error={formState.errors?.step2?.[input.name]}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export { BR_Step2 };
