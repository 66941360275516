import React from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthenticated) {
      return navigate('/');
    }
  }, [isAuthenticated]);

  return <Outlet />;
};

export { ProtectedRoute };
