import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Tooltip } from 'react-tooltip';
import { FormField } from '../FormField';

import styles from './styles.module.css';

const dropdownStyles = (customColor) => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    border: 'solid #D4D4D4 1px',
    backgroundColor: '#FDFDFD',
    fontSize: 14,
    // boxShadow:
    //   'inset -3px -3px 6px rgba(255, 255, 255, 0.5), inset 3px 3px 6px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    height: 20,
    width: 20,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#ffffff',
    zIndex: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? customColor ?? '#D33C2E' : '#000',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: customColor ?? '#D33C2E',
      color: '#fff',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '6px 8px',
  }),
});

const SelectDropdown = ({
  options,
  label,
  placeholder = 'Auswählen...',
  name,
  isMulti,
  isCreatable,
  value,
  onChange,
  error,
  tooltipText,
  customColor,
}) => {
  const handleChange = (selectedOptions, action) => {
    if (!isMulti) {
      onChange(selectedOptions);
      return;
    }

    if (action.action === 'remove-value' || action.action === 'pop-value') {
      const removedOption = action.removedValue;
      const presetOptions = value?.preset?.filter(
        (option) => option.value !== removedOption.value
      );
      const customOptions = value?.custom?.filter(
        (option) => option.label !== removedOption.label
      );
      onChange({ preset: presetOptions, custom: customOptions });
      return;
    }

    if (action.action === 'clear') {
      onChange({ preset: [], custom: [] });
      return;
    }

    console.log('selectedOptions: ', selectedOptions);

    const presetOptions = selectedOptions.filter((option) => !option.isNew);
    const customOptions = selectedOptions.filter((option) => option.isNew);

    const defaultOptions = presetOptions.filter(
      (option) =>
        !value?.custom?.some(
          (customOption) => customOption.label === option.label
        )
    );

    const updatedCustomOptions = value?.custom
      ? customOptions.concat(value?.custom?.filter((option) => !option.isNew))
      : [];

    onChange({ preset: defaultOptions, custom: updatedCustomOptions });
  };

  const handleCreateOption = (inputValue) => {
    const customOption = { label: inputValue };
    onChange({
      preset: value?.preset ?? [],
      custom: value?.custom ? [...value.custom, customOption] : [customOption],
    });
  };

  const tooltipAnchor = `tooltipAnchor${name.split('.').pop()}`;

  return (
    <FormField error={error}>
      <div className={styles.selectWrapper}>
        {label && (
          <div className={styles.labelWrapper}>
            <span className={styles.selectLabel}>{label}</span>
            {tooltipText && (
              <>
                <span className={`${styles.tooltipWrapper} ${tooltipAnchor}`}>
                  ?
                </span>
                <Tooltip anchorSelect={`.${tooltipAnchor}`} place="top">
                  {tooltipText}
                </Tooltip>
              </>
            )}
          </div>
        )}
        {isCreatable ? (
          <CreatableSelect
            options={options}
            placeholder={placeholder}
            name={name}
            isMulti={isMulti}
            styles={dropdownStyles(customColor)}
            value={
              isMulti ? (value?.preset ?? []).concat(value?.custom) : value
            }
            onChange={handleChange}
            onCreateOption={handleCreateOption}
            onRemove
          />
        ) : (
          <Select
            options={options}
            placeholder={placeholder}
            name={name}
            isMulti={isMulti}
            styles={dropdownStyles(customColor)}
            value={value}
            onChange={onChange}
          />
        )}
      </div>
    </FormField>
  );
};

export { SelectDropdown };
