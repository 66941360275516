import React from 'react';

import { useApi } from 'services/axios';
import { Navbar, Loader, MobileNavbar } from 'components';
import { Link, useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DetailedViewPdf } from './detailedViewPdf';
import { useTranslation } from 'react-i18next';
import TipBar from 'components/TipBar';


import styles from './styles.module.css';

const DatenschutzverletzungDetailedView = () => {
  const { id } = useParams();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);
  const pointDisplayMapping = t('detailedView.dsv.pointDisplayMapping', {
    returnObjects: true,
  });
  const LABELS = t('detailedView.dsv.labels', { returnObjects: true });

  const fetchEntry = async () => {
    try {
      const entryResponse = await api.get(`/dsv_alldata/${id}`);
      const companyResponse = await api.get(`/companydata`);

      setError(null);
      setData(entryResponse.data);
      setCompanyData(companyResponse.data[0]);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api && !data && !error) {
      fetchEntry();
    }
  }, [api]);

  if (error) {
    return (
      <div className="wrapper">
        <div id="content">
          <Link
            to="/DatenschutzverletzungOverview"
            className={styles.detailedViewLink}
          >
            <button className="btn btn-default">
              {t('detailedView.backToOverview')}
            </button>
          </Link>
          <h2>{t('detailedView.notFound')}</h2>
        </div>
      </div>
    );
  }

  if (!data || companyData === null)
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar />
      <MobileNavbar />
      <div
        className={`${styles.detailedViewWrapper} morph_variation1`}
        id="content"
      >
        <div className={styles.detailedViewHeadingWrapper}>
          <div className={styles.detailedViewTitleWrapper}>
            <Link
              to="/DatenschutzverletzungOverview"
              className={styles.detailedViewLink}
            >
              <button className="btn btn-default">
                {t('detailedView.backToOverview')}
              </button>
            </Link>
          </div>

          <div className={styles.detailedViewLinks}>
            <Link
              to={`/Datenschutzverletzung/${id}`}
              className={styles.detailedViewLink}
            >
              <button className="btn btn-default">
                {t('detailedView.edit')}
              </button>
            </Link>

            <PDFDownloadLink
              document={
                <DetailedViewPdf
                  data={{ ...data, id, companyInfo: companyData }}
                />
              }
              fileName={`dsvEntry-${id}`}
            >
              {({ loading }) => (
                <button className="btn btn-primary accent-purple-bg">
                  {loading
                    ? t('detailedView.loadingDocument')
                    : t('detailedView.downloadPDF')}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>

        <hr />

        <div className={styles.mainInfoPoints}>
          <div className={styles.mainInfoPoint}>
            <span className={styles.mainInfoPointTitle}>Bezeichnung der Datenschutzverletzung</span>
            <span className={styles.mainInfoPointValue}>{data.step1.description}
            </span>
          </div>
        </div> 

        <hr />

        <div className={styles.gapWrapper}>
          {Object.keys(data.step1).map((point) => {
            const displayString = pointDisplayMapping[point] || '';
            return typeof data.step1[point] === 'string' ? (
              <div className={styles.mainInfoPoints} key={point}>

              </div>
            ) : data.step1[point]?.preset ? (
              <div className={styles.categoryPoints} key={point}>
                <div className={styles.categoryPoint}>
                  <h5 className={styles.categoryPointTitle}>{displayString}</h5>
                  <ul className={styles.categoryPointList}>
                    {data.step1[point].preset.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                    {data.step1[point].custom.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div className={styles.mainInfoPoints} key={point}>
                <div className={styles.mainInfoPoint}>
                  <span className={styles.mainInfoPointTitle}>{point}</span>
                  <span className={styles.InfoPointValue}>
                    {data.step1[point].label}
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <hr />

        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step2).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step2[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.categoryPoints}>
            {Object.keys(data.step3).map((point) => {
              return data.step3[point]?.preset?.length > 0 ||
                data.step3[point]?.custom?.length > 0 ? (
                <div className={styles.categoryPoint} key={point}>
                  <h5 className={styles.categoryPointTitle}>
                    {LABELS.step3[point]}
                  </h5>
                  <ul className={styles.categoryPointList}>
                    {data.step3[point]?.preset.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                    {data.step3[point]?.custom.map((obj, i) => (
                      <li className={styles.categoryPointListItem} key={i}>
                        {obj.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null;
            })}
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step4).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step4[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step5).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step5[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step6).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with your default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step6[point] == 'no' ? 'Nein' : 'Ja'}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <hr />

        <div>
          <div className={styles.mainInfoPoints}>
            {Object.keys(data.step7).map((point) => {
              const displayString = pointDisplayMapping[point] || ''; // replace '' with our default string if needed
              return (
                <div className={styles.mainInfoPoint} key={point}>
                  {/* <span className={styles.mainInfoPointTitle}>{point}</span> */}

                  <span className={styles.mainInfoPointTitle}>
                    {displayString}
                  </span>
                  <span className={styles.InfoPointValue}>
                    {data.step7[point]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          
        <hr />

              <div className={styles.gapdates}>
                <span>{t('detailedView.dateCreated')}</span><span>{data.dateCreated.replace(/-/g, '.')}</span><br>
                </br>
                <span>{t('detailedView.lastEdited')}</span><span>{data.lastEdited.replace(/-/g, '.')}</span>
              </div>          
            </div>
      </div>
    </div>
    </div>
  );
};

export { DatenschutzverletzungDetailedView };
