import React from 'react';
import { Navbar } from 'components';
import { useTranslation } from 'react-i18next';

const Einstellungen = () => {
  const { t } = useTranslation('common');

  return (
    <div className="wrapper">
      <Navbar />
      <div id="content">
        <div className="container" style={{ width: '100%' }}>
          <div className="row topmargin100">
            <div className="col-md-2"></div>
            <div className="card col-md-8">
              <div className="card-body morph_variation1 padder">
                <i
                  className="fa-solid fa-gear fa-5x"
                  style={{ color: '#c0392b' }}
                ></i>

                <h3>{t('optionsPage.title')}</h3>

                <p
                  className="text-center"
                  style={{ margin: '20px 0px 40px 0px' }}
                >
                  {t('optionsPage.desc')}
                </p>

                <label
                  for="Username"
                  style={{
                    marginBottom: '-12px',
                    marginTop: '12px',
                    textAlign: 'left',
                  }}
                >
                  {t('optionsPage.name')}
                </label>
                <input
                  type="text"
                  readOnly
                  className="form-control morphfield"
                  id="exampleInputEmail67"
                  aria-describedby="emailHelp"
                />

                <label
                  for="Username"
                  style={{
                    marginBottom: '-12px',
                    marginTop: '12px',
                    textAlign: 'left',
                  }}
                >
                  {t('optionsPage.password')}
                </label>
                <input
                  type="text"
                  className="form-control morphfield"
                  id="exampleInputEmail67"
                  aria-describedby="emailHelp"
                />

                <label for="Username" style={{ marginBottom: '-12px' }}>
                  {t('optionsPage.confirmPassword')}
                </label>
                <input
                  type="text"
                  className="form-control morphfield"
                  id="exampleInputEmail67"
                  aria-describedby="emailHelp"
                />

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      marginBottom: '10px',
                      marginTop: '16px',
                      width: '200px',
                    }}
                  >
                    {t('optionsPage.save')}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Einstellungen };
