import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown } from 'components/SelectDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { Loader } from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'helpers/validation';
import { RadioGroup } from 'components/RadioGroup';

import styles from './styles.module.css';

const Verzeichnis_Step2 = () => {
  const { api } = useApi();
  const [categories, setCategories] = React.useState(null);
  const [duplicateError, setDuplicateError] = React.useState(''); // State for duplicate error message
  const { control, formState, getValues, setError, clearErrors } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const options = t('radioOptions', { returnObjects: true });

  const fetchData = async () => {
    try {
      const responseCategories = await api.get('/dsfa_categories');
      const responseSubcategories = await api.get('/dsfa_subcategories');

      const categoriesWithSubcategories = responseCategories.data.map(
        (category) => {
          const subcategories = responseSubcategories.data.filter(
            (subCategory) => subCategory.CategoryId === category.CategoryId
          );
          return { ...category, Subcategories: subcategories };
        }
      );

      setCategories(categoriesWithSubcategories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    if (api && !categories) {
      fetchData();
    }
  }, [api]);

  const validateUniqueSelection = (currentValue, currentName) => {
    const allValues = getValues()?.step2 || {};

    // Collect all selected subcategory values from all categories except the current one
    const allSelectedValues = Object.entries(allValues)
      .filter(([name]) => name !== currentName) // Exclude the current category being validated
      .flatMap(([_, categoryValue]) => {
        // Extract values from the 'custom' key, if they exist
        if (categoryValue && Array.isArray(categoryValue.custom)) {
          return categoryValue.custom.map((item) => item.label); // Use the label for comparison
        }
        return [];
      });

    // Ensure currentValue is an array before checking for duplicates
    const currentSelectionArray = Array.isArray(currentValue)
      ? currentValue.map((item) => item.label) // Extract labels for comparison
      : [];

    // Combine all values (current selection + other selected values)
    const combinedValues = [...allSelectedValues, ...currentSelectionArray];

    // Create a Set to check for duplicates (since Sets only store unique values)
    const uniqueValuesSet = new Set(combinedValues);

    const hasDuplicates = combinedValues.length !== uniqueValuesSet.size;

    if (hasDuplicates) {
      setDuplicateError(t('duplicateCategoryError')); // Set error message in state
      setError('step2.categories', {
        type: 'manual',
        message: t('duplicateCategoryError'),
      }); // Trigger a form error
      return false;
    } else {
      setDuplicateError(''); // Clear error message if no duplicates
      clearErrors('step2.categories');
      return true;
    }
  };

  const validateFields = () => {
    const step2Values = getValues()?.step2;

    if (!step2Values) {
      return 'Mindestens ein Feld ist erforderlich';
    }

    const isAnyFieldFilled = Object.values(step2Values).some(
      (fieldValues) =>
        fieldValues !== null &&
        (typeof fieldValues === 'string' || // For radio buttons
          (Array.isArray(fieldValues) && fieldValues.length > 0)) // For multi-selects
    );

    return isAnyFieldFilled || 'Mindestens ein Feld ist erforderlich';
  };

  return (
    <div className="card-body">
      <div className={styles.verzeichnisInputsWrapper}>
        <h4>{t('verzeichnis.steps.2.schuetzenswert')}</h4>
        <div style={{ fontSize: '14px' }}>
        {t('verzeichnis.steps.2.schuetzenswert_description')
          .split('\n')
          .map((line, index) => (
            <p key={index} style={{ margin: '4px 0' }}>{line}</p>  
          ))}
      </div>

        <Controller
          name="step2.schuetzenswert"
          control={control}
          rules={{
            ...validation.required,
            validate: validateFields,
          }}
          render={({ field }) => (
            <RadioGroup
              label={''}
              name="schuetzenswert"
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step2?.schuetzenswert}
            />
          )}
        />

        <h4 className="text-lg font-medium mb-1" style={{ marginTop: '50px' }}>
          {t('verzeichnis.steps.2.title')}
        </h4>
        <p className="text-sm text-gray-500 mb-2">{t('verzeichnis.steps.2.description')}</p>

        {!categories && <Loader />}
        {categories &&
          categories.map((category) => (
            <Controller
              key={category.CategoryId}
              name={`step2.${category.CategoryName.replace(/[,\s-]/g, '')}`}
              control={control}
              rules={{ validate: (value) => validateUniqueSelection(value, `step2.${category.CategoryName.replace(/[,\s-]/g, '')}`) }}
              defaultValue={null}
              render={({ field }) => (
                <SelectDropdown
                  options={category.Subcategories.map((subcategory) => ({
                    label: subcategory.SubcategoryName,
                    value: subcategory.SubcategoryId,
                  }))}
                  label={category.CategoryName}
                  isMulti
                  isCreatable
                  value={field.value}
                  onChange={field.onChange}
                  name={field.name}
                />
              )}
            />
          ))}
      </div>

      {/* Display error message at the bottom if duplicates are detected */}
      {duplicateError && (
        <div style={{ color: 'red', marginTop: '20px' }}>
          {duplicateError}
        </div>
      )}

      <span className={styles.errorMsg}>
        {formState.errors?.step2?.ArbeitsundBerufsdaten?.message}
      </span>
    </div>
  );
};

export { Verzeichnis_Step2 };