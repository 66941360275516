import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Slider } from 'components/Slider';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const DSV_Step5 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const sliders = t('dsv.steps.5.inputs', { returnObjects: true });

  return (
    <div className="card-body">
      <h3 style={{ marginBottom: '30px' }}>Risikoeinschätzung</h3>
      <div className={`${styles.dsvInputsWrapper} ${styles.dsvSlidersWrapper}`}>
        {sliders.map((slider) => (
          <Controller
            key={slider.name}
            control={control}
            name={`step5.${slider.name}`}
            defaultValue={null}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <Slider
                marks={slider.marks}
                min={slider.min}
                max={slider.max}
                title={slider.label}
                error={formState.errors?.step5?.[slider?.name]}
                handleStyle={{
                  backgroundColor: '#a55eea',
                  borderColor: '#a55eea',
                }}
                {...field}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export { DSV_Step5 };
