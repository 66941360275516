import React, { useState, useEffect } from "react";
import { useApi } from "services/axios";
import styles from "./styles.module.css";
import detailedStyles from "../../Toms/TomsDetailedView/styles.module.css";
import { TITLES } from "Programme/Toms/TomsDetailedView";
import { Loader } from "components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Dsa_Step6 = () => {
  const [tomsEntries, setTomsEntries] = useState(null);
  const [tomsEntriesDetails, setTomsEntriesDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tomsAvailable, setTomsAvailability] = useState(true);

  const { t } = useTranslation("common");
  const { register, formState, watch, setValue } = useFormContext();
  const [error, setFetchError] = useState(null);
  const { api } = useApi();

  const showTomsValue = watch("step6.showToms");

  const fetchData = React.useCallback(async () => {
    if (!api) return;
    setIsLoading(true);
    try {
      const tomsResponse = await api.get(`/toms_alldata`);
      setTomsEntries(tomsResponse.data.entries);
      fetchTomsDetailsData(tomsResponse.data.entries);
    } catch (err) {
      console.error("Error fetching entry: ", err);
      setFetchError(err);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const fetchDetails = React.useCallback(
    async (id) => {
      if (!api) return;
      if (id) {
        try {
          const detailsResponse = await api.get(`/toms_alldata/${id}`);
          if (
            tomsEntriesDetails &&
            tomsEntriesDetails.find((entry) => entry.id === id)
          )
            return;
          setTomsEntriesDetails((prev) => [
            ...(prev ?? []),
            { ...detailsResponse.data, id },
          ]);
        } catch (err) {
          console.error(`Error fetching details for ID ${id}: `, err);
        }
      }
    },
    [api, tomsEntriesDetails]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchTomsDetailsData = (entries) => {
    if (entries && entries.length === 0) {
      setTomsEntriesDetails([]);
      return;
    }
    if (entries) {
      entries.forEach((entry) => fetchDetails(entry.id));
    }
  };

  useEffect(() => {
    if (
      (tomsEntries && tomsEntries.length === 0) ||
      (tomsEntriesDetails && tomsEntriesDetails.length === 0)
    ) {
      setTomsAvailability(false);
      setValue("step6.showToms", "standard");
    } else {
      setTomsAvailability(true);
    }
  }, [tomsEntries, tomsEntriesDetails, setValue]);

  const InputItem = () => (
    <input
      {...register("step6.showToms", {
        required: t("validation.required"),
      })}
      type="radio"
      value="toms"
      disabled={!tomsAvailable}
    />
  );

  return (
    <div className="card-body">
      <h4>{t("dsa.steps.6.title")}</h4>
      <div className={styles.dsaInputsWrapper} style={{ marginTop: "20px" }}>
        <div>
          <p>{t("dsa.steps.6.description")}</p>
          <label>
            <input
              {...register("step6.showToms", {
                required: t("validation.required"),
              })}
              type="radio"
              value="standard"
            />
            {t("dsa.steps.6.useStandard")}
          </label>
          <br />
          <label>
            <InputItem />
            {t("dsa.steps.6.useTOMs")}
          </label>
          {!tomsAvailable && (
            <p style={{ color: "red" }}>{t("dsa.steps.6.notfound")}</p>
          )}
        </div>

        {showTomsValue === "standard" && tomsAvailable && (
          <div>
            <p>{t("dsa.steps.6.standardText")}</p>
          </div>
        )}

        {showTomsValue === "toms" && tomsAvailable && (
          <>
            {isLoading && <Loader />}
            {!isLoading && error && <p>Error: {error.message}</p>}
            {!isLoading && tomsEntriesDetails && tomsEntriesDetails.length > 0 && (
              <div>
                <div className={detailedStyles.categoryPoints}>
                  {tomsEntriesDetails.map((entry) => (
                    <React.Fragment key={entry.id}>
                      <span>ID: {entry.id}</span>

                      <label htmlFor={`tomsEntry-${entry.id}`}>
                        <input
                          {...register("step6.selectedTomsId", {
                            required: t("validation.required"),
                          })}
                          type="radio"
                          value={entry.id}
                          id={`toms-${entry.id}`}
                          style={{
                            accentColor: "rgb(165, 94, 234)",
                            marginRight: "5px",
                          }}
                        />
                        {t("dsa.steps.6.useThisTOMsEntry")}
                      </label>

                      {Object.keys(entry).map((step, idx) => {
                        return Array.isArray(
                          entry[step]?.[Object.keys(entry[step] ?? {})[0]]
                        ) ? (
                          <div style={{ width: "100%" }} key={idx}>
                            <h5 className={detailedStyles.categoryPointTitle}>
                              {TITLES[idx]}
                            </h5>

                            <ul className={detailedStyles.categoryPointList}>
                              {Object.keys(entry[step]).map((key) => {
                                return entry[step][key].map((obj, i) => (
                                  <li
                                    className={
                                      detailedStyles.categoryPointListItem
                                    }
                                    key={i}
                                  >
                                    {obj.label}
                                  </li>
                                ));
                              })}
                            </ul>
                          </div>
                        ) : null;
                      })}
                      <div
                        className={detailedStyles.mainInfoPoints}
                        style={{ width: "100%" }}
                      >
                        <div className={detailedStyles.mainInfoPoint}>
                          <span className={detailedStyles.mainInfoPointTitle}>
                            Weitere Massnahmen
                          </span>
                          <span className={detailedStyles.mainInfoPointValue}>
                            {entry.step12.furtherMeasures}
                          </span>
                        </div>
                        <div className={detailedStyles.mainInfoPoint}>
                          <span className={detailedStyles.mainInfoPointTitle}>
                            Zertifizierungen
                          </span>
                          <span className={detailedStyles.mainInfoPointValue}>
                            {entry.step12.certificates}
                          </span>
                        </div>
                      </div>
                      <hr style={{ marginBottom: "20px" }} />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step6?.showToms?.message ||
          formState.errors?.step6?.selectedTomsId?.message}
      </span>
    </div>
  );
};

export { Dsa_Step6 };