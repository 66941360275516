import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Textarea } from 'components';

import styles from './styles.module.css';

const Toms_Step12 = () => {
  const { control, formState } = useFormContext();
  const validation = useValidation();

  return (
    <div className="card-body">
      <div className={styles.steptext}></div>
      <div className={styles.tomsInputsWrapper}>
        <Controller
          control={control}
          name="step12.furtherMeasures"
          rules={{ ...validation.required }}
          render={({ field }) => {
            return (
              <Textarea
                label="Weitere Massnahmen"
                error={formState.errors?.step12?.furtherMeasures}
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="step12.certificates"
          rules={{ ...validation.required }}
          render={({ field }) => {
            return (
              <Textarea
                label="Zertifizierungen"
                error={formState.errors?.step12?.certificates}
                {...field}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export { Toms_Step12 };
