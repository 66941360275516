import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const RadioGroup = ({ label, description, options, field, error }) => (
  <div className={styles.radioGroup}>
    <label>{label}</label>
    {description}
    <p></p>
    <div>
      {options.map((option) => (
        <div key={option.value} className={styles.radioOption}>
          <label>
            <input
              type="radio"
              value={option.value}
              checked={field.value === option.value}
              onChange={() => field.onChange(option.value)}
              onBlur={field.onBlur}
            />
            {option.label}
          </label>
        </div>
      ))}
    </div>
    {error && <span className={styles.error}>{error.message}</span>}
  </div>
);

const Standortbestimmung_Step4 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const INPUTS = [
    {
      label: t('sb.steps.4.inputs.auftragsbearbeitung'),
      description: t('sb.steps.4.inputs.auftragsbearbeitung_description'),
      name: 'hiringprocessor',
      tooltipText: t('sb.steps.4.inputs.auftragsbearbeitungTooltip'),
      options: [
        { value: 1, label: t('sb.steps.4.inputs.auftragsbearbeitung0') },
        { value: 2, label: t('sb.steps.4.inputs.auftragsbearbeitung1') },
        //value 1 = keine Folgen
        //value 2 = Keine ADVs notwendig (nur wenn nachfolgender Schritt auch 2)
      ],
    },
    {
      label: t('sb.steps.4.inputs.anbieter_auftragsbearbeitung'),
      description: t(
        'sb.steps.4.inputs.anbieter_auftragsbearbeitung_description'
      ),
      name: 'beinprocessor',
      tooltipText: t('sb.steps.4.inputs.anbieter_auftragsbearbeitungTooltip'),
      options: [
        {
          value: 1,
          label: t('sb.steps.4.inputs.anbieter_auftragsbearbeitung0'),
        },
        {
          value: 2,
          label: t('sb.steps.4.inputs.anbieter_auftragsbearbeitung1'),
        },
        //value 1 = keine Folgen
        //value 2 = Keine ADVs notwendig (nur wenn vorheriger Schritt auch 2)
      ],
    },
  ];

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        <h4>{t('sb.steps.4.title')}</h4>
      </div>
      <div className={styles.sbInputsWrapper}>
        {INPUTS.map((input) => (
          <Controller
            key={input.name}
            control={control}
            name={`step4.${input.name.toLowerCase()}`}
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <RadioGroup
                label={input.label}
                description={input.description}
                options={input.options}
                field={field}
                error={formState.errors?.step4?.[input.name.toLowerCase()]}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export { Standortbestimmung_Step4 };
