import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const GhvRequest = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const handleDownload = (filename) => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/${filename}`;
    link.download = filename;
    link.click();
  };

  return (
    <div className="card-body morph_variation1 padder">
      <br />
      <img src="/icon-ghv.svg" style={{ width: '80px' }} />

      <h4>{t('pendingModule.ghv')}</h4>

      <p className="text-center" style={{ margin: '10px 0px 30px 0px' }}>
        {t('programmeData.ghv.moduledescription')}
        <br />  
        {t('programmeData.ghv.moduledescription2')}
        </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
        }}
      >
        <button
          type="button"
          className="btn btn-secondary"
          style={{
            marginBottom: '10px',
            marginTop: '32px',
            width: 'calc(50% - 2.5px)',
            whiteSpace: 'normal',
            lineHeight: '1.2', // Optional: Passt den Zeilenabstand an
            padding: '10px', // Optional: Vergrößert den Button, um Platz für mehr Text zu schaffen
          }}
          onClick={() => navigate('/Programme')}
        >
          {t('backToMenu')}
        </button>

        <button
          type="button"
          className="btn btn-primary accent-cyan-bg"
          style={{
            marginBottom: '10px',
            marginTop: '32px',
            width: 'calc(50% - 2.5px)',
            whiteSpace: 'normal',
            lineHeight: '1.2', // Optional: Passt den Zeilenabstand an
            padding: '10px', // Optional: Vergrößert den Button, um Platz für mehr Text zu schaffen
          }}
          onClick={() => handleDownload('Geheimhaltungsvereinbarung_Auftrag.docx')}
        >
          {t('programmeData.ghv.geheimhaltungsvereinbarung')}
        </button>

        <button
          type="button"
          className="btn btn-primary accent-cyan-bg"
          style={{
            marginBottom: '10px',
            marginTop: '32px',
            width: 'calc(50% - 2.5px)',
            whiteSpace: 'normal',
            lineHeight: '1.2', // Optional: Passt den Zeilenabstand an
            padding: '10px', // Optional: Vergrößert den Button, um Platz für mehr Text zu schaffen
          }}
          onClick={() => handleDownload('Vertraulichkeitserklaerung_Arbeitnehmerin.docx')}
        >
          {t('programmeData.ghv.vertraulichkeitsvereinbarung')}
        </button>
      </div>
    </div>
  );
};

export { GhvRequest };
