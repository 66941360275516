import React from 'react';
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import Logo_Red_New from '../../../../src/assets/images/Logo_Red_New.png';
import { useTranslation } from 'react-i18next';
import { TITLES } from '.';

const DetailedViewPdf = ({ data }) => {
  console.log('pdf data: ', data);
  const { companyInfo } = data;
  const { t } = useTranslation('common');

  // const LABELS = t('detailedView.verzeichnis.labels', { returnObjects: true });

  const pdfStyles = StyleSheet.create({
    title: {
      fontSize: 16,
      textAlign: 'center',
      padding: 10,
      marginBottom: '30px',
    },
    documenttitle: {
      fontSize: 16,
      textAlign: 'center',
      padding: 4,
      marginBottom: '4px',
    },
    documentsubtitle: {
      fontSize: 10,
      textAlign: 'center',
      padding: 2,
      marginBottom: '6px',
    },

    documentcompanydata: {
      fontSize: 12,
      textAlign: 'center',
      padding: 2,
      marginBottom: '6px',
      marginTop: '10px',
    },

    section: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 0,
    },
    outerPadding: {
      padding: 40, // Replace '10' with the amount of padding you want
    },
    sectionTitle: {
      fontSize: 12,
      marginLeft: '10px',
      marginBottom: '10px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      fontSize: '10px',
      alignItems: 'left',
    },

    rowOdd: {
      backgroundColor: 'rgb(232, 232, 232)',
    },
    tableHeaders: {
      backgroundColor: '#34495e',
      color: '#fff',
      marginTop: -1,
      fontWeight: '700',
    },
    mainPointTitle: {
      fontSize: 12,
      marginLeft: '10px',
      marginBottom: '10px',
    },

    mainFieldTitle: {
      fontSize: 12,
      marginLeft: '20px',
    },

    mainPointValue: {
      width: '70%',
    },
    categoryPointList: {
      display: 'flex',
      flexDirection: 'column',
      listStyle: 'square',
    },

    points: {
      marginLeft: '30px',
    },
    logo: {
      textAlign: 'right',
      width: '30%',
      marginTop: '-20px',
    },
    logoContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: 20,
    },
    pageContainer: {
      paddingVertical: '40px',
      paddingHorizontal: '20px',
    },
  });

  return (
    <Document>
      <Page size="A4" style={pdfStyles.pageContainer}>
        <View style={pdfStyles.logoContainer}>
          <Image style={pdfStyles.logo} source={Logo_Red_New} />
        </View>

        {data && (
          <View style={pdfStyles.outerPadding}>
            <View style={pdfStyles.documenttitle}>
              <Text>
                {t('detailedView.verzeichnis.title')} -{' '}
                {t('detailedView.entry')} {data.id}
              </Text>
            </View>

            <View style={pdfStyles.documentsubtitle}>
              <Text>
                {t('detailedView.dateCreated')}: {data.dateCreated}
              </Text>
              <Text>
                {t('detailedView.lastEdited')}: {data.lastEdited}
              </Text>
            </View>

            <View style={pdfStyles.documentcompanydata}>
              <Text>{companyInfo?.CompanyName}</Text>
              <Text>
                {companyInfo?.CompanyStreet} {companyInfo?.CompanyStreetNr}
              </Text>
              <Text>
                {companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}
              </Text>
            </View>

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
                marginTop: 20,
              }}
            />

            {Object.keys(data).map((step, idx) =>
              step.includes('step') ? (
                <View style={pdfStyles.section} key={step}>
                  <View>
                    {Object.keys(data[step]).map((point) => (
                      <View
                        style={{
                          ...pdfStyles.rowView,
                          flexDirection: 'column',
                        }}
                        key={idx}
                      >
                        <Text style={pdfStyles.mainPointTitle}>
                          {TITLES[idx]}
                        </Text>
                        <View className={pdfStyles.categoryPointList}>
                          {Array.isArray(data[step][point])
                            ? data[step][point].map((obj, i) => (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    marginBottom: 1,
                                  }}
                                  key={i}
                                >
                                  <Text
                                    style={{ marginLeft: 20, marginRight: 8 }}
                                  >
                                    •
                                  </Text>
                                  <Text>{obj.label}</Text>
                                </View>
                              ))
                            : null}
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              ) : null
            )}

            <View
              style={{
                borderTopColor: 'grey',
                borderTopWidth: '1px',
                marginBottom: 20,
                marginTop: 20,
              }}
            />

            {/* <View style={pdfStyles.section}>
              <View>
                <Text style={pdfStyles.mainFieldTitle}>Weitere Massnahmen</Text>
                <View style={pdfStyles.rowView}>
                  <Text style={pdfStyles.points}>
                    {data.step12.furtherMeasures}
                  </Text>
                </View>
              </View>

              <View>
                <Text style={pdfStyles.mainFieldTitle}>Zertifizierungen</Text>
                <View style={pdfStyles.rowView}>
                  <Text style={pdfStyles.points}>
                    {data.step12.certificates}
                  </Text>
                </View>
              </View>
            </View> */}
          </View>
        )}
      </Page>
    </Document>
  );
};

export { DetailedViewPdf };
