import React from 'react';
import { FormField } from '../FormField';
import { Tooltip } from 'react-tooltip';

import styles from './styles.module.css';

const Input = React.forwardRef(
  (
    {
      label,
      type = 'text',
      className,
      value,
      onChange,
      onBlur,
      error,
      name,
      tooltipText,
    },
    ref
  ) => {
    const tooltipAnchor = `tooltipAnchor${name.split('.').pop()}`;

    return (
      <FormField error={error}>
        <div className={styles.inputWrapper}>
          {label && (
            <div className={styles.labelWrapper}>
              <label
                htmlFor={name}
                className={styles.inputLabel}
                style={{ marginBottom: 0 }}
              >
                {label}
              </label>
              {tooltipText && (
                <>
                  <span className={`${styles.tooltipWrapper} ${tooltipAnchor}`}>
                    ?
                  </span>
                  <Tooltip anchorSelect={`.${tooltipAnchor}`} place="top">
                    {tooltipText}
                  </Tooltip>
                </>
              )}
            </div>
          )}
          <input
            type={type}
            className={`form-control morphfield ${styles.input} ${className}`}
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
          />
        </div>
      </FormField>
    );
  }
);

export { Input };
